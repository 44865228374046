import React, { useState, useEffect, createContext, useContext } from 'react'
import { Tabs, Table, Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { PaginationCustomComponent } from '../../component-utils/PaginationComponent'
import './styles/pay-later.scss'
import EmptyImage from '../image/EmptyIcon.jpg'
import DatePickerCustom from './date-picker-range/index'
import PayLaterStatus from './pay-later-status/index.js'
import { payLaterStatus } from '../fixtures/pay-later-status'
import { formatMoney } from '../util/ultil'
import moment from 'moment'
import SelectMultipleCustom from './select'
import apiClient from '../../services/ApiClient'

const PayLaterContext = createContext()

export default function PayLater({ children, ...restProps }) {
  const [tab, setTab] = useState('1')

  return (
    <PayLaterContext.Provider
      value={{
        tab,
        setTab,
      }}
    >
      <div className="pay-later__tab_root" {...restProps}>
        {children}
      </div>
    </PayLaterContext.Provider>
  )
}

PayLater.Tab = function PayLaterTab() {
  const { TabPane } = Tabs
  const { tab, setTab } = useContext(PayLaterContext)
  return (
    <Tabs className="pay-later__tabs" activeKey={tab} onChange={(activeKey) => setTab(activeKey)}>
      <TabPane key="1" tab="Tất cả">
        <PayLater.TabAll />
      </TabPane>
    </Tabs>
  )
}

PayLater.TabAll = function PayLaterTabAll() {
  const [searchValue, setSearchValue] = useState('')
  const [pageable, setPageable] = useState({ pageIndex: 1, pageSize: 50, total: 0 })
  const [payDate, setPayDate] = useState({
    startDate: null,
    endDate: null,
  })

  const [partnerSelect, setPartnerSelect] = useState([])
  const [statusSelect, setStatusSelect] = useState([])

  const [dataAll, setDataAll] = useState([])

  useEffect(() => {
    try {
      const callPayLater = apiClient.get('api/paylaters/applications.json', {
        query: searchValue,
        paidOnMin: payDate.startDate ? moment(payDate.startDate).format('YYYY-MM-DDT00:00:00') : '',
        paidOnMax: payDate.endDate ? moment(payDate.endDate).format('YYYY-MM-DDT23:59:59') : '',
        partner: partnerSelect.join(','),
        status: statusSelect.join(','),
        source: 'WEB',
        page: pageable.pageIndex,
        limit: pageable.pageSize,
      })
      callPayLater
        .then((res) => {
          const dataResponse = res.response.result.applications || []
          setDataAll(dataResponse)
          setPageable({
            ...pageable,
            pageIndex: res.response.result.metadata.page || 1,
            pageSize: res.response.result.metadata.limit || 50,
            total: res.response.result.metadata.total || 0,
          })
        })
        .finally()
    } catch (error) {
      return null
    }
  }, [pageable.pageIndex, pageable.pageSize])

  const handleSubmitFilerForm = (event) => {
    event.preventDefault()
    try {
      const request = {
        query: searchValue,
        paidOnMin: payDate.startDate ? moment(payDate.startDate).format('YYYY-MM-DDT00:00:00') : '',
        paidOnMax: payDate.endDate ? moment(payDate.endDate).format('YYYY-MM-DDT23:59:59') : '',
        partner: partnerSelect.join(','),
        status: statusSelect.join(','),
        source: 'WEB',
        page: 1,
        limit: 50,
      }
      const callPayLater = apiClient.get('api/paylaters/applications.json', request)
      callPayLater
        .then((res) => {
          const { result = {} } = res.dataResponseModel.result
          setDataAll(result.applications)
          setPageable({
            ...pageable,
            pageIndex: 1,
            pageSize: 50,
          })
        })
        .finally()
    } catch (error) {
      return null
    }
  }

  return (
    <div className="pay-later__tab_all">
      <div className="pay-later__search_form_root">
        <PayLater.Search onSubmit={handleSubmitFilerForm}>
          <PayLater.Input
            placeholder="Tìm kiếm theo Mã đơn hàng, Tên, SĐT khách hàng"
            value={searchValue}
            onChange={({ target }) => setSearchValue(target.value)}
          />
          <PayLater.FilerOption className="pay-later__filer_option">
            <DatePickerCustom
              title="Ngày thanh toán"
              key="paid"
              valueDatePicker={payDate}
              setValueDatePicker={setPayDate}
            />

            <SelectMultipleCustom
              title="Đối tác"
              key={'Đối tác'}
              selectValues={partnerSelect}
              setSelectValues={setPartnerSelect}
            >
              <SelectMultipleCustom.Group>
                <SelectMultipleCustom.Option value="FDI">Funddin</SelectMultipleCustom.Option>
              </SelectMultipleCustom.Group>
            </SelectMultipleCustom>

            <SelectMultipleCustom
              title="Trạng thái"
              key={'Trạng thái'}
              selectValues={statusSelect}
              setSelectValues={setStatusSelect}
            >
              <SelectMultipleCustom.Group>
                {payLaterStatus.map((item, key) => (
                  <SelectMultipleCustom.Option key={key} value={item.value}>
                    {item.label}
                  </SelectMultipleCustom.Option>
                ))}
              </SelectMultipleCustom.Group>
            </SelectMultipleCustom>
          </PayLater.FilerOption>
          <PayLater.Button type="submit"> {'Tìm kiếm'} </PayLater.Button>
        </PayLater.Search>
      </div>

      <PayLater.Table pageable={pageable} setPageable={setPageable} data={dataAll} />
    </div>
  )
}

PayLater.FilerOption = function PayLaterFilerOption({ children, ...restProps }) {
  return <div {...restProps}>{children}</div>
}

PayLater.Title = function PayLaterTitle({ children }) {
  return <h1 className="pay-later__title"> {children} </h1>
}

PayLater.Search = function PayLaterSearch({ children, ...restProps }) {
  return (
    <form className="pay-later__search_form" {...restProps}>
      {' '}
      {children}{' '}
    </form>
  )
}

PayLater.Input = function PayLaterInput({ ...restProps }) {
  return (
    <Input
      {...restProps}
      className="pay-later__input"
      prefix={<SearchOutlined style={{ color: '#A3A8AF' }} />}
    />
  )
}

PayLater.Button = function PayLaterButton({ children, ...restProps }) {
  return (
    <button className="pay-later__button" type="submit" {...restProps}>
      {' '}
      {children}{' '}
    </button>
  )
}

PayLater.Table = function PayLaterTable({ pageable, setPageable, data, ...restProps }) {
  // eslint-disable-next-line no-unused-vars
  // const dataFake = dataTableFake

  const columns = [
    {
      title: 'Mã đơn hàng',
      dataIndex: 'code',
      width: '8%',
      align: 'left',
      className: 'pay-later__table_id',
    },
    {
      title: 'Tên khách hàng',
      dataIndex: 'customer_name',
      width: '15%',
      align: 'left',
      className: 'pay-later__table_name',
    },
    {
      title: 'SĐT',
      dataIndex: 'customer_phone',
      width: '10%',
      align: 'left',
      className: 'pay-later__table_phone',
    },
    {
      title: 'Địa chỉ',
      dataIndex: 'customer_address',
      width: '7%',
      align: 'left',
      className: 'pay-later__table_address',
    },
    {
      title: 'Số tiền(VND)',
      dataIndex: 'total_amount',
      width: '10%',
      align: 'right',
      className: 'pay-later__table_money',
      render: (money) => (Number.isNaN(money) ? '' : formatMoney(money)),
    },
    {
      title: 'Đối tác',
      dataIndex: 'partner',
      width: '5%',
      align: 'left',
      className: 'pay-later__table_partner',
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      width: '15%',
      align: 'left',
      className: 'pay-later__table_status',
      // eslint-disable-next-line react/display-name
      render: (value) => <PayLaterStatus status={value} />,
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'created_on',
      width: '15%',
      align: 'left',
      className: 'pay-later__table_created_date',
      render: (created_date) =>
        !created_date ? '' : moment(created_date).format('DD/MM/YYYY HH:mm:ss'),
    },
    {
      title: 'Ngày thanh toán',
      dataIndex: 'paid_on',
      width: '15%',
      align: 'left',
      className: 'pay-later__table_payed_date',
      render: (payed_date) => (!payed_date ? '' : moment(payed_date).format('DD/MM/YYYY HH:mm:ss')),
    },
  ]

  return (
    <div className="pay-later__table">
      <Table
        {...restProps}
        dataSource={data}
        columns={columns}
        pagination={false}
        locale={{
          emptyText: <Empty />,
        }}
        rowKey={(record) => record.code}
        scroll={{ y: 560 }}
      />
      <PaginationCustomComponent pageable={pageable} setPageable={setPageable} />
    </div>
  )
}

function Empty() {
  return (
    <div className="pay-later__empty">
      <img src={EmptyImage} alt="Empty" />
    </div>
  )
}
