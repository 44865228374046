import React from 'react'

function FieldRightDetail(props) {
  const { label, value } = props
  return (
    <div>
      <div className="Display-root  Display-container">
        <div className="Display-root Display-container Display-item Display-direction-column label-right-detail">
          <p style={{ fontSize: 14 }}>{label}</p>
        </div>
        <div className="Display-root Display-container Display-item Display-direction-column value-right-detail">
          {label && <p style={{ fontSize: 14 }}>{value}</p>}
        </div>
      </div>
    </div>
  )
}

export default FieldRightDetail
