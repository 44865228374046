import React, { useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import phone from '../../components/home/phone.svg'
import Header from '../header/Header'
import CalculatorLoanInterest from './CalculatorLoanInterest'
import CalculatorLoanLimit from './CalculatorLoanLimit'
import CalculatorLoanPayMonth from './CalculatorLoanPayMonth'
import ExchangeRate from './ExchangeRate'

const data = [
  { id: 1, title: 'Tính số tiền có thể vay' },
  { id: 2, title: 'Tính số tiền trả hàng tháng' },
  { id: 3, title: 'Tính lãi tiền gửi' },
  { id: 4, title: 'Tỷ giá ngoại tệ' },
]
export default function Calculator() {
  const history = useHistory()
  const [calculatorActive, setCalculatorActive] = useState(1)
  const tableBody = (idValue) => {
    switch (idValue) {
      case 1:
        return <CalculatorLoanLimit />
      case 2:
        return <CalculatorLoanPayMonth />
      case 3:
        return <CalculatorLoanInterest />
      case 4:
        return <ExchangeRate />
      default:
        return <CalculatorLoanLimit />
    }
  }

  return (
    <Container fluid className="SapoFin">
      <Header title="Công cụ tính" />
      <div className="CalculatorContainer" style={{marginTop:100}}>
        <div className="calculator_menu">
          <div style={{ borderBottom: '1px #e8eaeb solid', height: '48px' }}>
            <div className="title-calcualator">
              {data.map((value) => (
                <div
                  key={value.id}
                  className={`${calculatorActive === value.id && 'active-container-calculator'}`}
                  test={value.id}
                  onClick={() => setCalculatorActive(value.id)}
                >
                  {value.title}
                </div>
              ))}
            </div>
          </div>
          <div>{tableBody(calculatorActive)}</div>
        </div>
      </div>
      <div className="hotline-phone-ring-wrap">
        <a href="tel:02473086880" >
          <img src={phone} alt="Gọi điện thoại" width={50} />
        </a>
      </div>
      {/* <div className="hotline-message-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={mess} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div> */}
    </Container>
  )
}
