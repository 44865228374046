/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useReducer, useState } from 'react'
import { AuthenticationContext } from './AuthenticationContext'
import { useHistory } from 'react-router-dom'
import tokenStorage from '../services/token/TokenStorage'
import AuthenticationReducer from './AuthenticationReducer'
import authenticationActionType from './AuthenticationActionType'
import queryString from 'query-string'
import historyUtil from '../common/historyUtil'
import { encrypt } from '../utils/encryptUtil'

function AuthenticationProvider({ children }) {
  const refreshToken = tokenStorage.getToken()
  const initialAuthState = {
    isAuthenticated: refreshToken == null ? false : true,
    currentUser: {
      id: '',
      name: '',
      email: '',
      accountOwner: '',
    },
    bhServicePackageStatus: 1,
    bhRoles: [],
  }

  const [state, dispatch] = useReducer(AuthenticationReducer, initialAuthState)
  const [pathname, setPathname] = useState('')
  const history = useHistory()
  const callback = queryString.parse(history.location.search)

  useEffect(() => {
    const currentPath = history.location.pathname
    if (
      !currentPath.includes('install') &&
      !currentPath.includes('register') &&
      !currentPath.includes('callback') &&
      !currentPath.includes('oauth') &&
      currentPath.trim() !== '/'
    ) {
      localStorage.setItem('path', currentPath)
    }
    if (
      currentPath === '/api/install/callback-sapo-core' ||
      currentPath === '/install/callback-sapo-core' ||
      currentPath === '/register'
    ) {
      return
    }
    if (callback.tenant) {
      localStorage.setItem('origin', `https://${callback.tenant}`)
    }
    const origin = localStorage.getItem('origin')
    if (origin === null || origin === undefined) {
      // history.push("/register");
      return
    }
    if (state.isAuthenticated) {
      initSapoApp()
    } else {
      const token = tokenStorage.getToken()
      if (!token) {
        const apiKey = origin.includes('-sapostaging')
          ? process.env.REACT_APP_API_KEY_STAGING
          : process.env.REACT_APP_API_KEY
        localStorage.setItem('clientId', apiKey)
        //localStorage.setItem('clientId', '728226d5b6f0483e80ca3a855734d230')
        const redirectUri = `${localStorage.getItem(
          'origin'
        )}/admin/oauth/authorize?client_id=${apiKey}&scope=${
          process.env.REACT_APP_SCOPES
        }&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`
        // const redirectUri = `${localStorage.getItem(
        //   'origin'
        // )}/admin/oauth/authorize?client_id=728226d5b6f0483e80ca3a855734d230&scope=read_accounts,read_locations,read_orders&redirect_uri=http://localhost:8086/install/callback-sapo-core`
        window.parent.window.location.href = redirectUri
      }
    }
    return
  }, [pathname])

  useEffect(() => {
    return history.listen((location) => {
      setPathname(location.pathname)
    })
  }, [history])

  // useEffect(() => {
  //   initAuthoritesUser()
  // }, [window.SapoApp])

  // const initAuthoritesUser = () => {
  //   const sapoApp = window.SapoApp

  //   sapoApp.ready(() => {
  //     const { User = {} } = sapoApp || {}
  //     const { current = {} } = User || {}
  //     const { id = 0 } = current || {}

  //     const bhToken = localStorage.getItem('bankhubToken')
  //     if (bhToken) {
  //       if (!checkInfoToken(bhToken, id) || !checkExpiredToken(bhToken)) {
  //         localStorage.removeItem('bankhubToken')
  //         localStorage.removeItem('roles')
  //       }
  //     }
  //     dispatch({
  //       type: authenticationActionType.currentUser,
  //       payload: current,
  //     })
  //   })
  // }
  const initSapoApp = () => {
    const storageClientId = localStorage.getItem('clientId')
    const storageOrigin = localStorage.getItem('origin')
    const domainAlias =
      storageOrigin !== null && storageOrigin !== undefined
        ? storageOrigin.replace('.mysapo.vn', '.mysapogo.com')
        : ''
    const sapoApp = window.SapoApp
    localStorage.removeItem('path')
    if (storageClientId && storageOrigin) {
      sapoApp.init({
        apiKey: storageClientId.toString(),
        shopOrigin: domainAlias,
      })
      sapoApp._isReady = true
      sapoApp.ready(function() {
        sapoApp.Menu.expand()
        sapoApp.Menu.initialize({
          title: 'Sapo Money',
          icon: 'https://sapobizsynchronize.bizwebapps.vn/Content/Images/icon_60_60_sapo.png',
          label:
            '<img src="https://bizweb-sync.sapo.vn/Content/Images/icon-bw.png" style="width: 17px; margin-right: 5px;margin-bottom: -3px;"> Sapo web',
          expand: true,
          links: [
            {
              label: 'Trang chủ',
              message: 'Trang chủ',
              href: '/home',
              active: window.location.pathname && window.location.pathname === '/home',
              callback: function() {
                // window.location.href = "/sapoFin";
              },
            },
            {
              label: 'Vay vốn',
              message: 'Vay vốn',
              href: '/los',
              active: window.location.pathname && window.location.pathname === '/los',
              callback: function() {
                // window.location.href = "/sapoFin";
              },
            },
            {
              label: 'Thanh toán',
              message: 'Thanh toán',
              href: '/online-payment',
              active: window.location.pathname && window.location.pathname === '/online-payment',
              callback: function() {
                // window.location.href = "/sapoFin";
              },
            },
            {
              label: 'Mua trước trả sau',
              message: 'Mua trước trả sau',
              href: '/buy-first-pay-later',
              active:
                window.location.pathname && window.location.pathname === '/buy-first-pay-later',
              callback: function() {
                // window.location.href = "/sapoFin";
              },
            },
            // {
            //   label: 'Thanh toán hóa đơn',
            //   message: 'Thanh toán hóa đơn',
            //   href: '/payment-service',
            //   active:
            //     window.location.pathname &&
            //     (window.location.pathname === '/payment-service' ),
            //   callback: function() {
            //     // window.location.href = "/sapoFin";
            //   },
            // },
            {
              label: 'Tiện ích',
              message: 'Tiện ích',
              href: '/calculator',
              active: window.location.pathname && window.location.pathname === '/calculator',
              callback: function() {
                // window.location.href = "/paylater";
              },
            },
            // {
            //   label: 'Quản lý dòng tiền',
            //   message: 'Quản lý dòng tiền',
            //   href: 'https://www.sapo.vn/blog/quan-ly-ban-hang/quan-ly-nguon-von',
            //   active:
            //     window.location.pathname &&
            //     (window.location.pathname === 'quan-ly-ban-hang'),
            //   callback: function() {
            //     // window.location.href = "/sapoFin";
            //   },
            // },
          ],
        })
      })
    } else {
      console.log('sapoApp not defined, check after 1s')
      setTimeout(() => {
        initSapoApp()
      }, 1000)
    }
  }

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  )
}

export default AuthenticationProvider
