/* eslint-disable no-useless-escape */
export const loanReasons = [
  { value: 'Bổ sung hàng hóa', label: 'Bổ sung hàng hóa' },
  {
    value: 'Thanh toán công nợ',
    label: 'Thanh toán công nợ',
  },
  {
    value: 'Quảng bá sản phẩm',
    label: 'Quảng bá sản phẩm',
  },
]
export const LoanReasonTPD = [
  {
    value: 'maintenance',
    label: 'Bảo trì nhà,Trang thiết bị',
  },
  {
    value: 'transportation',
    label: 'Vận chuyển',
  },
  {
    value: 'Edu_sport_cultu_activities',
    label: 'Học tập văn hóa thể dục thể thao',
  },
  {
    value: 'healthcheck',
    label: 'Chữa bệnh',
  },
  {
    value: 'electronic_goods',
    label: 'Mua sắm điện tử,điện lạnh',
  },
]
export const MARRIEDSTATUS = [
  {
    code: 'WIDOW',
    name: 'Góa',
  },
  {
    code: 'MARRIED',
    name: 'Đã kết hôn',
  },
  {
    code: 'DIVORCED',
    name: 'Ly hôn',
  },
  {
    code: 'SINGLE',
    name: 'Độc thân',
  },
]
export const EDUCATIONSTATUS = [
  {
    value: 'N',
    label: 'Trung học cơ sở',
  },
  {
    value: 'U',
    label: 'Trung học phổ thông',
  },
  {
    value: 'G',
    label: 'Trường đại học/Cao đẳng',
  },
  {
    value: 'P',
    label: 'Sau đại học',
  },
]

export const leadTypes = [
  {
    value: 'CN',
    label: 'Cá nhân',
  },
  { value: 'HKD', label: 'Hộ kinh doanh' },
  { value: 'DN', label: 'Doanh nghiệp' },
]

export const months = [
  { value: 1, label: '1 Tháng' },
  { value: 2, label: '2 Tháng' },
  { value: 3, label: '3 Tháng' },
  { value: 4, label: '4 Tháng' },
  { value: 5, label: '5 Tháng' },
  { value: 6, label: '6 Tháng' },
  { value: 7, label: '7 Tháng' },
  { value: 8, label: '8 Tháng' },
  { value: 9, label: '9 Tháng' },
  { value: 10, label: '10 Tháng' },
  { value: 11, label: '11 Tháng' },
  { value: 12, label: '12 Tháng' },
  { value: 24, label: '24 Tháng' },
  { value: 60, label: '60 Tháng' },
]
export const packages = [
  { value: 'Welcome', label: 'Gói WelCome' },
  { value: 'Loyalty', label: 'Gói Loyalty' },
  { value: 'Premium', label: 'Gói Premium' },
  { value: '', label: 'Tất cả gói dịch vụ' },
]
export const optionTerm = [
  { value: 3, label: '3 Tháng' },
  { value: 6, label: '6 Tháng' },
  { value: 9, label: '9 Tháng' },
  { value: 12, label: '12 Tháng' },
  { value: 15, label: '15 Tháng' },
  { value: 18, label: '18 Tháng' },
  { value: 21, label: '21 Tháng' },
  { value: 24, label: '24 Tháng' },
  { value: 27, label: '27 Tháng' },
  { value: 30, label: '30 Tháng' },
  { value: 33, label: '33 Tháng' },
  { value: 36, label: '36 Tháng' },
]

export const orderByOpts = [
  { value: 'MRD', label: 'Số tiền trả hàng tháng giảm dần' },
  { value: 'MRA', label: 'Số tiền trả hàng tháng tăng dần' },
  { value: 'ATD', label: 'Thời gian phê duyệt giảm dần' },
  { value: 'ATA', label: 'Thời gian phê duyệt tăng dần' },
]

//
export const TIME_ONE_YEAH = 365 * 24 * 60 * 60 * 1000
//regex
export const REGEX_EMAIL = /^[a-z0-9]+(?:[\._][a-z0-9]+)*@[a-z0-9]+(?:\.[a-z0-9]+)+$/i
export const REGEX_IDENTIFY_CARD_9_NUMBER = /^\d{9}$/
export const REGEX_IDENTIFY_CARD_12_NUMBER = /^\d{12}$/
export const REGEX_IDENTIFY_CARD = /^([0-9]{9,12})$/
// export const REGEX_PHONE_NUMBER = /^(0)([0-9]{9})$/
export const REGEX_PHONE_NUMBER = /^((03)|(05)|(07)|(08)|(09))+[0-9]{8}$/
export const REGEX_PHONE_NUMBER_DETAIL = /^0((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-9]))|(9([0-9])))([0-9]{7})$/
export const REGEX_CARD_ATM_16_NUMBER = /^([0-9]{16})$/
export const REGEX_CARD_ATM_19_NUMBER = /^([0-9]{19})$/
export const REGEX_BANK_ACCOUNT_NUMBER = /^([A-Z0-9]{9,16})$/
export const REGEX_STRING_TEXT_NUMBER = /^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$/
export const REGEX_ONLY_TEXT_NUMBER = /^[A-Za-z0-9]+$/
export const REGEX_ONLY_TEXT_NUMBER_VN = /^(?:[ẮẰẲẴẶĂẤẦẨẪẬÂÁÀÃẢẠĐẾỀỂỄỆÊÉÈẺẼẸÍÌỈĨỊỐỒỔỖỘÔỚỜỞỠỢƠÓÒÕỎỌỨỪỬỮỰƯÚÙỦŨỤÝỲỶỸỴ]|[0-9a-z_\s])+$/
export const REGEX_NAME_VN = /^(?:[ẮẰẲẴẶĂẤẦẨẪẬÂÁÀÃẢẠĐẾỀỂỄỆÊÉÈẺẼẸÍÌỈĨỊỐỒỔỖỘÔỚỜỞỠỢƠÓÒÕỎỌỨỪỬỮỰƯÚÙỦŨỤÝỲỶỸỴ]|[0-9a-z_'\-\s])+$/

export const REGEX_ONLY_TEXT = /^[a-zA-Z\s]*$/

export const REGEX_REGISTER_TAX_NUMBER = /^(?:[0-9]+[-]+[0-9]|[0-9])+$/

// export const bankhubRoutes = {
//   CONNECT_CARD_ATM: '/bankhub/connectCardAtm',
//   MANAGE_ACCOUNT
// }

// function alias

export const SETTING_SALARY = 'setting_salary'
export const CREATE_EMPLOYEE = 'create_employee'
export const EDIT_EMPLOYEE = 'edit_employee'
export const EDIT_SALARY = 'edit_salary'
export const DELETE_SALARY = 'delete_salary'
export const INDEX_PREV_SALARY = 'index_prev_salary'
export const HISTORY_TRANSACTION = 'history_transaction'
export const ACCESS_CONTROL_ROLE = 'access_control'

//define mess err
export const PLEASE_TYPE = 'Vui lòng nhập '
export const PLEASE_SELECT = 'Vui lòng chọn '

export const VALID = ' hợp lệ'
export const VALID_LENGTH_MAX = 'Vượt quá ký tự cho phép'
export const VALID_LENGTH_MIN = 'Nhỏ hơn ký tự cho phép'
export const VALID_VALUE_MAX = ' không được lớn hơn '
export const VALID_VALUE_MIN = ' phải lớn hơn '

//url bankhub
export const BANKHUB = '/sapoFin/bankhub/'
export const CONNECT_CARD_ATM = `${BANKHUB}connectCardAtm`
export const CONNECT_CARD_ATM_PARTNER_CALLBACK = `${BANKHUB}connectCardAtm/partner-callback`
export const MANAGE_ACCOUNT = `${BANKHUB}manageAccount`
export const ACCESS_CONTROL = `${BANKHUB}accessControl`
export const ACCESS_CONTROL_SETTING = `${ACCESS_CONTROL}/setting`

export const TRANSFER = `${BANKHUB}transfer`
export const TRANSACTION_HISTORY = `${BANKHUB}transactionHistory`
export const DETAIL_TRANSACTION = `${TRANSACTION_HISTORY}/detailTransaction`
export const ACCOUNT_BALANCE = `${BANKHUB}accountBalance`
export const TRANSFER_SALARY = `${BANKHUB}transferSalary`
export const TRANSFER_SALARY_SCHEDULE = `${TRANSFER_SALARY}/schedule`
export const DETAIL_SALARY = `${BANKHUB}detailSalary`

export const bankHubPermission = {}
bankHubPermission.internalTransfer = 'internal_transfer'
bankHubPermission.napasCardTransfer = 'napas_card_transfer'
bankHubPermission.externalTransfer = 'external_transfer'
bankHubPermission.napasAccTransfer = 'napas_acc_transfer'
bankHubPermission.historyTransaction = 'history_transaction'
bankHubPermission.createEmployee = 'create_employee'
bankHubPermission.editEmployee = 'edit_employee'
bankHubPermission.settingSalary = 'setting_salary'
bankHubPermission.confirmSalaryTransfer = 'confirm_salary_transfer'

bankHubPermission.indexPrevSalary = 'index_prev_salary'
bankHubPermission.cancelPrevSalary = 'cancel_prev_salary'

bankHubPermission.accessControl = 'access_control'
bankHubPermission.deleteBankAccount = 'delete_bank_account'
bankHubPermission.connectDisconnectBankAccount = 'connect_disconnect_bank_account'
bankHubPermission.connectCard = 'connect_card'
bankHubPermission.openBankAccount = 'open_bank_account'
bankHubPermission.editSalary = 'edit_salary'

// bankhub type reducer =====
export const BH_ROLES = 'BANKHUB_ROLES'

// module bankhub
export const BH_MODULE_MANAGE_ACCOUNT = ['delete_bank_account', 'connect_disconnect_bank_account']
export const BH_MODULE_CONNECT_CARD = ['connect_card', 'open_bank_account']
export const BH_MODULE_TRANSFER_SALARY = [
  'create_employee',
  'edit_employee',
  'setting_salary',
  'index_prev_salary',
  'edit_salary',
  'delete_salary',
  'cancel_prev_salary',
]
export const BH_MODULE_TRANSFER = [
  'internal_transfer',
  'napas_card_transfer',
  'external_transfer',
  'napas_acc_transfer',
]

//url los
export const CALCULATOR = '/sapoFin/calculator'
export const LOS = '/los'
export const LOS_PRODUCTS = `${LOS}/products/:loanLimit`
export const LOS_APPLICATION_HISTORY = `${LOS}/application-history`

export const IndustryOptions = [
  { value: 'fashion', label: 'Thời trang' },
  { value: 'comestic', label: 'Mỹ phẩm' },
  { value: 'supermarket', label: 'Siêu thị mini' },
  { value: 'building_metarial', label: 'Vật liệu xây dựng' },
  { value: 'mom_and_baby', label: 'Mẹ và Bé' },
  { value: 'grocery', label: 'Cửa hàng tạp hóa' },
  { value: 'motobike', label: 'Xe Máy - Linh kiện' },
  { value: 'book', label: 'Sách và văn phòng phẩm' },
  { value: 'digital_electronic', label: 'Điện tử - điện máy' },
  { value: 'medicine', label: 'Nhà thuốc' },
  { value: 'furniture', label: 'Nội thất gia dụng' },
  { value: 'agricultural', label: 'Nông sản và thực phẩm' },
  { value: 'flower_gift', label: 'Hoa và quà tặng' },
  { value: 'other', label: 'Lĩnh vực khác' },
]

export const Partner = {
  ASP: 1,
  EVN: 2,
  KAG: 3,
  SHF: 4,
  TPB: 5,
  TPD: 6,
  VPB: 7,
  KBANK: 8,
  FASTMONEY: 9,
  TCB: 10,
  getName(name) {
    switch (name) {
      case Partner.TPB:
        return 'Ngân hàng Thương mại Cổ phần Tiên Phong'
      case Partner.TPD:
        return 'Ngân hàng TMCP Tiên Phong - Dico'
      case Partner.SHF:
        return 'Công ty tài chính TNHH MTV Ngân hàng TMCP Sài Gòn - Hà Nội'
      case Partner.ASP:
        return 'Công ty tài chính Aspire Việt Nam'
      case Partner.KAG:
        return 'Tập đoàn tài chính Kim An'
      case Partner.VPB:
        return 'Ngân hàng TMCP Việt Nam Thịnh Vượng'
      case Partner.EVN:
        return 'Công ty Tài chính Cổ phần Điện lực'
      case Partner.KBANK:
        return 'Ngân hàng Kasikornbank'
      case Partner.FASTMONEY:
        return 'Công ty Tài chính Cổ phần Điện lực'
      case Partner.TCB:
        return 'Ngân hàng TMCP Kỹ thương Việt Nam'
      default:
        return ''
    }
  },
  getCode(id) {
    switch (id) {
      case Partner.TPB:
        return 'TPB'
      case Partner.TPD:
        return 'TPD'
      case Partner.SHF:
        return 'SHF'
      case Partner.ASP:
        return 'ASP'
      case Partner.KAG:
        return 'KAG'
      case Partner.VPB:
        return 'VPB'
      case Partner.EVN:
        return 'EVN'
      case Partner.KBANK:
        return 'KBANK'
      case Partner.FASTMONEY:
        return 'FASTMONEY'
      case Partner.TCB:
        return 'TCB'
      default:
        return ''
    }
  },
  getBrandName(id) {
    return Partner.getOptions().find((item) => item.value === id)?.brandName || ''
  },
  getLabel(id) {
    return Partner.getOptions().find((item) => item.value === id)?.label || ''
  },
  getOptions() {
    const options = [
      { value: 1, brandName: 'Aspire', label: 'ASP'.concat(' - ', Partner.getName(Partner.ASP)) },
      { value: 2, brandName: 'EVN', label: 'EVN'.concat(' - ', Partner.getName(Partner.EVN)) },
      { value: 3, brandName: 'Kim An', label: 'KAG'.concat(' - ', Partner.getName(Partner.KAG)) },
      {
        value: 4,
        brandName: 'SHB Finance',
        label: 'SHF'.concat(' - ', Partner.getName(Partner.SHF)),
      },
      { value: 5, brandName: 'TPBDico', label: 'TPB'.concat(' - ', Partner.getName(Partner.TPD)) },
      { value: 6, brandName: 'TP Bank', label: 'TPD'.concat(' - ', Partner.getName(Partner.TPB)) },
      { value: 7, brandName: 'VP Bank', label: 'VPB'.concat(' - ', Partner.getName(Partner.VPB)) },
      {
        value: 8,
        brandName: 'KBANK',
        label: 'KBANK'.concat(' - ', Partner.getName(Partner.KBANK)),
      },
      {
        value: 9,
        brandName: 'FASTMONEY',
        label: 'FM'.concat(' - ', Partner.getName(Partner.FM)),
      },
      {
        value: 10,
        brandName: 'TCB',
        label: 'TCB'.concat(' - ', Partner.getName(Partner.TCB)),
      },
    ]
    return options
  },
}
