import React from 'react'
import { Col, Row } from 'react-bootstrap'
import FieldDetail from './FieldDetail'

function RowContent(props) {
  const { leftTitle, leftValue, style, rightTitle, rightValue } = props
  return (
    <Row style={style}>
      <Col>
        <FieldDetail label={leftTitle} value={leftValue} />
      </Col>
      <Col>
        <FieldDetail label={rightTitle} value={rightValue} />
      </Col>
    </Row>
  )
}

export default RowContent
