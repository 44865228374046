import vnpay from '../../components/onlinePayment/vnpay.svg'
import qrCode from '../../components/onlinePayment/qrCode.svg'
import payment2 from '../../components/onlinePayment/payment2.svg'
import payment3 from '../../components/onlinePayment/payment3.svg'
export const PAYMENTS = [
  {
    name: 'Chuyển khoản VietQR',
    detail: 'Không mất phí đầu tư thiết bị',
    detail2: ' Không phí xử lý giao dịch',
    description: 'Áp dụng: mọi nhà bán hàng (Cá nhân và doanh nghiệp)',
    icon: qrCode,
  },
  {
    name: 'Thanh toán qua QR - Code',
    detail: 'Không mất phí đầu tư thiết bị ',
    detail2: 'Phí giao dịch từ 0.8% doanh số',
    description: 'Áp dụng: mọi nhà bán hàng(Cá nhân và doanh nghiệp)',
    icon: vnpay,
  },
  {
    name: 'Thanh toán thẻ tại quầy',
    detail: 'Phí cố định (mua hoặc thuê thiết bị)',
    detail2: 'Phí giao dịch từ 0.6% doanh số',
    description: 'Áp dụng: hộ kinh doanh, doanh nghiệp bán hàng tại cửa hàng',
    icon: payment2,
  },
  {
    name: 'Cổng thanh toán',
    detail: 'Không mất phí đầu tư thiết bị ',
    detail2: 'Phí giao dịch từ 0.5% doanh số',
    description: 'Áp dụng: mọi nhà bán hàng (Cá nhân và doanh nghiệp)',
    icon: payment3,
  },
]
