import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import AdminLeadService from '../../../../services/admin/lead/AdminLeadService'
import { formatMoney } from '../../../../utils/FormatNumber'
import { PaginationCustomComponent } from '../PaginationComponent'
import { useHistory } from 'react-router-dom'
import historyUtil from '../../../../common/historyUtil'

function LeadList() {
  const [pageable, setPageable] = useState({
    page: 1,
    size: 20,
    total: 0,
  })
  const history = useHistory()
  const [leads, setLeads] = useState([])

  const getData = async () => {
    const leadRes = await AdminLeadService.getLeads(pageable.page, pageable.size)
    if (leadRes && leadRes.data && leadRes.data.leads) {
      setLeads(leadRes.data.leads)
    }
  }

  const getTotalLeads = async () => {
    const countRes = await AdminLeadService.countLeads(pageable.page, pageable.size)
    if (countRes && countRes.metadata && countRes.metadata.count) {
      setPageable({
        ...pageable,
        total: countRes.metadata.count,
      })
    }
  }

  useEffect(() => {
    getTotalLeads()
  }, [])

  useEffect(() => {
    getData()
  }, [pageable])

  return (
    <TableContainer component={Paper} className="tbl-application-container">
      <Table aria-label="list application table" className="tbl-application">
        <TableHead>
          <TableRow>
            <TableCell align="left" className="tbl-header-applicaction">
              Mã yêu cầu
            </TableCell>
            <TableCell align="right" className="tbl-header-applicaction">
              Số tiền (VND)
            </TableCell>
            <TableCell align="right" className="tbl-header-applicaction">
              Thời gian (Tháng)
            </TableCell>
            <TableCell align="left" className="tbl-header-applicaction">
              Mục đích
            </TableCell>
            <TableCell align="left" className="tbl-header-applicaction th-status">
              Ngày tạo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leads.map((lead, index) => (
            <TableRow key={index}>
              <TableCell align="left">
                <span
                  onClick={() => historyUtil(history, `/los/loan-history/lead/${lead?.id}`)}
                  className="span-link"
                  style={{ fontSize: 14 }}
                >
                  {lead?.code}
                </span>
              </TableCell>
              <TableCell align="right">{formatMoney(lead?.amount)}</TableCell>
              <TableCell align="right">{lead?.time}</TableCell>
              <TableCell align="left">{lead?.reason}</TableCell>
              <TableCell align="left">{moment(lead?.created_date).format('DD/MM/YYYY')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {pageable?.total > 20 && (
        <PaginationCustomComponent pageable={pageable} setPageable={setPageable} />
      )}
    </TableContainer>
  )
}

export default LeadList
