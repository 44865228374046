import { LeftOutlined } from '@ant-design/icons'
import { Breadcrumb } from 'antd'
import React from 'react'
import { useHistory, withRouter } from 'react-router-dom'
import historyUtil from '../../common/historyUtil'
const breadcrumbNameMap = {
  '/sapoFin': 'Sapo Fin',
  '/sapoFin/bankhub': 'BankHUB',
  '/sapoFin/bankhub/connectCardAtm': 'Liên kết tài khoản',
  '/sapoFin/bankhub/manageAccount': 'Quản lý tài khoản',
  '/sapoFin/bankhub/accessControl': 'Quản lý truy cập',
  '/sapoFin/bankhub/accessControl/setting': 'Cài đặt quyền truy cập',

  '/sapoFin/bankhub/transfer': 'Chuyển khoản trực tuyến',
  '/sapoFin/bankhub/transactionHistory': 'Tra cứu lịch sử giao dịch',
  '/sapoFin/bankhub/transactionHistory/detailTransaction': 'Chi tiết lịch sử giao dịch',
  '/sapoFin/bankhub/accountBalance': 'Tra cứu số dư tài khoản ngân hàng',
  '/sapoFin/bankhub/transferSalary': 'Chuyển lương',
  '/sapoFin/bankhub/transferSalary/schedule': 'Thiết lập bảng lương',
  '/sapoFin/bankhub/detailSalary': 'Chi tiết bảng lương',
  '/sapoFin/bankhub/notify': ' Thông báo',
  '/accumulation': 'Tích lũy',
  '/paylater': 'Thanh toán trả góp',
}

const BreadcrumbComponent = withRouter((props) => {
  const history = useHistory()
  const { location } = props
  let url = location.pathname
  const index = url.lastIndexOf('/')
  const param = url.substring(index + 1)
  if (!isNaN(param)) {
    url = url.substring(0, index)
  }
  const pathSnippets = url
    .split('/')
    .filter((i) => i)
    .filter((item, i, ar) => ar.indexOf(item) === i)
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`
    // console.log('====breadcrumbItems===', url)
    const indexLast = pathSnippets.length - 1

    return (
      <Breadcrumb.Item
        key={url}
        className={`CustomeBreadcrumbContainer ${index === indexLast && 'ActiveLinkContainer'}`}
      >
        <span onClick={() => historyUtil(history, url)}>{breadcrumbNameMap[url]}</span>
      </Breadcrumb.Item>
    )
  })
  const breadcrumbItems = [].concat(extraBreadcrumbItems)

  return (
    <>
      <Breadcrumb separator={<LeftOutlined className="BreadcrumbIconCustom" />}>
        {breadcrumbItems}
      </Breadcrumb>
    </>
  )
})

export default BreadcrumbComponent
