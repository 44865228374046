import apiClient from '../ApiClient'

const authenticationService = {
  async authenticate(authenticateRequest) {
    const authenticateResult = await apiClient.post(
      'api/install/callback-sapo-core.json',
      authenticateRequest
    )
    return authenticateResult
  },
  async login(request) {
    try {
      const result = await apiClient.post('/api/first_login.json', request)
      return result
    } catch (e) {
      // console.log(e);
      return null
    }
  },
}

export default authenticationService
