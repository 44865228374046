import apiClient from '../../ApiClient'
const AdminLocationService = {
  getCities: async () => {
    return apiClient.get('api/admin/money/countries/cities.json')
  },

  create: async (request) => {
    return apiClient.post('api/admin/money/leads.json', { request })
  },
}

export default AdminLocationService
