import { Collapse } from '@material-ui/core'
import { Col, Row } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { orderByOpts } from '../../common/Constant'
import { formatCurrencyCal } from '../../common/functionUtils'
import validateUtils from '../../common/validateUtils'
import FormContainer from '../../component-utils/FormContainer'
import { InputComponents } from '../../component-utils/InputComponent'
import Loading from '../../components/Loading'
import calculatorService from '../../services/calculator/CalculatorService'
import tenantService from '../../services/tenant/TenantService'
import { compareArrays } from '../../utils/Comparator'
import { formatMoney } from '../../utils/FormatNumber'
import { sortingProducts } from '../../utils/ProductSorting'
import RegisterModal from '../register/RegisterModal'
import Products from './Product'

export default function CalculatorLoanLimit(props) {
  const [loanLimit, setLoanLimit] = useState(0)
  const [isAccept, setIsAccept] = useState(false)
  const [data, setData] = useState({
    monthlyIncome: '',
    interestRate: '',
    loanPeriod: '',
    otherDebt: '',
  })
  const { monthlyIncome, interestRate, loanPeriod, otherDebt } = data
  const [sorting, setSorting] = useState({ value: '', label: 'Sắp xếp' })
  const [isLoading, setIsLoading] = useState(false)
  // const [term, setTerm] = useState(1)
  // const [amount, setAmount] = useState()
  const [error, setErr] = useState({})
  const [products, setProducts] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const [showNotiForm, setShowNotiForm] = useState(false)
  const [tenant, setTenant] = useState({})
  const [isOpenProduct, setIsOpenProduct] = useState(false)
  const setError = useCallback(
    (e) => {
      setTimeout(() => {
        setErr(e)
      }, 0)
    },
    [error]
  )
  const handleChangeCurrentProduct = (product) => {
    setCurrentProduct(product)
  }

  const handleRegisterSuccessful = () => {
    setShowRegisterForm(false)
    setShowNotiForm(true)
  }
  useEffect(() => {
    tenantService.getTenantInfo().then(
      (result) => {
        if (result) setTenant(result.tenant)
      },
      (error) => {
        // console.log(error.response);
      }
    )
  }, [])
  useEffect(() => {
    setTenant({
      ...tenant,
      loanProductCode: currentProduct.code,
      partnerLogo: currentProduct.logo,
    })
  }, [currentProduct])

  function handleFetchProduct(loanLimit, loanPeriod, loanLimits) {
    setIsLoading(true)
    calculatorService
      .getProductList(loanLimit, loanPeriod, loanLimit)
      .then((result) => {
        if (result && result.data && result.data.loan_products) {
          if (!compareArrays(products, result.data.loan_products)) {
            setProducts(result.data.loan_products)
            setSorting({ ...orderByOpts[3] })
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setIsLoading(false))
  }

  function getLoanAmount(monthlyIncome, interestRate, loanPeriod, otherDebt) {
    const interestRates = parseFloat(interestRate.toString().trim())
    const interests = interestRates / 100
    const monthlyIncomeValue = parseFloat(
      monthlyIncome
        .toString()
        .replaceAll(',', '')
        .trim()
    )
    const otherDebtValue = parseFloat(
      otherDebt
        .toString()
        .replaceAll(',', '')
        .trim()
    )
    if (otherDebtValue.toString().trim() === 'NaN') {
      calculatorService
        .getLoanAmount(monthlyIncomeValue, interests, loanPeriod, 0)
        .then((result) => {
          if (result && result.data) {
            setLoanLimit(result.data.loan_amount > 0 ? result.data.loan_amount : 0)
            handleFetchProduct(result.data.loan_amount, loanPeriod, result.data.loan_amount)
          }
        })
    } else {
      calculatorService
        .getLoanAmount(monthlyIncomeValue, interests, loanPeriod, otherDebtValue)
        .then((result) => {
          if (result && result.data) {
            setLoanLimit(result.data.loan_amount > 0 ? result.data.loan_amount : 0)
            handleFetchProduct(result.data.loan_amount, loanPeriod, result.data.loan_amount)
          }
        })
    }
  }

  const r = products.map((product, i) => (
    <Products
      key={i}
      index={i}
      product={product}
      changeCurrentProduct={handleChangeCurrentProduct}
      showRegisterForm={(value) => setShowRegisterForm(value)}
    />
  ))

  function onSubmit() {
    const err = {}
    validateUtils(
      {
        key: 'monthlyIncome',
        value: monthlyIncome,
        lableErr: 'thu nhập hàng tháng',
        isRequire: true,
        max: 100000000000,
        min: 0,
      },
      err
    )
    validateUtils(
      {
        key: 'interestRate',
        value: interestRate,
        lableErr: 'lãi suất vay',
        isRequire: true,
        max: 100,
        min: 0,
      },
      err
    )
    validateUtils(
      {
        key: 'loanPeriod',
        value: loanPeriod,
        lableErr: 'thời gian vay',
        isRequire: true,
        max: 60,
        min: 0,
      },
      err
    )

    if (monthlyIncome) {
      const monthlyIncomeValid = parseFloat(
        monthlyIncome
          .toString()
          .replaceAll(',', '')
          .trim()
      )

      if (isNaN(monthlyIncomeValid)) {
        err.monthlyIncome = 'Nhập thu nhập hàng tháng hợp lệ'
        // return
      }
      if (!/^[\d,]+$/.test(monthlyIncome.toString().trim())) {
        err.monthlyIncome = 'Nhập thu nhập hàng tháng hợp lệ'
        // return
      }
      if (monthlyIncomeValid > 100000000000) {
        err.monthlyIncome = 'Thu nhập hàng tháng không được lớn hơn 100,000,000,000'
      }
      if (monthlyIncomeValid <= 0) {
        err.monthlyIncome = 'Thu nhập hàng tháng phải lớn hơn 0'
      }
    }

    if (interestRate) {
      const interestRateValid = parseFloat(interestRate.toString().trim())
      const index = interestRateValid.toString().indexOf('.')
      const interestRateValids = interestRateValid.toString().slice(index + 1)
      if (isNaN(interestRateValid)) {
        err.interestRate = 'Nhập lãi suất vay hợp lệ'
      }
      if (!/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(interestRate.toString().trim())) {
        err.interestRate = 'Nhập lãi suất vay hợp lệ'
      } else {
        if (interestRateValid < 0) {
          err.interestRate = 'Lãi suất vay không được nhỏ hơn 0'
        }
        if (interestRateValids.length > 3) {
          err.interestRate = 'Lãi suất vay lấy tối đa 3 số sau dấu thập phân'
        }
        if (interestRateValid > 100) {
          err.interestRate = 'Lãi suất vay không được lớn hơn 100%'
        }
      }
    }

    if (loanPeriod) {
      const loanPeriodValid = parseFloat(
        loanPeriod
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (isNaN(loanPeriodValid)) {
        err.loanPeriod = 'Nhập thời gian vay hợp lệ'
      }
      if (!/^[\d,]+$/.test(loanPeriod.toString().trim())) {
        err.loanPeriod = 'Nhập thời gian vay hợp lệ'
      }
      if (loanPeriodValid > 60) {
        err.loanPeriod = 'Thời gian vay không được lớn hơn 60'
      }
      if (loanPeriodValid <= 0) {
        err.loanPeriod = 'Thời gian vay phải lớn hơn 0'
      }
    }

    if (otherDebt) {
      const otherDebtValid = parseFloat(
        otherDebt
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (!/^[\d,]+$/.test(otherDebt.toString().trim())) {
        err.otherDebt = 'Nhập tiền trả nợ khoản vay khác hợp lệ'
      }
      if (otherDebtValid > 100000000000) {
        err.otherDebt = 'Tiền trả nợ khoản khác không được lớn hơn 100,000,000,000'
      }
      if (otherDebtValid < 0) {
        err.otherDebt = 'Tiền trả nợ các khoản vay khác không được nhỏ hơn 0'
      }
    }

    if (Object.keys(err).length !== 0) {
      setError(err)
      return
    }

    getLoanAmount(monthlyIncome, interestRate, loanPeriod, otherDebt)
  }

  function checkNull() {
    if (
      monthlyIncome === '' ||
      monthlyIncome === null ||
      monthlyIncome === undefined ||
      interestRate === '' ||
      interestRate === null ||
      interestRate === undefined ||
      loanPeriod === '' ||
      loanPeriod === null ||
      loanPeriod === undefined
    ) {
      setIsAccept(false)
    } else {
      setIsAccept(true)
    }
  }
  useEffect(() => {
    if (loanLimit > 0) {
      setIsOpenProduct(true)
    } else {
      setIsOpenProduct(false)
    }
  }, [loanLimit])

  useEffect(() => {
    checkNull()
  })
  useEffect(() => {
    const result = sortingProducts(products, sorting)
    if (!compareArrays(result, products)) setProducts(result)
  }, [sorting])
  return (
    <div className="">
      <div className="CalculatorContainer">
        <Row gutter={24}>
          <Col span={16} style={{ paddingLeft: '0px' }}>
            <div className="font14">
              <br></br>
              <p>
                Nhập thông tin tài chính của bạn, Sapo Money sẽ giúp bạn tính toán số tiền trả hàng
                tháng nhanh chóng và chính xác nhất
              </p>
            </div>
            <Row gutter={24}>
              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Thu nhập hàng tháng"
                  placeholder="Nhập thu nhập hàng tháng"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={formatCurrencyCal(monthlyIncome.toString().trim())}
                  name="monthlyIncome"
                  labelCustomClass="LabelCustomPadding"
                  suffix="VND"
                  customSuffix="CustomSuffix"
                />
              </FormContainer>
              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Thời gian vay"
                  placeholder="Nhập thời gian vay"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={formatCurrencyCal(loanPeriod.toString().trim())}
                  name="loanPeriod"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="tháng"
                  customSuffix="CustomSuffix"
                  labelError="thời gian vay"
                />
              </FormContainer>
              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Lãi suất vay"
                  placeholder="Lãi suất vay hiện tại khoảng 1,5"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={interestRate.toString().trim()}
                  name="interestRate"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="%/tháng"
                  customSuffix="CustomSuffix"
                  labelError="lãi suất vay"
                />
              </FormContainer>

              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Tiền trả nợ các khoản vay khác"
                  placeholder="Nhập số tiền trả nợ các khoản vay khác"
                  // isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={formatCurrencyCal(otherDebt.toString())}
                  name="otherDebt"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="VND"
                  customSuffix="CustomSuffix"
                />
              </FormContainer>
              <Col span={24}>
                <div style={{ justifyContent: 'flex-end' }}>
                  <Row justify="center" style={{ marginTop: '2%', marginBottom: '3%' }}>
                    <div
                      className={`ButtonContainer ${!isAccept && 'ButtonDisable'} `}
                      onClick={isAccept ? () => onSubmit() : () => {}}
                    >
                      <Loading open={isLoading} />
                      Xem kết quả
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={8} className="boder-left">
            <br></br>
            <div className="font14">
              Số tiền có thể vay <br></br>
              <div style={{ fontSize: '24px', color: '#0088FF' }}>
                <b>{formatMoney(loanLimit)} VND</b>
              </div>
            </div>
            <div style={{ marginTop: '200px', fontSize: '12px' }}>
              <i>
                <b>Ghi chú:</b> Kết quả tính toán này chỉ mang tính chất tham khảo và có thể sai
                lệch nhỏ với kết quả tính toán thực tế tại các thời điểm
              </i>
            </div>
          </Col>
        </Row>
      </div>
      <Collapse in={isOpenProduct}>
        <div
          style={{
            minHeight: '25px',
            backgroundColor: '#F4F4F4',
          }}
        >
          <br></br>
          <b>Sản phẩm tài chính phù hợp với bạn</b>
        </div>
        <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Container fluid>
            <Loading open={isLoading} />
            {r}
          </Container>
        </div>
        <RegisterModal
          isProductForm={1}
          visible={showRegisterForm}
          open={() => setShowRegisterForm(true)}
          close={() => setShowRegisterForm(false)}
          loanLimit={loanLimit}
          initTenant={tenant}
          amount={loanLimit}
          term={loanPeriod}
        />
        {/* <NotificationModal show={showNotiForm} onHide={(e) => setShowNotiForm(false)} /> */}
        <br></br>
      </Collapse>
    </div>
  )
}
