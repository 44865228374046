import { Box, Grid, Typography, Button } from '@material-ui/core'
import React from 'react'
export const LoanPartner = (props) => {
  const {
    icon,
    title,
    content,
    total,
    limitTime,
    interestRate,
    onAction,
    actionContent,
    isDetail = false,
  } = props

  return (
    <Grid item xl={3} xs={3} sm={6} md={6} style={{ textAlign: 'center' }}>
      <Box className={'FinOptionsContainer'} style={{ height: 'fit-content' }}>
        <Box className="ImgFinPartner">
          <img src={icon} height={40} />
        </Box>
        <Box className="FinPartnerTitle">{title}</Box>
        <Box className="FinPartnerDescription">
          <div style={{ width: 'fit-content', textAlign: 'left' }}>{content}</div>
        </Box>
        <Box className="FinPartnerLoanInfo">
          <Grid container style={{ height: '100%' }}>
            <Grid item xs={4} style={{ height: '100%' }}>
              <Box style={{ height: '100%' }}>
                <Typography className="FinPartnerLoanInfoTitle">
                  {isDetail ? 'Số tiền giải ngân' : 'Khoản vay lên tới'}
                </Typography>
                <Typography className="FinPartnerLoanInfoContent">{total}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} style={{ height: '100%' }}>
              <Box style={{ height: '100%', borderLeft: '1px dotted #CCE7FF' }}>
                <Typography className="FinPartnerLoanInfoTitle">Thời hạn vay</Typography>
                <Typography className="FinPartnerLoanInfoContent">{limitTime}</Typography>
              </Box>
            </Grid>
            <Grid item xs={4} style={{ height: '100%' }}>
              <Box style={{ height: '100%', borderLeft: '1px dotted #CCE7FF' }}>
                <Typography className="FinPartnerLoanInfoTitle">Lãi suất từ</Typography>
                <Typography className="FinPartnerLoanInfoContent">{interestRate}</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="FinPartnerRegisterButton">
          <Button
            variant="contained"
            style={{
              background: '#0088FF',
              color: '#F3F4F5',
              textTransform: 'none',
              width: '200px',
            }}
            onClick={onAction}
          >
            {actionContent}
          </Button>
        </Box>
      </Box>
    </Grid>
  )
}
