import apiClient from '../ApiClient'

const exchangeRateService = {
  async getList() {
    try {
      const result = await apiClient.get(
        `api/exchange_rate_vcb.json`
      )
      return result
    } catch (e) {
      // console.log(e);
      return null
    }
  },
}
export default exchangeRateService
