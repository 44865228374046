import React from 'react'
import { Row, Steps, } from 'antd'
import sapoFinLogo from '../../../components/SFLogo.png'
const { Step } = Steps
export default function AccumulationModalHeader(props) {
    const { step, partnerLogo } = props
    return (
        <> <div className={`AccumulationHeaderRegisterModal `}>
            <div className="AccumulationTitleModalContainer">
                Yêu cầu tư vấn sản phẩm tích lũy
            </div>
            <div className="logo-group">
                <div className="accumulation-partner-logo">
                    <img src={partnerLogo} alt="Partner Logo" />
                </div>
                <div className="accumulation-sapo-fin-logo">
                    <img src={sapoFinLogo} alt="Sapo Money Logo" />
                </div>
            </div>
        </div>
            <Row className="AccumulationStepsContainer">
                <Steps size="small" current={step} labelPlacement="vertical">
                    <Step title="Điền form đăng ký" />
                    <Step className="AccumulationStepFinishContainer" title="Hoàn thành" />
                </Steps>
            </Row>
            {step === 0 && <Row justify='center' style={{ marginBottom: 24, fontSize: 14 }}>Vui lòng bổ sung các thông tin dưới đây để chuyên viên tài chính từ Sapo hỗ trợ bạn nhanh nhất  </Row>}
        </>
    )
}
