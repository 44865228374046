export function formatMoney(amount, decimalCount = 0, decimal = '.', thousands = ',', unit = '') {
  try {
    decimalCount = Math.abs(decimalCount)
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount

    const negativeSign = amount < 0 ? '-' : ''

    const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
    const j = i.length > 3 ? i.length % 3 : 0

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : '') +
      i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
        : '') +
      unit
    )
  } catch (e) {
    // console.log(e);
  }
}
export function formattedMoneyToInt(input = "") {
  if (input === undefined || input === null || input === "" || typeof input !== 'string') {
    return 0
  } else {
    let x = input.split(",").join("");
    let y = parseInt(x)
    return y
  }
}
export function autoFormatMoney(amount, locale = 'vi-VN', currency = 'VND', display = 'symbol') {
  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currency,
      currencyDisplay: display,
    }).format(amount)
  } catch (e) {
    // console.log(e);
  }
}
