import package1 from '../../components/los/packge1.svg'
import package2 from '../../components/los/packge2.svg'
export const SERVICE_PACKS = [
  {
    name: 'Vay online siêu nhanh tới 100 triệu VND',
    detail: 'Đối tượng: Khách hàng hiện hữu có ghi nhận doanh thu tối thiểu 3 tháng trên Sapo',
    description1: 'Thủ tục online 100%',
    description2: 'Lãi suất từ 11.9%/năm',
    description3: 'Hạn mức đến 100 triệu VND',
    description4: 'Giải ngân trong 24h',
    package: 'Welcome',
    icon: package1,
  },
  {
    name: 'Bổ sung vốn kinh doanh tới 1 tỷ VND',
    detail: 'Đối tượng: Khách hàng hiện hữu có ghi nhận doanh thu tối thiểu 6 tháng trên Sapo',
    description1: 'Thủ tục đơn giản',
    description2: 'Lãi suất từ 11.9%/năm',
    description3: 'Hạn mức đến 1 tỷ VND',
    description4: 'Kỳ hạn tới 60 tháng',
    package: 'Loyalty',
    icon: package2,
  }
]
