import logo1 from '../../components/buy/logo1.svg'
import logo2 from '../../components/buy/logo2.svg'
import logo3 from '../../components/buy/logo3.svg'
export const PAYMENTS =[
    {
        name:"Thu hút thêm khách hàng",
        description:"Người mua sở hữu món hàng yêu thích ngay cả khi chưa đủ tài chính với lãi suất trả góp 0%",
        icon:logo1
    },
    {
        name:"Tăng doanh thu",
        description:"Kích cầu mua sắm, tăng giá trị đơn hàng từ đó tăng doanh thu bán hàng",
        icon:logo2
    },
    {
        name:"Không rủi ro",
        description:"Chủ shop được thanh toán tiền bán hàng mà không phải chịu bất kỳ rủi ro nào.",
        icon:logo3
    }
]