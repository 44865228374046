/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core'
import { Rating } from '@material-ui/lab'
import React, { useEffect, useState } from 'react'
import {
  Button, Col, Row
} from 'react-bootstrap'
import ChevronDown from '../../components/ChevronDownIcon.svg'
import ChevronRight from '../../components/ChevronRightIcon.svg'
import DoneIcon from '../../components/DoneIcon.svg'
import phone from '../../components/home/phone.svg'
import amazonWebService from '../../services/amazonWebService/AmazonWebService'
import { formatMoney } from '../../utils/FormatNumber'
export default function Product(props) {
  const { product, changeCurrentProduct = (f) => f, showRegisterForm = (f) => f } = props
  const [open, setOpen] = useState(false)
  const [imageUrl, setImageUrl] = useState()
  const handleRegisterProduct = (e) => {
    changeCurrentProduct(product)
    showRegisterForm(true)
  }
  const interestRows = []
  const interestMonths = product && product.interest ? product.interest.interest_months : []
  interestMonths.forEach(function (item, index) {
    interestRows.push(
      createData(
        item.numerical_order,
        product.code === 'KAG007' && index === 0 ? 0 : item.interest_per_month,
        item.principal_per_month,
        item.surplus
      )
    )
  })
  const productNote =
    product && product.note ? (
      product.note.split('-').map((note, i) => {
        return (
          <li key={i} className="requirement-item">
            <img className="icon" src={DoneIcon} />
            <span className="content">{note}</span>
          </li>
        )
      })
    ) : (
      <></>
    )
  useEffect(() => {
    console.log('product logo', product.logo)
    getOneImage(product.logo)
  })

  function getOneImage(files) {
    amazonWebService
      .getOneImage(files)
      .then((result) => {
        if (result && result.responseData && result.responseData.data) {
          setImageUrl(result.responseData.data[0].url)
        }
      })
      .catch((e) => {
        console.log(e)
      })
  }

  return (
    <React.Fragment>
      <div className="paper Shadow8 ">
        <div style={{ minHeight: '24px', backgroundColor: '#F4F4F4' }}></div>
        <Row className="product-row">
          <Col className="partner-logo">
            <img src={imageUrl ? imageUrl : product.logo} className="logo" />
          </Col>
          <Col className="loan-product">
            <div className="product-name">{product.name}</div>
          </Col>
          <Col className="monthly-repayment">
            <div className="repayment-detail">
              {formatMoney(product.interest.total_pay_per_month)}
            </div>
            <div className="repayment-info">Số tiền trả hàng tháng</div>
          </Col>
          <Col className="approval-time">
            <div className="approval-time-detail">{`${product.processing_time} giờ`}</div>
            <div className="approval-time-info">Thời gian phê duyệt</div>
          </Col>
          <Col className="successful-rate">
            <div className="successful-rate-detail">{`${product.success_rate * 100} %`}</div>
            <div className="successful-rate-info">Tỷ lệ thành công</div>
          </Col>
          <Col className="rating">
            <div className="rating-detail">
              <Rating
                className="rating-icon"
                name="rating"
                value={Math.round(Number(product.rating) * 10) / 10}
                readOnly
                precision={0.1}
                style={{
                  fontSize: 20,
                }}
              />
            </div>
            <div className="rating-info">{`Dịch vụ ${Math.round(Number(product.rating) * 10) /
              10}/5`}</div>
          </Col>
          <Col className="register-control">
            <Button variant="primary" onClick={handleRegisterProduct}>
              Đăng ký
            </Button>
          </Col>
        </Row>
        <div
          className="product-row-control"
          aria-label="product expand row"
          onClick={() => setOpen(!open)}
        >
          <div style={{ display: 'inline-block', margin: '0 4px' }}>Chi tiết</div>
          {open ? <img src={ChevronDown} /> : <img src={ChevronRight} />}
        </div>
      </div>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className="paper Shadow8 product-detail-container"
        style={{ marginLeft: '0px', marginRight: '0px' }}
      >
        <ul className="list-requirement">{productNote}</ul>
        <TableContainer className="lst-repayment-tbl-container">
          <Table stickyheader aria-label="list repayment table" className="lst-repayment-tbl">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '8%' }}>Kỳ trả nợ</TableCell>
                <TableCell style={{ width: '23%' }}>Số tiền trả hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Lãi hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Gốc hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Số dư</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interestRows.map(function (row, i, interestRows) {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {row.numericalOrder}
                    </TableCell>
                    <TableCell>{formatMoney(product.interest.total_pay_per_month)}</TableCell>
                    <TableCell>{formatMoney(row.interestPerMonth)}</TableCell>
                    <TableCell>{formatMoney(row.principalPerMonth)}</TableCell>
                    <TableCell>{formatMoney(row.surplus)}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
      <div className="hotline-phone-ring-wrap">
        <a href="tel:02473086880" >
          <img src={phone} alt="Gọi điện thoại" width={50} />
        </a>
      </div>
      {/* <div className="hotline-message-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={mess} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div> */}
    </React.Fragment>
  )
}
function createData(numericalOrder, interestPerMonth, principalPerMonth, surplus) {
  return { numericalOrder, interestPerMonth, principalPerMonth, surplus }
}
