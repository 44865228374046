/* eslint-disable no-unused-vars */
import React from 'react'
import { Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import NotFoundImg from '../../components/NotFound.svg'
export default function Unauthorize(props) {
  const history = useHistory()
  return (
    <Container fluid className="wrapper">
      <div className="header">
        <div className="title">Sapo Money - Đăng ký vay vốn trực tuyến</div>
      </div>
      <div className="not-found-img">{/* <img src={NotFoundImg} alt="not found" /> */}</div>
      <Button
        variant="primary"
        className="return-home-btn"
        onClick={(e) => history.push('/los')}
      >
        Quay về trang chủ
      </Button>
    </Container>
  )
}
