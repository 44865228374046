import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import tokenStorage from './token/TokenStorage'
import queryString from 'query-string'
var cancel
class ApiClient {
  baseUrl
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE_URL || ''
    //this.baseUrl = 'http://localhost:8083'
  }
  initRequest(method, path, params) {
    var request = {
      baseUrl: this.baseUrl,
      url: `${this.baseUrl}/${path}`,
      method: method,
      params: params,
      cancelToken: new axios.CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c
      }),
    }
    return request
  }
  async authorizeRequest(request) {
    var jwt = await tokenStorage.getJwt()
    if (!jwt || jwt === '') {
      return request
    }

    if (!request.headers) {
      request.headers = {
        // Authorization: `${jwt}`,
        'X-AppFin-Token': `${jwt}`,
      }
    } else {
      // request.headers["Authorization"] = `${jwt}`;
      request.headers['X-AppFin-Token'] = `${jwt}`
    }

    return request
  }
  convertResponseData(data) {
    var camelData = camelcaseKeys(data)
    return camelData
  }
  convertRequestBody(data) {
    var snakeData = data instanceof FormData ? convertObjectToSnakeKeys(data) : snakecaseKeys(data)
    return snakeData
  }
  processError(error) {
    let errorModel = {}
    if (error.response) {
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      errorModel = camelcaseKeys(error.response.data)
      if (error.response.status === 401) {
        // browserHistory.push("/unauthorize");
        let callback = queryString.parse(window.location.href)
        let alias= callback?.tenant?.replace(".mysapo.vn", ".mysapogo.com").replace(".mysapogo.com", "")
        localStorage.removeItem(`${alias}_token`)
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      // console.log(error.request);
      errorModel = {
        statusCode: null,
        errorCode: null,
        messages: ['No response was received'],
      }
    } else {
      // Something happened in setting up the request and triggered an Error
      // console.log("Error", error.message);
      errorModel = {
        statusCode: null,
        errorCode: null,
        messages: [error.message],
      }
    }
    return errorModel
  }
  async get(path, params = null) {
    var request = this.initRequest('get', path, params)
    try {
      request = await this.authorizeRequest(request)
      var response = await axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }
  async getWithCancel(path, params = null) {
    if (cancel !== undefined) {
      cancel()
    }
    var request = this.initRequest('get', path, params)
    try {
      request = await this.authorizeRequest(request)
      var response = await axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }

  async post(path, body, params = null) {
    var request = this.initRequest('post', path, params)
    request.data = this.convertRequestBody(body)
    try {
      request = await this.authorizeRequest(request)
      // console.log(request);
      var response = await axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }
  syncPost(path, body, params = null) {
    var request = this.initRequest('post', path, params)
    request.data = this.convertRequestBody(body)
    try {
      request = this.authorizeRequest(request)
      console.log(request)
      var response = axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }
  async put(path, body, params = null) {
    var request = this.initRequest('put', path, params)
    request.data = this.convertRequestBody(body)
    try {
      await this.authorizeRequest(request)
      var response = await axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }
  async delete(path) {
    var request = this.initRequest('delete', path, null)
    try {
      await this.authorizeRequest(request)
      var response = await axios(request)
      return this.convertResponseData(response.data)
    } catch (error) {
      throw this.processError(error)
    }
  }
}

function camelToUnderscore(key) {
  return key.replace(/([A-Z])/g, '_$1').toLowerCase()
}

function convertObjectToSnakeKeys(original) {
  const newForm = new FormData()
  for (var pair of original.entries()) {
    newForm.append(camelToUnderscore(pair[0]), pair[1])
  }
  return newForm
}

const apiClient = new ApiClient()
export default apiClient
