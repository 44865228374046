import { Col, Row } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import FormContainer from '../../component-utils/FormContainer'
import { InputComponents } from '../../component-utils/InputComponent'
import validateUtils from '../../common/validateUtils'
import calculatorService from '../../services/calculator/CalculatorService'
import { formatMoney } from '../../utils/FormatNumber'
import { formatCurrencyCal } from '../../common/functionUtils'

export default function CalculatorInterest() {
  const [error, setErr] = useState({})
  const [moneyPeriod, setMoneyPeriod] = useState()
  const [totalDebt, setTotalDebt] = useState(0)
  const [totalInterest, setTotalInterest] = useState(0)
  const [totalInterestPerMonth, setTotalInterestPerMonth] = useState(0)
  const [isAccept, setIsAccept] = useState(false)
  const [data, setData] = useState({
    moneyFirst: '',
    interestRate: '',
    time: '',
  })

  const { moneyFirst, interestRate, time } = data

  const setError = useCallback(
    (e) => {
      setTimeout(() => {
        setErr(e)
      }, 0)
    },
    [error]
  )

  function checkNull() {
    if (
      moneyFirst === '' ||
      moneyFirst === null ||
      moneyFirst === undefined ||
      interestRate === '' ||
      interestRate === null ||
      interestRate === undefined ||
      time === '' ||
      time === null ||
      time === undefined
    ) {
      setIsAccept(false)
    } else {
      setIsAccept(true)
    }
  }

  function getLoanInterest(moneyFirst, interestRate, time) {
    const interestRates = parseFloat(interestRate.toString().trim())
    const times = parseFloat(time.toString().trim())
    const interest = interestRates / 100
    const moneyFirstValue = parseFloat(
      moneyFirst
        .toString()
        .replaceAll(',', '')
        .trim()
    )
    calculatorService.getLoanInterest(moneyFirstValue, interest, times).then((result) => {
      if (result && result.data) {
        setMoneyPeriod(result.data)
        if (moneyFirstValue >= 0 && interest >= 0 && times >= 0) {
          setTotalDebt(result.data.total_money_period > 0 ? result.data.total_money_period : 0)
          setTotalInterest(result.data.total_interest > 0 ? result.data.total_interest : 0)
          setTotalInterestPerMonth(
            result.data.total_interest_per_month > 0 ? result.data.total_interest_per_month : 0
          )
        }
      }
    })
  }

  function onSubmit() {
    const err = {}
    validateUtils(
      {
        key: 'moneyFirst',
        value: moneyFirst,
        lableErr: 'số tiền gửi ban đầu ',
        isRequire: true,
      },
      err
    )
    validateUtils(
      {
        key: 'interestRate',
        value: interestRate,
        lableErr: 'lãi suất gửi',
        isRequire: true,
      },
      err
    )
    validateUtils(
      {
        key: 'time',
        value: time,
        lableErr: 'thời gian gửi',
        isRequire: true,
      },
      err
    )

    if (interestRate) {
      const interestRateValid = parseFloat(interestRate.toString().trim())
      const index = interestRateValid.toString().indexOf('.')
      const interestRateValids = interestRateValid.toString().slice(index + 1)
      if (isNaN(interestRateValid)) {
        err.interestRate = 'Nhập lãi suất gửi hợp lệ'
      }
      if (!/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(interestRate.toString().trim())) {
        err.interestRate = 'Nhập lãi suất gửi hợp lệ'
      } else {
        if (interestRateValid < 0) {
          err.interestRate = 'Lãi suất gửi không được nhỏ hơn 0'
        }
        if (interestRateValids.length > 3) {
          err.interestRate = 'Lãi suất gửi lấy tối đa 3 số sau dấu thập phân'
        }
        if (interestRateValid > 100) {
          err.interestRate = 'Lãi suất gửi không được lớn hơn 100%'
        }
      }
    }

    if (moneyFirst) {
      const moneyFirstValid = parseFloat(
        moneyFirst
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (isNaN(moneyFirstValid)) {
        err.moneyFirst = 'Nhập số tiền gửi hợp lệ'
      }
      if (!/^[\d,]+$/.test(moneyFirst.toString().trim())) {
        err.moneyFirst = 'Nhập số tiền gửi hợp lệ'
      }
      if (moneyFirstValid > 100000000000) {
        err.moneyFirst = 'Số tiền gửi không được lớn hơn 100.000.000.000'
      }
      if (moneyFirstValid < 0) {
        err.moneyFirst = 'Số tiền gửi không được nhỏ hơn 0'
      }
    }

    if (time != null) {
      const timeValid = parseFloat(
        time
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (!/^[\d,]+$/.test(time.toString().trim())) {
        err.time = 'Nhập thời gian gửi hợp lệ'
      }
      if (timeValid > 60) {
        err.time = 'Thời gian gửi không được lớn hơn 60 tháng'
      }
      if (timeValid < 0) {
        err.time = 'Thời gian gửi không được nhỏ hơn 0'
      }
    }

    if (Object.keys(err).length !== 0) {
      setError(err)
      return
    }
    getLoanInterest(moneyFirst, interestRate, time)
  }
  useEffect(() => {
    checkNull()
  })
  return (
    <div className="CalculatorContainer">
      <div>
        <Row gutter={24}>
          <Col span={16} style={{ paddingLeft: '0px' }}>
            <div className="font14">
              <br></br>
              <p>
                Nhập thông tin tài chính của bạn, Sapo Money sẽ giúp bạn tính toán lãi số tiền gửi
                tiết kiệm nhanh chóng và chính xác nhất
              </p>
            </div>
            <Row gutter={24}>
              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Số tiền gửi ban đầu"
                  placeholder="Nhập số tiền gửi ban đầu"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={formatCurrencyCal(moneyFirst.toString().trim())}
                  name="moneyFirst"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="VND"
                  customSuffix="CustomSuffix"
                  labelError="Nhập số tiền gửi ban đầu"
                />
              </FormContainer>

              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Thời gian gửi"
                  placeholder="Nhập thời gian gửi tiết kiệm"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={formatCurrencyCal(time.toString().trim())}
                  name="time"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="tháng"
                  customSuffix="CustomSuffix"
                  labelError="Nhập thời gian gửi tiết kiệm"
                />
              </FormContainer>

              <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                <InputComponents
                  label="Lãi suất gửi"
                  placeholder="Lãi suất hiện tại khoảng 8"
                  isRequire={true}
                  data={data}
                  onChangeData={setData}
                  setError={setError}
                  error={error}
                  value={interestRate.toString().trim()}
                  name="interestRate"
                  labelCustomClass="LabelCustomPadding"
                  dataViewCustom="DataViewCustom"
                  suffix="%/năm"
                  customSuffix="CustomSuffix"
                  labelError="Nhập lãi suất"
                />
              </FormContainer>

              <Col span={24}>
                <div style={{ justifyContent: 'flex-end' }}>
                  <Row justify="center" style={{ marginTop: '2%' }}>
                    <div
                      className={`ButtonContainer ${!isAccept && 'ButtonDisable'} `}
                      onClick={isAccept ? () => onSubmit() : () => {}}
                    >
                      Xem kết quả
                    </div>
                  </Row>
                </div>
              </Col>
              <br></br>
            </Row>
          </Col>
          <Col span={8} className="boder-left">
            <br></br>
            <div className="font14">
              Tổng tiền nhận cuối kỳ <br></br>
              <div style={{ fontSize: '24px', color: '#0088FF' }}>
                <b>{formatMoney(totalDebt)} VND</b>
              </div>
            </div>
            <div className="font14">
              Tổng tiền lãi
              <br></br>
              <b className="font16">{formatMoney(totalInterest)} VND</b>
            </div>
            <div className="font14">
              Lãi bình quân trên 1 tháng <br></br>
              <b className="font16">{formatMoney(totalInterestPerMonth)} VND</b>
            </div>

            <br></br>
            <br></br>
            <br></br>
            <br></br>

            <div style={{ fontSize: '12px' }}>
              <i>
                <b>Ghi chú:</b> Kết quả tính toán này chỉ mang tính chất tham khảo và có thể sai
                lệch nhỏ với kết quả tính toán thực tế tại các thời điểm
              </i>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  )
}
