import {
  REGEX_IDENTIFY_CARD,
  REGEX_NAME_VN,
  REGEX_PHONE_NUMBER_DETAIL,
} from '../../common/Constant'
import { formatMoney } from '../../utils/FormatNumber'

export const ValidateForm = {
  loanAmount: [
    {
      required: true,
      message: 'Số tiền vay không được để trống',
    },
    {
      type: 'number',
      min: 1000000,
      message: 'Số tiền vay tối thiểu 1tr',
    },
    {
      type: 'number',
      max: 1000000000,
      message: 'Số tiền vay không quá ' + formatMoney(1000000000),
    },
  ],
  name: [
    {
      required: true,
      whitespace: true,
      message: 'Họ và tên không được để trống',
    },
    { max: 255, message: 'Họ và tên không được quá 255 ký tự' },
    {
      pattern: new RegExp(REGEX_NAME_VN, 'iu'),
      message: 'Họ và tên không đúng định dạng',
    },
  ],
  city: [{ required: true, message: 'Địa bàn cư trú không được để trống' }],
  businessType: [{ required: true, message: 'Hình thức kinh doanh không được để trống' }],
  loanReason: [{ required: true, message: 'Mục đích vay không được để trống' }],
  industry: [{ required: true, message: 'Lĩnh vực kinh doanh không được để trống' }],
  time: [{ required: true, message: 'Thời hạn vay không được để trống' }],
  phone: [
    {
      required: true,
      message: 'Số điện thoại không được để trống',
    },
    {
      pattern: new RegExp(REGEX_PHONE_NUMBER_DETAIL),
      message: 'Số điện thoại không đúng định dạng',
    },
  ],
  identityCard: [
    {
      required: true,
      message: 'CMND/CCCD không được để trống',
    },
    {
      pattern: new RegExp(REGEX_IDENTIFY_CARD),
      message: 'CMND/CCCD không đúng định dạng',
    },
  ],
}

export const ValidateFormLanding = {
  loanAmount: [
    {
      required: true,
      message: 'Số tiền vay không được để trống',
    },
    {
      type: 'number',
      min: 1000000,
      message: 'Số tiền vay tối thiểu 1tr',
    },
    {
      type: 'number',
      max: 150000000,
      message: 'Số tiền vay không quá ' + formatMoney(150000000),
    },
  ],
}
