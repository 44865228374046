import {
  SET_DATA_REGISTER_BANKHUB,
  SET_DATA_LEAD_BANKHUB,
  UPDATE_STATUS_BANKHUB,
  CLEAR_DATA_REGISTER_BANKHUB,
} from '../actionType'

const initValue = {
  // tenant
  storeName: '',
  regionCode: '',
  cityCode: '',
  districtCode: '',
  wardCode: '',
  //////////////
  businessAddress: '',
  region: '',
  ward: '',
  address: '',
  domain: '',
  tenantId: '',
  email: '',
  businessCategory: '',
  businessType: '',
  packageName: '',
  // lead
  ownerPhone: '',
  ownerName: '',
  ownerDob: '',
  ownerEmail: '',
  ownerIdCard: '',
  ownerIssuedDate: '',
  ownerIssuedPlace: '',
  ownerTermCondition: '',
  ////////
  ownerRegionCode: '',
  ownerCityCode: '',
  ownerCodeDistrict: '',
  ownerCodeWard: '',
  /////////
  ownerWard: '',
  ownerAddress: '',
  isAccecpt: false,
  ownerSource: '',
  //service package
  servicePackageCode: '',
  statusServicePackage: 1,
  isActiveAccount: 1, // 1: not active - 2: wait active -  3: active  - 4: expired - 5: block
}

const registerBankHubReducer = (state = initValue, action) => {
  const { payload } = action
  switch (action.type) {
    case SET_DATA_REGISTER_BANKHUB: {
      // const key = Object.keys(payload)[0]
      // const value = Object.values(payload)[0]
      // console.log('===payload====truoc=', state)
      // console.log('===payload====sau=', { state, ...payload })

      // console.log('===payload====sau=', { ...state, [key]: value })
      return { ...state, ...payload }
      // return payload
      // return { ...state, [key]: value }
    }
    case UPDATE_STATUS_BANKHUB: {
      return { ...state, statusServicePackage: payload }
    }
    case CLEAR_DATA_REGISTER_BANKHUB: {
      return { ...state, ...initValue }
    }
    case SET_DATA_LEAD_BANKHUB: {
      const { lead = {} } = payload
      const { tenants = [] } = lead || {}
      const tenant = tenants[0] || {}
      // console.log('====lead====', lead)
      //tenant thong tin cua hang
      // console.log('===lead==', payload)
      const {
        name: storeName = '',
        city: cityLead = {},
        district: districtLead = {},
        ward: wardLead = {},
        business_address: businessAddress = '',
        domain = '',
        tenant_id: tenantId = '',
        email = '',
        business_category_code: businessCategory = '',
        type: businessType = '',
        package_name: packageName = '',
      } = tenant || {}
      const { code: cityCode = '' } = cityLead || {}
      const { code: districtCode } = districtLead || {}
      const { code: wardCode } = wardLead || {}
      //lead thoing tin chu cua hang
      const {
        owner_name: ownerName = '',
        city = {},
        district = {},
        ward = {},
        email: ownerEmail = '',
        phone: ownerPhone = '',
        dob: ownerDob = '',
        // dob: ownerDob = ownerDob ? moment(ownerDob) : '',

        address: ownerAddress = '',
        identity_card: ownerIdCard = '',
        ic_issued_at: ownerIssuedPlace = '',
        ic_issued_on: ownerIssuedDate = '',
        source: ownerSource = '',
      } = lead || {}
      const { code: ownerCityCode = '' } = city || {}
      const { code: ownerCodeDistrict = '' } = district || {}
      const { code: ownerCodeWard = '' } = ward
      // return { ...state, [key]: value }
      return {
        ...state,
        storeName,
        regionCode: districtCode,
        cityCode,
        districtCode,
        wardCode,
        businessAddress,
        domain,
        tenantId,
        businessCategory,
        businessType,
        packageName,
        email,
        //lead
        ownerName,
        ownerEmail,
        ownerPhone,
        ownerDob,
        ownerAddress,
        ownerIdCard,
        ownerIssuedPlace,
        ownerIssuedDate,
        ownerRegionCode: ownerCodeDistrict,
        ownerCityCode,
        ownerCodeDistrict,
        ownerCodeWard,
        ownerSource,
      }
    }
    default:
      return state
  }
}

export default registerBankHubReducer
