import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@material-ui/core'
import moment from 'moment'
import React, { Fragment, useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import AdminApplicationService from '../../../../services/admin/application/AdminApplicationService'
import emptyImg from '../../../../img/svg/emptyImg.svg'
import Loading from '../../../../components/Loading'
import {
  ApplicationStatus,
  convertValue,
  fileExtensionNotAllowShow,
  getBusinesTypeName,
} from '../../../../utils/Utils'
import { formatMoney } from '../../../../utils/FormatNumber'
import RowContent from '../RowContent'
import { Partner } from '../../../../common/Constant'
import ModalPreview from '../ModalPreview'
import FieldRightDetail from '../FieldRightDetail'
import Banner from '../../../../component-utils/Banner'
import QRCode from 'react-qr-code'

export default function ApplicationDetail() {
  const { id } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)
  const [application, setApplication] = useState({})
  const [tenants, setTenants] = useState([])
  const [files, setFiles] = useState([])
  const [contacts, setContacts] = useState([])
  const [modalPreview, setModalPreview] = useState({
    content: '',
    url: '',
    visible: false,
  })
  const [infoAmberFintech, setInfoAmberFintech] = useState({})

  useEffect(() => {
    setLoading(true)
    AdminApplicationService.getApplicationById(id)
      .then((result) => {
        if (result && result.application) {
          setApplication(result.application)
          setTenants(result.application.tenants)
          setFiles(result.application.files)
          setContacts(result.application.contacts)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      console.log('end')
    }
  }, [id])

  useEffect(() => {
    if (application?.partner_contact_id && ApplicationStatus.DISBURSED === application?.status) {
      setLoading(true)
      AdminApplicationService.getInfoFastMoney(application.partner_contact_id)
        .then((result) => {
          if (result && result?.infoLoanResponse) {
            setInfoAmberFintech(result.infoLoanResponse)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
    return () => {
      console.log('end')
    }
  }, [application?.partner_contact_id])

  return (
    <Fragment>
      {loading ? (
        <Loading open={loading} />
      ) : (
        <Container fluid className="wrapper">
          <Box className="header">
            <Box style={{ marginLeft: 24 }}>
              <span style={{ fontSize: '15px' }}>
                <span className="btn-back" onClick={() => history.push('/los/loan-history')}>
                  <FontAwesomeIcon icon={faAngleLeft} className="product-back-icon-calculator" />
                  Lịch sử khoản vay
                </span>
              </span>
            </Box>
          </Box>
          <Container fluid style={{ marginTop: 90, padding: '0 30px' }}>
            <Box display={'flex'}>
              <h5 style={{ fontWeight: 'bold', marginRight: 12 }}>{application?.code}</h5>
              {ApplicationStatus.getChip(application?.status)}
            </Box>
            <Grid container spacing={3}>
              <Grid item xl={8} xs={8}>
                <Card className="mt-2" style={{ border: 'none' }}>
                  <Card.Header
                    style={{
                      backgroundColor: '#ffff',
                      color: '0F1824',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Thông tin người vay
                  </Card.Header>
                  <Card.Body>
                    <RowContent
                      leftTitle={'Họ và tên'}
                      leftValue={convertValue(application?.customer_name)}
                      rightTitle={'Căn cước công dân'}
                      rightValue={convertValue(application?.identity_card)}
                    />
                    <RowContent
                      leftTitle={'Ngày cấp'}
                      leftValue={
                        application?.ic_date
                          ? moment(application?.ic_date).format('DD/MM/YYYY')
                          : '---'
                      }
                      rightTitle={'Nơi cấp'}
                      rightValue={convertValue(application?.ic_place_name)}
                    />
                    <RowContent
                      leftTitle={'Giới tính'}
                      leftValue={convertValue(application?.gender)}
                      rightTitle={'Ngày sinh'}
                      rightValue={
                        application?.birthday
                          ? moment(application?.birthday).format('DD/MM/YYYY')
                          : '---'
                      }
                    />
                    <RowContent
                      leftTitle={'CMND cũ'}
                      leftValue={convertValue(application?.identity_card_old)}
                      rightTitle={'Email'}
                      rightValue={convertValue(application?.email)}
                    />
                    <RowContent
                      leftTitle={'Số điện thoại'}
                      leftValue={convertValue(application?.phone)}
                      rightTitle={'Địa chỉ cư trú'}
                      rightValue={convertValue(application?.address)}
                    />
                    <RowContent
                      leftTitle={'Khu vực'}
                      leftValue={
                        application?.city
                          ? `${application?.city} ${
                              application?.district ? `- ${application?.district}` : ''
                            }`
                          : '---'
                      }
                      rightTitle={'Phường xã'}
                      rightValue={convertValue(application?.ward)}
                    />
                    <RowContent
                      leftTitle={'Tình trạng hôn nhân'}
                      leftValue={convertValue(application?.married_status)}
                      rightTitle={'Số người phụ thuộc'}
                      rightValue={convertValue(application?.dependants_number)}
                    />
                    <RowContent
                      leftTitle={'Trình độ học vấn'}
                      leftValue={convertValue(application?.education_status)}
                      rightTitle={'Thu nhập hàng tháng'}
                      rightValue={convertValue(formatMoney(application?.monthly_income))}
                    />
                  </Card.Body>
                </Card>
                {tenants?.map((tenant) => {
                  return (
                    <Card className="mt-4" style={{ border: 'none' }}>
                      <Card.Header
                        style={{
                          backgroundColor: '#ffff',
                          color: '0F1824',
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Thông tin cửa hàng {tenant?.name}
                      </Card.Header>
                      <Card.Body>
                        <RowContent
                          leftTitle={'Hình thức kinh doanh'}
                          leftValue={
                            tenant?.business_type
                              ? getBusinesTypeName(tenant?.business_type)
                              : '---'
                          }
                          rightTitle={'Lĩnh vực kinh doanh'}
                          rightValue={convertValue(tenant?.industry)}
                        />
                        <RowContent
                          leftTitle={'Số ĐKKD'}
                          leftValue={convertValue(tenant?.business_number)}
                          rightTitle={'Địa chỉ kinh doanh'}
                          rightValue={convertValue(tenant?.business_address)}
                        />
                        <RowContent
                          leftTitle={'Khu vực'}
                          leftValue={
                            tenant?.city
                              ? `${tenant?.city} ${tenant?.district ? `- ${tenant?.district}` : ''}`
                              : '---'
                          }
                          rightTitle={'Phường xã'}
                          rightValue={convertValue(tenant?.ward)}
                        />
                      </Card.Body>
                    </Card>
                  )
                })}
                <Card className="mt-4" style={{ border: 'none' }}>
                  <Card.Header
                    style={{
                      backgroundColor: '#ffff',
                      color: '0F1824',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Thông tin nhu cầu vay
                  </Card.Header>
                  <Card.Body>
                    <RowContent
                      leftTitle={'Đơn vị cho vay'}
                      leftValue={convertValue(Partner.getName(application?.partner_id))}
                      rightTitle={'Số tiền muốn vay'}
                      rightValue={convertValue(formatMoney(application?.amount))}
                    />
                    <RowContent
                      leftTitle={'Mục đích vay'}
                      leftValue={convertValue(application?.reason)}
                      rightTitle={'Thời gian vay (Tháng)'}
                      rightValue={convertValue(application?.time)}
                    />
                    <RowContent
                      leftTitle={'Lãi suất'}
                      leftValue={convertValue(application?.rate)}
                    />
                  </Card.Body>
                </Card>
                {contacts?.length > 0 && (
                  <Card className="mt-4" style={{ border: 'none' }}>
                    <Card.Header
                      style={{
                        backgroundColor: '#ffff',
                        color: '0F1824',
                        fontWeight: 'bold',
                        fontSize: 16,
                      }}
                    >
                      Thông tin người tham chiếu
                    </Card.Header>
                    {contacts?.map((item, index) => {
                      return (
                        <Card.Body key={index}>
                          <RowContent
                            leftTitle={'Họ và tên'}
                            leftValue={convertValue(item.name)}
                            rightTitle={'Căn cước công dân'}
                            rightValue={convertValue(item.identity_card)}
                          />
                          <RowContent
                            leftTitle={'Số điện thoại'}
                            leftValue={convertValue(item?.phone)}
                            rightTitle={'Mối quan hệ'}
                            rightValue={convertValue(item?.relationship)}
                          />
                        </Card.Body>
                      )
                    })}
                  </Card>
                )}
                <Card className="mt-4" style={{ border: 'none' }}>
                  <Card.Header
                    style={{
                      backgroundColor: '#ffff',
                      color: '0F1824',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Thông tin tài khoản ngân hàng
                  </Card.Header>
                  <Card.Body>
                    <RowContent
                      leftTitle={'Chủ tài khoản '}
                      leftValue={convertValue(application?.bank_account_name)}
                      rightTitle={'Ngân hàng'}
                      rightValue={convertValue(application?.bank_name)}
                    />
                    <RowContent
                      leftTitle={'Số tài khoản'}
                      leftValue={convertValue(application?.bank_account_number)}
                      rightTitle={'Chi nhánh'}
                      rightValue={convertValue(application?.bank_branch_name)}
                    />
                  </Card.Body>
                </Card>
              </Grid>
              <Grid item xl={4} xs={4}>
                {Partner.FASTMONEY === application?.partner_id &&
                  (ApplicationStatus.DISBURSED === application?.status && (
                    <Card style={{ border: 'none', marginTop: 8 }}>
                      <Card.Header
                        style={{
                          backgroundColor: '#ffff',
                          color: '0F1824',
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Lịch đến hạn thanh toán
                      </Card.Header>
                      <Card.Body>
                        {!infoAmberFintech?.billList?.[0]?.needToPaymentAmount > 0 && (
                          <div style={{ marginBottom: '20px' }}>
                            <Banner
                              content="Đã thanh toán hết dư nợ sao kê kỳ này"
                              status="success"
                            />
                          </div>
                        )}
                        <FieldRightDetail
                          label={'Mã hợp đồng'}
                          value={convertValue(infoAmberFintech?.referenceId)}
                        />
                        <FieldRightDetail
                          label={'Ngày đến hạn'}
                          value={convertValue(infoAmberFintech?.billList?.[0]?.fromDate)}
                        />
                        <FieldRightDetail
                          label={'Số tiền cần thanh toán trong kỳ'}
                          value={convertValue(
                            formatMoney(infoAmberFintech?.billList?.[0]?.needToPaymentAmount)
                          )}
                        />
                        {infoAmberFintech?.billList?.[0]?.needToPaymentAmount > 0 && (
                          <>
                            <div
                              style={{
                                color: '0F1824',
                                fontWeight: 'bold',
                                fontSize: 16,
                                margin: '10px 0',
                              }}
                            >
                              Thông tin thanh toán
                            </div>
                            <FieldRightDetail
                              label={'Ngân hàng'}
                              value={convertValue(infoAmberFintech?.BankName)}
                            />
                            <FieldRightDetail
                              label={'Chủ tài khoản'}
                              value={convertValue(infoAmberFintech?.AccountName)}
                            />
                            <FieldRightDetail
                              label={'Số tài khoản'}
                              value={convertValue(infoAmberFintech?.BankNumber)}
                            />
                            <FieldRightDetail
                              label={'Nội dung chuyển khoản'}
                              value={convertValue(infoAmberFintech?.referenceId)}
                            />
                          </>
                        )}
                        <div
                          style={{
                            fontSize: 14,
                          }}
                        >
                          <span
                            style={{
                              color: '#FF4D4D',
                            }}
                          >
                            Lưu ý:
                          </span>
                          &nbsp;Nếu bạn có thắc mắc cần hỗ trợ, vui lòng liên hệ tổng đài FastMoney
                          1900 638398.
                        </div>
                        {infoAmberFintech?.qrcode &&
                          infoAmberFintech?.billList?.[0]?.needToPaymentAmount > 0 && (
                            <div style={{ textAlign: 'center' }}>
                              <img
                                style={{ margin: '20px 0' }}
                                src={'data:image/png;base64, ' + infoAmberFintech.qrcode}
                              />
                              <div
                                style={{
                                  color: '#747C87',
                                  fontSize: 14,
                                }}
                              >
                                Quét QR code để thanh toán khoản vay
                              </div>
                            </div>
                          )}
                      </Card.Body>
                    </Card>
                  ))}
                <Card
                  className={
                    Partner.FASTMONEY === application?.partner_id &&
                    ApplicationStatus.DISBURSED === application?.status
                      ? 'mt-4'
                      : 'mt-2'
                  }
                  style={{ border: 'none' }}
                >
                  <Card.Header
                    style={{
                      backgroundColor: '#ffff',
                      color: '0F1824',
                      fontWeight: 'bold',
                      fontSize: 16,
                    }}
                  >
                    Ảnh chứng từ
                  </Card.Header>
                  <Card.Body>
                    <Box>
                      {Partner.FASTMONEY === application?.partner_id &&
                        application?.partner_return_url &&
                        ApplicationStatus.TRANSFERENCE === application?.status && (
                          <div style={{ marginBottom: '20px' }}>
                            <Banner
                              content="Quét QR code để xác thực tại FastMoney"
                              status="info"
                            />
                            <div style={{ margin: '20px 0' }}>
                              <QRCode value={application.partner_return_url} />
                            </div>
                          </div>
                        )
                      }
                      <Box>
                        <span className="label-detail" style={{ marginBottom: 10, marginTop: 10 }}>
                          CMND/CCCD mặt trước
                        </span>
                      </Box>
                      <Box>
                        {files?.filter(
                          (item) =>
                            item.type === 1 && !fileExtensionNotAllowShow.includes(item.extension)
                        ).length > 0 ? (
                          <Fragment>
                            {files
                              ?.filter(
                                (item) =>
                                  item.type === 1 &&
                                  !fileExtensionNotAllowShow.includes(item.extension)
                              )
                              ?.map((item, index) => (
                                <img
                                  key={index}
                                  src={item?.url ?? emptyImg}
                                  alt="CMND/CCCD mặt trước"
                                  style={{
                                    width: '70px',
                                    height: '70px',
                                    cursor: 'pointer',
                                    marginRight: 10,
                                  }}
                                  onClick={() =>
                                    setModalPreview({
                                      content: 'Chi tiết ảnh CMND/CCCD mặt trước',
                                      url: item?.url ? item.url : emptyImg,
                                      visible: true,
                                    })
                                  }
                                />
                              ))}
                          </Fragment>
                        ) : (
                          <img
                            src={emptyImg}
                            alt="CMND/CCCD mặt trước"
                            style={{ width: '70px', height: '70px' }}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <span className="label-detail" style={{ marginBottom: 10, marginTop: 10 }}>
                          CMND/CCCD mặt sau
                        </span>
                      </Box>
                      <Box>
                        {files?.filter(
                          (item) =>
                            item.type === 2 && !fileExtensionNotAllowShow.includes(item.extension)
                        ).length > 0 ? (
                          <Fragment>
                            {files
                              ?.filter(
                                (item) =>
                                  item.type === 2 &&
                                  !fileExtensionNotAllowShow.includes(item.extension)
                              )
                              ?.map((item, index) => (
                                <img
                                  key={index}
                                  src={item?.url ?? emptyImg}
                                  alt="CMND/CCCD mặt sau"
                                  style={{
                                    width: '70px',
                                    height: '70px',
                                    cursor: 'pointer',
                                    marginRight: 10,
                                  }}
                                  onClick={() =>
                                    setModalPreview({
                                      content: 'Chi tiết ảnh CMND/CCCD mặt sau',
                                      url: item?.url ? item.url : emptyImg,
                                      visible: true,
                                    })
                                  }
                                />
                              ))}
                          </Fragment>
                        ) : (
                          <img
                            src={emptyImg}
                            alt="CMND/CCCD mặt sau"
                            style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                            onClick={() => console.log('click')}
                          />
                        )}
                      </Box>
                    </Box>
                    <Box>
                      <Box>
                        <span className="label-detail" style={{ marginBottom: 10, marginTop: 10 }}>
                          Ảnh chân dung
                        </span>
                      </Box>
                      <Box>
                        {files?.filter(
                          (item) =>
                            item.type === 9 && !fileExtensionNotAllowShow.includes(item.extension)
                        ).length > 0 ? (
                          <Fragment>
                            {files
                              ?.filter(
                                (item) =>
                                  item.type === 9 &&
                                  !fileExtensionNotAllowShow.includes(item.extension)
                              )
                              ?.map((item, index) => (
                                <img
                                  key={index}
                                  src={item?.url ?? emptyImg}
                                  alt="Ảnh chân dung"
                                  style={{
                                    width: '70px',
                                    height: '70px',
                                    cursor: 'pointer',
                                    marginRight: 10,
                                  }}
                                  onClick={() =>
                                    setModalPreview({
                                      content: 'Chi tiết ảnh chân dung',
                                      url: item?.url ? item.url : emptyImg,
                                      visible: true,
                                    })
                                  }
                                />
                              ))}
                          </Fragment>
                        ) : (
                          <img
                            src={emptyImg}
                            alt="Ảnh chân dung"
                            style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                          />
                        )}
                      </Box>
                    </Box>
                  </Card.Body>
                </Card>
              </Grid>
            </Grid>{' '}
            <ModalPreview
              url={modalPreview.url}
              content={modalPreview.content}
              visible={modalPreview.visible}
              setVisible={setModalPreview}
            />
          </Container>
        </Container>
      )}
    </Fragment>
  )
}
