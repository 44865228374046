import React from 'react'
import { Result, Button } from 'antd'
import { BANKHUB } from '../../common/Constant'

export default function BhErr500Component() {
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={
        <Button type="primary" onClick={() => (window.location.href = BANKHUB)}>
          Trang chủ
        </Button>
      }
    />
  )
}
