import React from 'react'
import { Partner } from '../common/Constant'
import asp from '../img/svg/aspire.svg'
import ecr from '../img/svg/ecr.svg'
import kinam from '../img/svg/kinam.svg'
import shb from '../img/svg/shb.svg'
import tpdLogo from '../img/svg/tpdLogo.svg'
import uob from '../img/svg/uob.svg'
import vpb from '../img/svg/vpbLogo.svg'
import kbank from '../img/svg/kbank.svg'
import fm from '../img/svg/fastmoney.svg'
import tcb from '../img/svg/tcb.svg'

export default function ConvertLogoPartner(props) {
  const { partnerId, style } = props
  function chekPartner(id) {
    switch (id) {
      case Partner.ASP:
        return (
          <img
            src={asp}
            alt="ASP"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.TPD:
        return (
          <img
            src={tpdLogo}
            alt="TPD"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.TPB:
        return (
          <img
            src={tpdLogo}
            alt="TPB"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case 'UOB':
        return (
          <img
            src={uob}
            alt="UOB"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.VPB:
        return (
          <img
            src={vpb}
            alt="VPB"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.VTB:
        return (
          <img
            src={vpb}
            alt="VTB"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.EVN:
        return (
          <img
            src={ecr}
            alt="EVN"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.KAG:
        return (
          <img
            src={kinam}
            alt="KAG"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.SHF:
        return (
          <img
            src={shb}
            alt="SHF"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.KBANK:
        return (
          <img
            src={kbank}
            alt="KBANK"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.FASTMONEY:
        return (
          <img
            src={fm}
            alt="FASTMONEY"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      case Partner.TCB:
        return (
          <img
            src={tcb}
            alt="TECHCOMBANK"
            style={style ? style : { width: 80, height: 30 }}
            className="logo"
          />
        )
      default:
        return <span></span>
    }
  }
  return <>{chekPartner(partnerId)}</>
}
