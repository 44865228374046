import apiClient from '../ApiClient'

const calculatorService = {
  async getProductList(total, time, limit) {
    try {
      let result = await apiClient.get(
        `api/products.json?total=${total}&time=${time}&limit=${limit}`
      )
      return result
    } catch (e) {
      // console.log(e);
      return null
    }
  },
  async getLoanAmount(monthlyIncome, interest, loanPeriod, otherDebt) {
    try {
      let result = await apiClient.get(
        `api/loan_amount.json?monthlyIncome=${monthlyIncome}&interestRate=${interest}&loanPeriod=${loanPeriod}&otherDebt=${otherDebt}`
      )
      return result
    } catch (e) {
      return null
    }
  },
  async getLoanInterest(moneyFirst, interestRate, time) {
    try {
      let result = await apiClient.get(
        `api/loan_interest.json?moneyFirst=${moneyFirst}&interestRate=${interestRate}&time=${time}`
      )
      return result
    } catch (e) {
      return null
    }
  },
  async getLoanPeriodMonth(
    loanAmount,
    term,
    interestRatePerMonth,
    prepaymentTerm,
    prepaymentPenaltyFee,
    interestRateMethod,
    checkPrepayment
  ) {
    try {
      let result = await apiClient.get(
        `api/loan_period.json?loanAmount=${loanAmount}&term=${term}&interestRatePerMonth=${interestRatePerMonth}&prepaymentTerm=${prepaymentTerm}&prepaymentPenaltyFee=${prepaymentPenaltyFee}&interestRateMethod=${interestRateMethod}&checkPrepayment=${checkPrepayment}`
      )
      return result
    } catch (e) {
      return null
    }
  },
}
export default calculatorService
