import authenticationActionType from './AuthenticationActionType'
import _ from 'lodash'
import tokenStorage from '../services/token/TokenStorage'
const AuthenticationReducer = (state, action) => {
  switch (action.type) {
    case authenticationActionType.signIn: {
      const { payload = {} } = action || {}
      const { access_token = '', token = '' } = payload || {}
      const domain = tokenStorage.getDomain()
      if (domain) {
        localStorage.setItem(`${domain}_token`, token)
      }
      localStorage.setItem('accessToken', access_token)
      return { ...state, isAuthenticated: true }
    }

    case authenticationActionType.reEvaluateInitialState: {
      const newState = _.cloneDeep(state)
      _.assign(newState, action.payload)
      return newState
    }

    case authenticationActionType.currentUser: {
      const { payload = {} } = action || {}
      return { ...state, currentUser: payload }
    }
    case authenticationActionType.bhServicePackageStatus: {
      const { payload = {} } = action || {}
      return { ...state, bhServicePackageStatus: payload }
      // return { ...state, bhServicePackageStatus: 3 }
    }
    case authenticationActionType.bhAuthenticate: {
      const { payload = {} } = action || {}
      const { bhRoles = [], bhServicePackageStatus = 1 } = payload || {}
      return { ...state, bhRoles, bhServicePackageStatus }
    }

    default:
      return state
  }
}

export default AuthenticationReducer
