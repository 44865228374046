export function sortingProducts(origin = [], sorting) {
    if (origin === null || origin === undefined) return []
    const list = origin.map((o) => Object.assign({}, o))
    if (list.length > 0) {
        switch (sorting.value) {
            case 'SRD':
                list.sort((p1, p2) => {
                    if (p1.success_rate > p2.success_rate) return -1
                    else if (p1.success_rate === p2.success_rate) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return 1
                })
                break
            case 'SRA':
                list.sort((p1, p2) => {
                    if (p1.success_rate > p2.success_rate) return 1
                    else if (p1.success_rate === p2.success_rate) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return -1
                })
                break
            case 'ATD':
                list.sort((p1, p2) => {
                    if (p1.processing_time > p2.processing_time) return -1
                    else if (p1.processing_time === p2.processing_time) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return 1
                })
                break
            case 'ATA':
                list.sort((p1, p2) => {
                    if (p1.processing_time > p2.processing_time) return 1
                    else if (p1.processing_time === p2.processing_time) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return -1
                })
                break
            case 'MRD':
                list.sort((p1, p2) => {
                    if (p1.interest.total_pay_per_month > p2.interest.total_pay_per_month) return -1
                    else if (p1.interest.total_pay_per_month === p2.interest.total_pay_per_month) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return 1
                })
                break
            case 'MRA':
                list.sort((p1, p2) => {
                    if (p1.interest.total_pay_per_month > p2.interest.total_pay_per_month) return 1
                    else if (p1.interest.total_pay_per_month === p2.interest.total_pay_per_month) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return -1
                })
                break
            default:
                list.sort((p1, p2) => {
                    if (p1.success_rate > p2.success_rate) return -1
                    else if (p1.success_rate === p2.success_rate) {
                        if (p1.name > p2.name) return 1
                        else return -1
                    } else return 1
                })
                break
        }
    }
    return list
}
