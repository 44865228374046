/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { faQuestionCircle as regularQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, OverlayTrigger, Popover, Row } from 'react-bootstrap'
import NumberFormat from 'react-number-format'
import { useHistory, useParams } from 'react-router-dom'
import Select from 'react-select'
import { Partner, months, orderByOpts, packages } from '../../common/Constant'
import historyUtil from '../../common/historyUtil'
import ChevronDown from '../../components/ChevronDownIcon.svg'
import ChevronRight from '../../components/ChevronRightIcon.svg'
import DoneIcon from '../../components/DoneIcon.svg'
import Loading from '../../components/Loading'
import AdminProductService from '../../services/admin/product/AdminProductService'
import tenantService from '../../services/tenant/TenantService'
import { compareArrays } from '../../utils/Comparator'
import { formatMoney } from '../../utils/FormatNumber'
import { sortingProducts } from '../../utils/ProductSorting'
import RegisterModal from '../register/RegisterModal'
import phone from '../../components/home/phone.svg'
import ConvertLogoPartner from '../../utils/ConvertLogoPartner'
import { toNumber } from 'lodash'
function ProductRow(props) {
  const { product, changeCurrentProduct = (f) => f, showRegisterForm = (f) => f } = props
  const [open, setOpen] = useState(false)
  const handleRegisterProduct = (e) => {
    changeCurrentProduct(product)
    showRegisterForm(true)
  }
  const interestRows = []
  const interestMonths = product && product.interest ? product.interest.interest_months : []
  interestMonths.forEach(function(item, index) {
    interestRows.push(
      createData(
        item.numerical_order,
        product.code === 'KAG007' && index === 0 ? 0 : item.interest_per_month,
        item.principal_per_month,
        item.surplus
      )
    )
  })
  const productNote =
    product && product.note ? (
      product.note.split('-').map((note, i) => {
        return (
          <li key={i} className="requirement-item">
            <img className="icon" src={DoneIcon} />
            <span className="content">{note}</span>
          </li>
        )
      })
    ) : (
      <></>
    )
  const getStyleLogo = (partnerId) => {
    if (Partner.TPD === partnerId || Partner.TPB === partnerId) {
      return { height: 60, width: 125 }
    } else if (Partner.KAG === partnerId) {
      return { height: 80, width: 125 }
    } else if (Partner.ASP === partnerId) {
      return { height: 60, width: 165 }
    }
    return { height: 60, width: 176 }
  }
  return (
    <React.Fragment>
      <div className="paper Shadow8 product-container">
        <Row className="product-row">
          <Col className="partner-logo">
            <ConvertLogoPartner
              partnerId={product?.partner_id}
              style={getStyleLogo(product?.partner_id)}
            />
          </Col>
          <Col className="loan-product">
            <div className="product-name">{product.name}</div>
          </Col>
          <Col className="monthly-repayment">
            <div className="repayment-detail">
              {formatMoney(product.interest.total_pay_per_month)}
            </div>
            <div className="repayment-info">Số tiền trả hàng tháng</div>
          </Col>
          <Col className="approval-time">
            <div className="approval-time-detail">{`${product.processing_time} giờ`}</div>
            <div className="approval-time-info">Thời gian phê duyệt</div>
          </Col>
          <Col className="register-control">
            <Button variant="primary" onClick={handleRegisterProduct}>
              Đăng ký
            </Button>
          </Col>
        </Row>
        <div
          className="product-row-control"
          aria-label="product expand row"
          onClick={() => setOpen(!open)}
        >
          <div style={{ display: 'inline-block', margin: '0 4px' }}>Chi tiết</div>
          {open ? <img src={ChevronDown} /> : <img src={ChevronRight} />}
        </div>
      </div>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className="paper Shadow8 product-detail-container"
      >
        <ul className="list-requirement">{productNote}</ul>
        <TableContainer className="lst-repayment-tbl-container">
          <Table stickyheader aria-label="list repayment table" className="lst-repayment-tbl">
            <TableHead>
              <TableRow>
                <TableCell style={{ width: '8%' }}>Kỳ trả nợ</TableCell>
                <TableCell style={{ width: '23%' }}>Số tiền trả hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Lãi hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Gốc hàng tháng</TableCell>
                <TableCell style={{ width: '23%' }}>Số dư</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {interestRows.map(function(row, i, interestRows) {
                return (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {row.numericalOrder}
                    </TableCell>
                    <TableCell>{formatMoney(product.interest.total_pay_per_month)}</TableCell>
                    <TableCell>{formatMoney(row.interestPerMonth)}</TableCell>
                    <TableCell>{formatMoney(row.principalPerMonth)}</TableCell>
                    <TableCell>{formatMoney(row.surplus)}</TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Collapse>
    </React.Fragment>
  )
}
function createData(numericalOrder, interestPerMonth, principalPerMonth, surplus) {
  return { numericalOrder, interestPerMonth, principalPerMonth, surplus }
}
const IndicatorSeparator = ({ innerProps }) => {
  return <span style={{}} {...innerProps} />
}
const customControlSelect = {
  control: (base) => ({
    ...base,
    height: '48px',
  }),
  menu: (base) => ({
    ...base,
    fontSize: '14px',
  }),
}

export default function Product() {
  const history = useHistory()
  const { loanLimit: loanLimits } = useParams()
  const search = queryString.parse(history.location.search)
  const [loanLimit, setLoanLimit] = useState(
    loanLimits ? (loanLimits >= 50000000 ? loanLimits : 50000000) : 0
  )
  const [term, setTerm] = useState(months[11])
  const [amount, setAmount] = useState(loanLimit ? loanLimit : 0)
  const [isLoading, setIsLoading] = useState(false)
  const [isValidAmount, setIsValidAmount] = useState(true)
  const [limitAmountError, setLimitAmountError] = useState('Số tiền vay tối thiểu là 1tr')
  const [tenant, setTenant] = useState({})
  const [currentProduct, setCurrentProduct] = useState({})
  const [products, setProducts] = useState([])
  const [servicePackage, setServicePackage] = useState(
    packages.find((item) => item.value == search.package) || packages[3]
  )
  const [sorting, setSorting] = useState({ value: '', label: 'Sắp xếp' })
  const handleChangeOrder = (order) => {
    setSorting(order)
  }
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const handleChangeCurrentProduct = (product) => {
    setCurrentProduct(product)
  }
  useEffect(() => {
    tenantService.getTenantInfo().then((result) => {
      if (result) setTenant(result.tenant)
    })
    return () => {
      console.log('end')
    }
  }, [])

  useEffect(() => {
    setAmount(loanLimit)
    setLoanLimit(loanLimit >= 50000000 ? loanLimit : 50000000)
    return () => {
      console.log('end')
    }
  }, [loanLimit])

  useEffect(() => {
    if (amount === '' || amount < 1000000) {
      setLimitAmountError('Số tiền vay tối thiểu là 1,000,000')
      setIsValidAmount(false)
    } else if (amount > 1000000000) {
      setLimitAmountError('Số tiền vay không được lớn hơn 1 tỷ')
      setIsValidAmount(false)
    } else setIsValidAmount(true)
    return () => {
      console.log('end')
    }
  }, [amount])

  useEffect(() => {
    if (loanLimit >= 50000000)
      handleFetchProduct(amount, term.value, loanLimit, servicePackage.value)
    else handleFetchProduct(50000000, term.value, loanLimit, servicePackage.value)
    return () => {
      console.log('end')
    }
  }, [loanLimit])

  useEffect(() => {
    const result = sortingProducts(products, sorting)
    if (!compareArrays(result, products)) setProducts(result)
    return () => {
      console.log('end')
    }
  }, [sorting])

  useEffect(() => {
    setTenant({
      ...tenant,
      loanProductCode: currentProduct.code,
      partnerLogo: currentProduct.logo,
    })
    return () => {
      console.log('end')
    }
  }, [currentProduct])

  function handleFetchProduct(amount, term, loanLimit, servicePackage) {
    setIsLoading(true)
    AdminProductService.getProducts(amount, term, loanLimit, servicePackage)
      .then((result) => {
        if (result && result.data && result.data.loan_products) {
          if (!compareArrays(products, result.data.loan_products)) {
            setProducts(result.data.loan_products)
            setSorting({ ...orderByOpts[0] })
          }
        }
      })
      .catch((e) => {
        // console.log(e);
      })
      .finally(() => setIsLoading(false))
  }
  const r = products.map((product, i) => (
    <ProductRow
      key={i}
      index={i}
      product={product}
      changeCurrentProduct={handleChangeCurrentProduct}
      showRegisterForm={(value) => setShowRegisterForm(value)}
    />
  ))

  return (
    <Container fluid className="wrapper">
      <div className="header" style={{ background: 'none', position: 'relative' }}>
        <div style={{ marginLeft: 24 }}>
          <span style={{ fontSize: '15px' }}>
            <span className="btn-back" onClick={() => historyUtil(history, '/los')}>
              <FontAwesomeIcon icon={faAngleLeft} className="product-back-icon-calculator" />
              Vay vốn kinh doanh
            </span>
          </span>
          <div className="greeting" style={{ marginLeft: 12, marginTop: 1 }}>
            Hồ sơ vay vốn
          </div>
        </div>
        <div className="support-content">
          <OverlayTrigger
            trigger="click"
            placement={'bottom-start'}
            rootClose={true}
            overlay={
              <Popover id={'support-popover'}>
                <a
                  href="https://support.sapo.vn/ung-dung-sapo-fin"
                  target="_blank"
                  className="support-item-link"
                >
                  <Popover.Content className="support-item-content">Về Sapo Money</Popover.Content>
                </a>
                <a
                  href="https://support.sapo.vn/ung-dung-sapo-fin#dang-ky-vay-von"
                  target="_blank"
                  className="support-item-link"
                >
                  <Popover.Content className="support-item-content">Hướng dẫn</Popover.Content>
                </a>
                <a
                  href="https://support.sapo.vn/dieu-khoan-dieu-kien-sapo-fin"
                  target="_blank"
                  className="support-item-link"
                >
                  <Popover.Content className="support-item-content">Điều khoản</Popover.Content>
                </a>
              </Popover>
            }
          >
            <Button variant="outline-light" className="support-btn">
              <FontAwesomeIcon className="icon-support" icon={regularQuestionCircle} />
              Trợ giúp
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <Form className="main-form">
        <Row className="main-form-row">
          <Form.Group as={Col} className="main-form-group money-group">
            <span className="currency-symbol">VND</span>
            <Form.Label className="main-form-label">Hạn mức tối đa được cấp sẵn</Form.Label>
            <Form.Control className="main-input " disabled value={formatMoney(loanLimit)} />
          </Form.Group>
          <Form.Group as={Col} className="main-form-group money-group" controlId="max-limit">
            <span className="currency-symbol">VND</span>
            <Form.Label className="main-form-label">Số tiền vay</Form.Label>
            <Form.Control
              className="hidden-input"
              value={amount}
              isInvalid={!isValidAmount}
              readOnly
            />
            <NumberFormat
              className={`form-control main-input ${isValidAmount ? '' : 'error-input'}`}
              thousandSeparator=","
              decimalSeparator="."
              allowNegative={false}
              placeholder="Nhập số tiền muốn vay"
              value={amount || ''}
              decimalScale={0}
              onValueChange={(valueObj) => {
                setAmount(valueObj.value)
              }}
              isAllowed={(valueObj) => valueObj === '' || valueObj.value >= 0}
            />
            <Form.Control.Feedback type="invalid">{limitAmountError}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} className="main-form-group" controlId="formGridState">
            <Form.Label className="main-form-label">Thời gian vay</Form.Label>
            <Select
              isClearable={false}
              isSearchable={true}
              name="month"
              options={months}
              components={{ IndicatorSeparator }}
              styles={customControlSelect}
              value={term}
              onChange={(value) => setTerm(value)}
            />
          </Form.Group>
          <Form.Group as={Col} className="main-form-group" controlId="formGridState">
            <Form.Label className="main-form-label">Gói dịch vụ</Form.Label>
            <Select
              isClearable={false}
              isSearchable={true}
              name="package"
              options={packages}
              components={{ IndicatorSeparator }}
              styles={customControlSelect}
              value={servicePackage}
              onChange={(value) => setServicePackage(value)}
            />
          </Form.Group>
          <Form.Group as={Col} className="main-form-group" md={1} lg={1} xl={1}>
            <Form.Label className="main-form-label" style={{ visibility: 'hidden' }}>
              Xem
            </Form.Label>
            <Button
              variant="primary"
              className="form-control main-input"
              onClick={() =>
                handleFetchProduct(amount, term.value, loanLimit, servicePackage.value)
              }
              disabled={!isValidAmount}
            >
              Xem
            </Button>
          </Form.Group>
        </Row>
      </Form>
      <div className="middle-section">
        <div className="intro-product">
          Sản phẩm tài chính phù hợp với cửa hàng{' '}
          <span className="shop-name-hl">{tenant.shop_name}</span>
        </div>
        <div className="order-by-wrapper">
          <Select
            // defaultValue={{ value: "", label: "Sắp xếp" }}
            value={sorting}
            isClearable={false}
            isSearchable={false}
            name="orderBy"
            options={orderByOpts}
            onChange={handleChangeOrder}
            components={{ IndicatorSeparator }}
            styles={{
              control: (base) => ({
                ...base,
                minHeight: '32px',
                height: '40px',
                width: '138px',
                fontSize: '14px',
              }),
              menu: (base) => ({
                ...base,
                width: '240px',
                fontSize: '14px',
                right: 0,
              }),
            }}
          />
        </div>
      </div>
      <Box>
        <Box className="hotline-phone-ring-wrap">
          <a href="tel:02473086880">
            <img src={phone} alt="Gọi điện thoại" width={50} />
          </a>
        </Box>
        {/* <div className="hotline-message-ring-wrap">
            <a href="tel:02473086880" >
              <img src={mess} alt="Gọi điện thoại" width={50} />
            </a>
          </div> */}
      </Box>
      <Container fluid>
        <Loading open={isLoading} />
        {r}
      </Container>
      <div className="footer-note">
        (<span className="requirement-mark">*</span>): Hạn mức được cấp bởi ngân hàng hoặc công ty
        tài chính dựa theo số liệu lưu trữ trên hệ thống SAPO, bạn có thể cung cấp thêm hồ sơ bổ
        sung để nâng cao hạn mức phê duyệt.
      </div>
      <RegisterModal
        productId={currentProduct.id}
        partnerId={currentProduct.partner_id}
        visible={showRegisterForm}
        open={() => setShowRegisterForm(true)}
        close={() => setShowRegisterForm(false)}
        loanAmount={toNumber(amount)}
        time={term.value}
      />
    </Container>
  )
}
