import logoMB from '../../components/onlinePayment/logoMB.svg'
import logoBIDV from '../../components/onlinePayment/logoBIDV.svg'
import logoTCB from '../../components/onlinePayment/logoTCB.svg'
import logoOCB from '../../components/onlinePayment/logoOCB.svg'
import logoVP from '../../components/onlinePayment/logoVP.svg'
import logoNextPay from '../../components/onlinePayment/logoNextPay.svg'
import logoVNPAY from '../../components/onlinePayment/logoVNPAY.svg'
import logoMOMO from '../../components/onlinePayment/logoMOMO.svg'

export const LIST_PARTNER_LOGO = [
  {
    logo: logoMB,
    name: 'Ngân hàng Thương mại Cổ phần Quân đội',
    url:
      'https://www.sapo.vn/blog/tich-hop-mb-bank-trong-giai-phap-thanh-toan-vietqr-pro-tren-sapo',
  },
  {
    logo: logoBIDV,
    name: 'Ngân hàng Thương mại cổ phần Đầu tư và Phát triển Việt Nam',
    url: 'https://www.sapo.vn/blog/ket-noi-giai-phap-thanh-toan-bidv-vietqr-pro-tren-sapo',
  },
  {
    logo: logoTCB,
    name: 'Ngân hàng Thương mại cổ phần Kỹ Thương Việt Nam',
    url: 'https://www.sapo.vn/blog/ket-noi-techcombank-vietqr-pro-tren-cong-thanh-toan-sapo',
  },
  {
    logo: logoOCB,
    name: 'Ngân hàng thương mại cổ phần Phương Đông',
    url: 'https://www.sapo.vn/blog/vietqr-xu-ly-noi-lo-thanh-toan-cua-chu-shop',
  },
  {
    logo: logoVP,
    name: 'Ngân hàng Thương mại cổ phần Việt Nam Thịnh Vượng',
    url:
      'https://www.sapo.vn/blog/sapo-pos-sapo-pay-ra-mat-tinh-nang-tich-hop-thanh-toan-quet-the-vpbank',
  },
  {
    logo: logoNextPay,
    name: 'NextPay cung cấp giải pháp Thanh toán toàn diện',
    url:
      'https://www.sapo.vn/blog/tien-ich-thanh-toan-sanh-vai-gap-2-doanh-so-cho-cua-hang-voi-giai-phap-thanh-toan-mpos-va-payon',
  },
  {
    logo: logoVNPAY,
    name: 'Ví điện tử VNPAY',
    url: 'https://www.sapo.vn/blog/sapo-tich-hop-vnpay',
  },
  {
    logo: logoMOMO,
    name: 'Ví điện tử MOMO',
    url: 'https://www.sapo.vn/blog/ket-noi-momo-qua-sapo',
  },
]
