import { Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import historyUtil from '../../../../common/historyUtil'
import AdminApplicationService from '../../../../services/admin/application/AdminApplicationService'
import ConvertLogoPartner from '../../../../utils/ConvertLogoPartner'
import { formatMoney } from '../../../../utils/FormatNumber'
import { ApplicationStatus } from '../../../../utils/Utils'
import { PaginationCustomComponent } from '../PaginationComponent'
import errorIcon from '../../../../img/svg/errorIcon.svg'
import warningIcon from '../../../../img/svg/outline_warning.png'
import { Tooltip } from 'antd'
import { Partner } from '../../../../common/Constant'

function ApplicationList() {
  const [pageable, setPageable] = useState({
    page: 1,
    size: 20,
    total: 0,
  })
  const history = useHistory()
  const [applications, setApplications] = useState([])

  const getData = async () => {
    const res = await AdminApplicationService.getApplications(pageable.page, pageable.size)
    if (res && res.data && res.data.applications) {
      setApplications(res.data.applications)
    }
  }

  const getTotalApplications = async () => {
    const countRes = await AdminApplicationService.countApplications(pageable.page, pageable.size)
    if (countRes && countRes.metadata && countRes.metadata.count) {
      setPageable({
        ...pageable,
        total: countRes.metadata.count,
      })
    }
  }

  useEffect(() => {
    getTotalApplications()
  }, [])

  useEffect(() => {
    getData()
  }, [pageable])

  return (
    <TableContainer component={Paper} className="tbl-application-container">
      <Table aria-label="list application table" className="tbl-application">
        <TableHead style={{ padding: 8 }}>
          <TableRow>
            <TableCell align="left" className="tbl-header-applicaction">
              Đơn vị cho vay
            </TableCell>
            <TableCell align="left" className="tbl-header-applicaction">
              Mã hồ sơ
            </TableCell>
            <TableCell align="right" className="tbl-header-applicaction">
              Số tiền (VND)
            </TableCell>
            <TableCell align="left" className="tbl-header-applicaction">
              Trạng thái
            </TableCell>
            <TableCell align="right" className="tbl-header-applicaction">
              Số tiền giải ngân
            </TableCell>
            <TableCell align="left" className="tbl-header-applicaction">
              Ngày tạo
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applications.map((item, index) => {
            return (
              <TableRow key={index}>
                <TableCell align="left">
                  {' '}
                  <ConvertLogoPartner
                    partnerId={item?.partner_id}
                    style={{ height: 20, width: 88 }}
                  />
                </TableCell>
                <TableCell align="left">
                  <span
                    onClick={() =>
                      historyUtil(history, `/los/loan-history/application/${item?.id}`)
                    }
                    className="span-link"
                    style={{ fontSize: 14 }}
                  >
                    {item?.code}
                  </span>
                </TableCell>
                <TableCell align="right">{formatMoney(item.amount)}</TableCell>
                <TableCell align="left">
                  {ApplicationStatus.getChip(item.status)}
                  {Partner.FASTMONEY === item?.partner_id &&
                    ApplicationStatus.DISBURSED === item?.status && (
                      <span className="ml-2">
                        <Tooltip
                          overlayClassName="TooltipCustomContainer"
                          placement="top"
                          title="Vui lòng xem chi tiết để thanh toán cho khoản vay"
                        >
                          <img src={errorIcon} alt="errorIcon" />
                        </Tooltip>
                      </span>
                    )}
                  {Partner.FASTMONEY === item?.partner_id &&
                    ApplicationStatus.TRANSFERENCE === item?.status && (
                      <span className="ml-2">
                        <Tooltip
                          overlayClassName="TooltipCustomContainer"
                          placement="top"
                          title="Vui lòng xem chi tiết hồ sơ để hoàn tất xác thực tại FastMoney"
                        >
                          <img src={warningIcon} alt="warningIcon" />
                        </Tooltip>
                      </span>
                    )}
                </TableCell>
                <TableCell align="right">{formatMoney(item.disbursement_amount)}</TableCell>
                <TableCell align="left">{moment(item.created_date).format('DD/MM/YYYY')}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
      {pageable?.total > 20 && (
        <PaginationCustomComponent pageable={pageable} setPageable={setPageable} />
      )}
    </TableContainer>
  )
}

export default ApplicationList
