/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { faQuestionCircle as regularQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
    Button, OverlayTrigger,
    Popover
} from 'react-bootstrap'
export default function Header(props) {
    const {title}=props
    return (
        <div className="header">
        <div className="greeting">{title}</div>
        <div className="support-content">
            <OverlayTrigger
                trigger="click"
                placement={'bottom-start'}
                rootClose={true}
                overlay={
                    <Popover id={'support-popover'}>
                        <a
                            href="https://support.sapo.vn/ung-dung-sapo-fin"
                            target="_blank"
                            className="support-item-link"
                        >
                            <Popover.Content className="support-item-content">Về Sapo Money</Popover.Content>
                        </a>
                        <a
                            href="https://support.sapo.vn/ung-dung-sapo-fin#dang-ky-vay-von"
                            target="_blank"
                            className="support-item-link"
                        >
                            <Popover.Content className="support-item-content">Hướng dẫn</Popover.Content>
                        </a>
                        <a
                            href="https://support.sapo.vn/dieu-khoan-dieu-kien-sapo-fin"
                            target="_blank"
                            className="support-item-link"
                        >
                            <Popover.Content className="support-item-content">Điều khoản</Popover.Content>
                        </a>
                    </Popover>
                }
            >
                <Button variant="outline-light" className="support-btn">
                    <FontAwesomeIcon className="icon-support" icon={regularQuestionCircle} />
                    Trợ giúp
                </Button>
            </OverlayTrigger>
        </div>
    </div>
    )
}
