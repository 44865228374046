import React from 'react'
import BreadcrumbComponent from '../bankHupComponent/router/BreadcrumbComponent'
import PayLater from './component/index'
import './pay-later.scss'

export default function PayLaterContainer() {
  return (
    <div className="pay-later__root">
      <div className="pay-later__bread_cum">
        <BreadcrumbComponent />
      </div>
      <PayLater>
        <PayLater.Title> {'Danh sách đơn hàng trả góp'} </PayLater.Title>
        <PayLater.Tab />
      </PayLater>
    </div>
  )
}
