/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React from 'react'
import {
    Col,
    Container, Row
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import phone from '../../components/home/phone.svg'
import HomeImage from '../../components/payment/payment-image.svg'
import Header from '../header/Header'
import { PAYMENTS as payments } from './Payments'
export default function PaymentService(props) {
    const history = useHistory()
    return (
        <>
            <Container fluid className="SapoFin">
                <Header title="Đại lý thanh toán hóa đơn & dịch vụ" />
                <div className='SapoMoneyContainer' style={{ background: "none", marginTop: 25 }}>
                    <div className='SapoMoneyHeaderContainer'>
                        <div className='row'>
                            <div className='col-9 text-center' style={{ marginTop: 50 }}>
                                <div className='title'>
                                    <h5>Trở thành đại lý thu hộ cho các nhà cung cấp dịch vụ</h5>
                                </div>
                                <div className='subDescription' style={{ marginTop: 30 }}>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Điện, nước</span>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Truyền hình, viễn thông</span>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Thẻ game và dịch vụ khác</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <img src={HomeImage} alt="Vay vốn kinh doanh" className='image-los' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SapoMoneyContainer">
                    <div className="feature-container">
                        <Row className="feature">
                            {payments.map((item, index) => {
                                return (
                                    <Col className="paper  feature-item " style={{ width: 240 }} key={index} >
                                        <div className="icon">
                                            <img src={item.icon} alt={item.name} />
                                        </div>
                                        <div className="content">
                                            <div >
                                                <div className="title">{item.name}</div>
                                                <div className="detail">
                                                    {item.description}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div>
                    <div className="hotline-phone-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={phone} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div>
                    {/* <div className="hotline-message-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={mess} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div> */}
                </div>
            </Container>
        </>
    )
}
