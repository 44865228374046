import React from 'react'
import successImage from '../../../components/RegisterSuccessfulImg.svg'
export default function AccumulationSuccessfulNotify() {
    return (<>
        <div className="noti-successful-content">
            <div className="noti-successful-title">
                Đăng ký tư vấn sản phẩm tích lũy thành công
            </div>
            <img
                className="noti-successful-img"
                src={successImage}
                alt="đăng ký thành công"
            />
            <div>
                Thông tin đăng ký sản phẩm tích lũy của quý khách đã được tiếp nhận,</div>
            <div> chuyên viên tư vấn tài chính sẽ nhanh chóng liên hệ quý khách để hướng dẫn bổ sung hồ sơ.
            </div>
            <div>
                Mọi thông tin cần hỗ trợ vui lòng liên hệ:{` `}
                <span style={{ fontWeight: '600' }}>(024) 730.868.80 (nhấn phím 803)</span>
            </div>
        </div></>
    )
}
