import React from 'react'
import { Col, Row, Input, Select, DatePicker, InputNumber } from 'antd'
import 'moment/locale/vi'
import locale from 'antd/es/date-picker/locale/vi_VN'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import * as Constant from './../common/Constant'
import moment from 'moment'

const { Group } = Input
const { Option } = Select

export default function InputContainer(props) {
  const {
    type,
    name,
    value,
    label,
    error = {},
    setError = () => { },
    regex,
    isNumber = false,
    onChangeData = () => { },
    isRequire = false,
    placeholder,
    min,
    max,
    suffix,
    classNameCustom,
    labelCustomClass,
    format,
    data = {}, // chỉ cần khi sử dụng useState, case Redux: => return về dạng (key, value)
    inputMode = true,
    spanLabel = 24,
    dataViewCustom,
  } = props
  // console.log('===er====', name)
  function onChange(e, dateString) {
    if (type === 'date-picker') {
      if (isRequire && !dateString) {
        // console.log('========', e, dateString)
        onChangeData({ ...data, [name]: moment(dateString, format) })
        setError({ ...error, [name]: `${Constant.PLEASE_SELECT}${label}` })
        return
      }
      console.log('========', e, dateString)
      onChangeData({ ...data, [name]: e })
      setError({ ...error, [name]: undefined })

      return
    }
    if (type === 'range-picker') {
      onChangeData({ ...data, [name]: e })
      console.log('=====e====', name)
      return
    }
    if (type === 'dropdown') {
      onChangeData({ ...data, [name]: e })
      // onChangeDataCustom()
      return
    }
    if (type === 'input-number') {
      const regex = /^\d+$/
      if (regex.test(e)) {
        onChangeData({ ...data, [name]: e })
        return
      }
      return
    }
    if (type === 'number') {
      const regex = /^\d+$/
      if (regex.test(e)) {
        onChangeData({ ...data, [name]: e })
        return
      }
      return
    }
    if (type === 'select') {
      setError({ ...error, [name]: undefined })
      onChangeData({ ...data, [name]: e })
      return
    }
    const { target } = e
    const { value, checked, type: typeInput } = target

    if (typeInput === 'checkbox') {
      onChangeData({ ...data, [name]: checked })

      return
    }
    // const caret = e.target.selectionStart
    // const element = e.target
    // window.requestAnimationFrame(() => {
    //   element.selectionStart = caret
    //   element.selectionEnd = caret
    // })
    onChangeData({ ...data, [name]: value })
    if (typeInput === 'text') {
      if (regex) {
        if (regex instanceof Array) {
          if (value && value.trim() && regex.length !== 0) {
            let testRegex = 0
            regex.forEach((item) => {
              if (!item.test(value.trim())) {
                testRegex++
              }
            })
            if (testRegex === regex.length) {
              setError({
                ...error,
                [name]: `${Constant.PLEASE_TYPE}${label}${Constant.VALID}`,
              })
              return
            }
          }
        } else {
          if (value && value.trim() && regex && !regex.test(value.trim())) {
            setError({
              ...error,
              [name]: `${Constant.PLEASE_TYPE}${label}${Constant.VALID}`,
            })
            return
          }
        }
      }

      if (value.length > max) {
        setError({
          ...error,
          [name]: `${Constant.VALID_LENGTH_MAX}`,
        })
        return
      }
      // }

      ///=========require==============
      if (isRequire) {
        if (!value || !value.trim()) {
          setError({ ...error, [name]: `${Constant.PLEASE_TYPE}${label}` })
          return
        }
        // if (regex && !regex.test(value)) {
        //   setError({
        //     ...error,
        //     [name]: `${Constant.PLEASE_TYPE}${label}${Constant.VALID}`,
        //   })
        //   return
        // }
        if (isNumber) {
          if (parseFloat(value) > parseFloat(max)) {
            setError({
              ...error,
              [name]: `${Constant.VALID_VALUE_MAX}${max}`,
            })
            return
          }
          if (parseFloat(value) < parseFloat(min))
            setError({
              ...error,
              [name]: `${Constant.VALID_VALUE_MIN}${min}`,
            })
          return
        } else {
          // if (value.length > max) {
          //   setError({
          //     ...error,
          //     [name]: `${Constant.VALID_LENGTH_MAX}`,
          //   })
          //   return
          // }
          if (value.length < min) {
            setError({
              ...error,
              [name]: `${Constant.VALID_LENGTH_MIN}`,
            })
            return
          }
        }
      }
    }
    setError({ ...error, [name]: undefined })
  }
  const switchComponent = () => {
    switch (type) {
      case 'dropdown': {
        const { options } = props
        return (
          <Row>
            <Col className={`LabelContainer ${!inputMode && 'ModeViewContainer'}`}>
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col>
              <Group className="InputContainer">
                <div className="DropDownContainer">
                  <Select
                    value={value}
                    onChange={(value) => onChange(value)}
                    placeholder={placeholder}
                  >
                    {options &&
                      options.map((item) => (
                        <Option key={item.value} value={item.label}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </div>
              </Group>
              {/* {err[name] && <span className="ValidateInput">{err[name]}</span>} */}
            </Col>
          </Row>
        )
      }
      case 'select': {
        const { options = [], placeholder } = props

        return (
          <Row>
            <Col
              span={spanLabel}
              className={`LabelContainer ${!inputMode && 'ModeViewContainer'}  ${labelCustomClass}`}
            >
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col span={24 === spanLabel ? 24 : 24 - spanLabel}>
              {inputMode ? (
                <>
                  <Select
                    className={`${error[name] && 'ValidInputSelect'} `}
                    showSearch
                    style={{ width: 200 }}
                    placeholder={placeholder}
                    optionFilterProp="children"
                    value={value}
                    onChange={(e) => onChange(e)}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {options &&
                      options.map((item) => (
                        <Option key={item.value} value={item.value}>
                          {item.label}
                        </Option>
                      ))}
                  </Select>
                  <div className="ValidateInput"><span>{error[name]}</span></div>
                </>
              ) : (
                <div className={`DataViewContainer ${dataViewCustom}`}>{value}</div>
              )}
            </Col>
          </Row>
        )
      }
      case 'date-picker': {
        const { picker, format, placeholder, value, disabledDate = () => { } } = props
        return (
          <Row>
            <Col className={`LabelContainer ${!inputMode && 'ModeViewContainer'}`}>
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            {inputMode ? (
              <>
                <Col>
                  <DatePicker
                    className={`DatePickerContainer ${error[name] && 'ValidInput'} `}
                    format={!format ? 'DD/MM/YYYY' : format}
                    picker={picker}
                    placeholder={placeholder}
                    locale={locale}
                    onChange={(date, dateString) => onChange(date, dateString)}
                    value={value ? moment(value) : ''}
                    allowClear={false}
                    disabledDate={disabledDate}
                    inputReadOnly={true}
                  />
                </Col>
                <span className="ValidateInput">{error[name]}</span>
              </>
            ) : (
              <Col>
                <div className="DataViewContainer">{moment(value).format('DD/MM/YYYY')}</div>
              </Col>
            )}
          </Row>
        )
      }

      case 'range-picker': {
        const { allowClear, disabledDate } = props
        return (
          <Row>
            <Col className={`LabelContainer ${!inputMode && 'ModeViewContainer'}`}>
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col>
              <DatePicker.RangePicker
                className={`DatePickerContainer ${error[name] && 'ValidInput'} `}
                format={!format ? 'DD/MM/YYYY' : format}
                // picker={picker}
                allowEmpty={[true, true]}
                placeholder={placeholder}
                locale={locale}
                onChange={(date, dateString) => onChange(date, dateString)}
                value={value}
                allowClear={allowClear}
                disabledDate={disabledDate}
                inputReadOnly={true}
              />
            </Col>
            <span className="ValidateInput">{error[name]}</span>
          </Row>
        )
      }

      case 'checkbox': {
        return (
          <Row>
            <Col>
              <Checkbox onChange={(e) => onChange(e)} name={name} checked={value}>
                {props.children}
              </Checkbox>
            </Col>
          </Row>
        )
      }

      case 'input-number': {
        return (
          <Row>
            <Col className={`LabelContainer ${!inputMode && 'ModeViewContainer'}`}>
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col>
              {inputMode ? (
                <div>
                  <InputNumber
                    className="InputContainer"
                    style={{ border: 'none' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    name={name}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    prefix={<span>$</span>}
                  />
                  <span style={{ position: 'absolute', right: '2%', top: '12px' }}>VND</span>
                </div>
              ) : (
                <div className="DataViewContainer">{value}</div>
              )}
            </Col>
            <span className="ValidateInput">{error[name]}</span>
          </Row>
        )
      }
      case 'number': {
        return (
          <Row>
            <Col className={`LabelContainer ${!inputMode && 'ModeViewContainer'}`}>
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col>
              {inputMode ? (
                <div>
                  <InputNumber
                    className="InputContainer"
                    style={{ border: 'none' }}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    name={name}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                    prefix={null}
                  />
                  {suffix && <span style={{ position: 'absolute', right: '2%', top: '12px' }}>{suffix}</span>}
                </div>
              ) : (
                <div className="DataViewContainer">{value}</div>
              )}
            </Col>
            <span className="ValidateInput">{error[name]}</span>
          </Row>
        )
      }
      case 'text-area': {
        return (
          <Row>
            <Col
              span={spanLabel}
              className={`LabelContainer ${!inputMode && 'ModeViewContainer'} ${labelCustomClass}`}
            >
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col span={24 === spanLabel ? 24 : 24 - spanLabel}>
              {inputMode ? (
                <>
                  <textarea
                    className={`InputContainer ${classNameCustom} 
                          ${error && error[name] && 'ValidInput'}`}
                    name={name}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                  />
                </>
              ) : (
                <div className={`DataViewContainer ${dataViewCustom}`}>{value}</div>
              )}
            </Col>
            <span className="ValidateInput">{error && error[name]}</span>
          </Row>
        )
      }

      default: {
        const { error, customSuffix } = props
        return (
          <Row>
            <Col
              span={spanLabel}
              className={`LabelContainer ${!inputMode && 'ModeViewContainer'} ${labelCustomClass}`}
            >
              {label} {isRequire && inputMode && <span>*</span>}
            </Col>
            <Col span={24 === spanLabel ? 24 : 24 - spanLabel}>
              {inputMode ? (
                <>
                  <input
                    className={`InputContainer ${classNameCustom} 
                          ${error && error[name] && 'ValidInput'}`}
                    name={name}
                    value={value}
                    autoComplete="off"
                    placeholder={placeholder}
                    onChange={(e) => onChange(e)}
                  />
                  <span
                    style={{ position: 'absolute', right: '2%', top: '12px' }}
                    className={customSuffix}
                  >
                    {suffix}
                  </span>
                </>
              ) : (
                <div className={`DataViewContainer ${dataViewCustom}`}>{value}</div>
              )}
            </Col>
            <span className="ValidateInput">{error && error[name]}</span>
          </Row>
        )
      }
    }
  }
  // console.log('==========onchange====input', Boolean(error))
  return <> {switchComponent()}</>
}

// export default InputComponents
export const InputComponents = React.memo(InputContainer)
