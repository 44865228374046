import { Box, Grid, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import phone from '../../components/home/phone.svg'
import RegisterModal from '../register/RegisterModal'
import { INFORMATION_TAG } from './Constant'
import './styles.scss'
import img_right from './svg/img-right.svg'
import { Partner } from '../../common/Constant'
import AdminApplicationService from '../../services/admin/application/AdminApplicationService'
import Loading from '../../components/Loading'
import { LoanPartner } from './LoanPartner'
import logo_tp from './svg/tpbank.svg'
import logo_fm from './svg/fastmoney.svg'
import logo_tcb from './svg/tcb.svg'
import historyUtil from '../../common/historyUtil'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { ApplicationStatus, convertValue } from '../../utils/Utils'
import { formatMoney } from '../../utils/FormatNumber'
import moment from 'moment'
import IconCheck from './svg/circle_check.svg'
import AdminLeadService from '../../services/admin/lead/AdminLeadService'

const SapoFinLandingPage = () => {
  const history = useHistory()
  const [showRegisterModal, setShowRegisterModal] = useState(false)
  const [partnerId, setPartnerId] = useState()
  const [loading, setLoading] = useState(false)
  const [application, setApplication] = useState({})
  const leadExtraInfo = queryString.parse(history.location.search);

  useEffect(() => {
    setLoading(true)
    AdminApplicationService.getCurrentApplication(Partner.FASTMONEY)
      .then((result) => {
        if (result && result?.application) {
          setApplication(result?.application)
        }
      })
      .finally(() => {
        setLoading(false)
      })
    return () => {
      console.log('end')
    }
  }, [])

  const handleRegister = (id) => {
    setPartnerId(id)
    setShowRegisterModal(true)
  }

  useEffect(() => {
    if (leadExtraInfo.source && leadExtraInfo.tenant) {
      const lead = {
        source: leadExtraInfo.source,
        domain: leadExtraInfo.tenant
      }
      AdminLeadService.createLeadFromBanner(lead);
    }
  }, [leadExtraInfo])

  return loading ? (
    <Loading open={loading} />
  ) : (
    <>
      <Box className="landingPage">
        <Box className="landingContainer ">
          <Box className="landingOptionsContainer">
            <Box className="text-center" style={{ display: 'flex', justifyContent: 'center' }}>
              <Box className="col-sm-8 col-lg-8 col-xs-12 text-center" style={{ marginTop: 35 }}>
                {/* <Box>
                  <img src={tpBank} alt="Ngân hàng TPBank" />

                  <img src={sapoLogo} className="ml-2" alt="Công ty Sapo" />
                </Box> */}
                <Box className="mt-3">
                  <Typography
                    style={{
                      fontWeight: 'bold',
                      fontSize: '20px',
                      lineHeight: '36px',
                      color: '#182537',
                    }}
                  >
                    Cần vốn ngay, online vay
                  </Typography>
                  <Typography className="font-text">
                    Dành riêng cho chủ shop sử dụng dịch vụ Sapo trên 3 tháng
                  </Typography>
                  <Box
                    className="text-left"
                    style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}
                  >
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Không tài
                      sản đảm bảo
                    </span>
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Quy trình
                      100% online
                    </span>
                    <span className="d-block font-text mt-2">
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i> Giải ngân
                      trong 24h làm việc
                    </span>
                  </Box>
                </Box>
                {/* <Box>
                  <button
                    className="btn btn-primary mt-3 btn-regis"
                    onClick={() => setShowRegisterModal(true)}
                  >
                    Đăng ký vay vốn ngay
                  </button>
                </Box> */}
              </Box>
              <Box className="col-sm-4 col-lg-4 col-xs-12">
                <img
                  src={img_right}
                  style={{ maxWidth: '100%', objectFit: 'cover', height: '200px' }}
                />
              </Box>
            </Box>
          </Box>
          <Box className="landingOptionsContainer" style={{ marginTop: 32 }}>
            <Grid container>
              {INFORMATION_TAG.map((item, index) => (
                <Grid item key={index} xl={3} xs={3} sm={6} md={3} style={{ textAlign: 'center' }}>
                  <Box className={'FinOptionsContainer'} style={{ height: '210px' }}>
                    <Box className="ImgFinOption">
                      <img src={item.icon} />
                    </Box>
                    <Box className="FinOptionTitle">{item.title}</Box>
                    <Box className="FinOptionDescription">{item.content}</Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box className="landingOptionsContainer" style={{ marginTop: 32 }}>
            <Grid container spacing={2}>
              <LoanPartner
                icon={logo_tp}
                title={'Sản phẩm chuyên biệt dành cho nhà bán hàng dùng Sapo trên 3 tháng'}
                content={
                  <>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Giải ngân trong 24h làm
                      việc
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh, tính
                      trên dư nợ giảm dần
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Không phát sinh phí ngoài
                      lãi suất
                    </p>
                  </>
                }
                total={'70,000,000 VND'}
                limitTime={'6 - 36 tháng'}
                interestRate={'2.75%/tháng'}
                onAction={() => handleRegister(Partner.TPD)}
                actionContent={'Đăng ký ngay'}
              />
              <LoanPartner
                icon={logo_tcb}
                title={'Vay thấu chi online - không tài sản đảm bảo'}
                content={
                  <>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Giải ngân trong 24h làm
                      việc
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh, tính
                      trên dư nợ giảm dần
                    </p>
                    <p style={{ marginBottom: 0 }}>
                      <span style={{ textAlign: 'center' }}>{' . '}</span>Không phát sinh phí ngoài
                      lãi suất
                    </p>
                  </>
                }
                total={'100,000,000 VND'}
                limitTime={'12 tháng'}
                interestRate={'0.99%/tháng'}
                onAction={() => handleRegister(Partner.TCB)}
                actionContent={'Đăng ký ngay'}
              />
              {application?.id &&
              application?.partner_contact_id &&
              [ApplicationStatus.DISBURSED, ApplicationStatus.OVERDUE_PAYMENT].includes(
                application?.status
              ) ? (
                <LoanPartner
                  isDetail
                  icon={logo_fm}
                  title={
                    <>
                      <img src={IconCheck} /> Bạn đang có khoản vay tại FastMoney
                    </>
                  }
                  content={
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ textAlign: 'center' }}>{' . '}</span>Mã hợp đồng:{' '}
                        <strong style={{ color: '#0F1824' }}>
                          {convertValue(application?.partner_contact_id)}
                        </strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ textAlign: 'center' }}>{' . '}</span>Ngày giải ngân:{' '}
                        <strong style={{ color: '#0F1824' }}>
                          {application?.disbursed_date
                            ? moment(application?.disbursed_date).format('DD/MM/YYYY')
                            : '---'}
                        </strong>
                      </p>
                      <p style={{ marginBottom: 0 }}>&nbsp;</p>
                    </>
                  }
                  total={convertValue(formatMoney(application?.amount) + ' VND')}
                  limitTime={convertValue(application?.time) + ' tháng'}
                  interestRate={(application?.interest_rate_applied / 12).toFixed(2) + '%/tháng'}
                  onAction={() =>
                    historyUtil(history, `/los/loan-history/application/${application.id}`)
                  }
                  actionContent={'Xem chi tiết & trả nợ'}
                />
              ) : (
                <LoanPartner
                  icon={logo_fm}
                  title={'Sản phẩm chuyên biệt dành cho nhà bán hàng dùng Sapo trên 6 tháng'}
                  content={
                    <>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ textAlign: 'center' }}>{' . '}</span>Giải ngân trong 3-5 phút
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ textAlign: 'center' }}>{' . '}</span>Lãi suất cạnh tranh,
                        tính trên dư nợ giảm dần
                      </p>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ textAlign: 'center' }}>{' . '}</span>Phí dịch vụ 0,018%/ngày
                      </p>
                    </>
                  }
                  total={'50,000,000 VND'}
                  limitTime={'3 - 12 tháng'}
                  interestRate={'1.75%/tháng'}
                  onAction={() => handleRegister(Partner.FASTMONEY)}
                  actionContent={'Đăng ký ngay'}
                />
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box>
        <div className="hotline-phone-ring-wrap">
          <a href="tel:02473086880">
            <img src={phone} alt="Gọi điện thoại" width={50} />
          </a>
        </div>
        {/* <div className="hotline-message-ring-wrap">
          <a href="tel:02473086880" >
            <img src={mess} alt="Gọi điện thoại" width={50} />
          </a>
        </div> */}
      </Box>
      <RegisterModal
        isLanding
        partnerId={partnerId}
        visible={showRegisterModal}
        open={() => setShowRegisterModal(true)}
        close={() => setShowRegisterModal(false)}
      />
    </>
  )
}

export default SapoFinLandingPage
