import jwt_decode from 'jwt-decode'
import queryString from 'query-string'
// import axios from 'axios'

const tokenStorage = {
  async getJwt() {
    return this.getToken()
  },
  getDomain() {
    const ancestorOrigins = window.location.ancestorOrigins[0]
    let alias = ancestorOrigins ? ancestorOrigins : queryString.parse(window.location.href)?.tenant
    if (!alias) {
      alias = localStorage.getItem('origin')
    }
    if (alias) {
      const domain = alias
        ?.replace('https://', '')
        ?.replace('http://', '')
        ?.split('.')[0]
      if (domain) {
        return domain
      }
    }
    return null
  },
  saveJwt(jwt) {
    if (jwt) {
      const domain = this.getDomain()
      if (domain) {
        localStorage.setItem(`${domain}_token`, jwt)
      }
    }
  },
  getRefreshToken() {
    const refreshToken = localStorage.getItem('refreshToken')
    if (refreshToken == null) return null
    if (this.tokenExprired(refreshToken)) {
      localStorage.removeItem('refreshToken')
      return null
    }
    return refreshToken
  },
  getToken() {
    const domain = this.getDomain()
    if (domain) {
      const jwt = localStorage.getItem(`${domain}_token`)
      if (jwt) {
        if (this.tokenExprired(jwt)) {
          localStorage.removeItem(`${domain}_token`)
          return null
        }
        return jwt
      }
    }
    return null
  },
  saveRefreshToken(refreshToken) {
    if (!refreshToken) {
      return
    }
    localStorage.setItem('refreshToken', refreshToken)
  },
  tokenExprired(token) {
    const tokenObject = jwt_decode(token)
    if (tokenObject.exp < new Date().getTime() / 1000) {
      return true
    }
    return false
  },
}
export default tokenStorage
