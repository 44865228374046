/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Button } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import historyUtil from '../../common/historyUtil'
import DoneGIcon from '../../components/DoneGIcon.svg'
import RegisterSuccessfulImg from '../../components/RegisterSuccessfulImg.svg'
export default function NotificationModal(props) {
  const { leadId = '' } = props
  const history = useHistory()
  return (
    <>
      <div className="noti-successful-content">
        <img className="noti-successful-img" src={RegisterSuccessfulImg} alt="đăng ký thành công" />
        <div className="noti-successful-title">
          <img className="sqr-icon-register-lead" src={DoneGIcon} alt="can't load img" />
          <span>Đăng ký tư vấn vay vốn thành công!</span>
        </div>
        <div style={{ marginTop: 24 }}>
          <span>Yêu cầu đăng ký vay vốn của quý khách đã được tiếp nhận,</span>
        </div>
        <div>
          <span>
            chuyên viên tư vấn tài chính sẽ lập tức liên hệ quý khách để hướng dẫn bổ sung hồ sơ.
          </span>
        </div>
        <div>
          <span>Mọi thông tin cần hỗ trợ vui lòng liên hệ:</span>
          <span style={{ fontWeight: '400', color: '#0088FF' }}> (024) 730.868.80</span>{' '}
          <span>(nhấn phím 803)</span>
        </div>
        <Button
          className="btn-view-application mt-4"
          onClick={() => historyUtil(history, `/los/loan-history/lead/${leadId}`)}
        >
          Xem chi tiết
        </Button>
      </div>
    </>
  )
}
