import React, { useCallback, useEffect, useState } from 'react'
import { Modal } from 'antd'
import AccumulationForm from './AccumulationForm'
import AccumulationModalHeader from './AccumulationModalHeader'
import AccumulationSuccessfulNotify from './AccumulationSuccessfulNotify'
export default function AccumulationModal(props) {
    const { tenant, productCode, visible, open, close, partnerLogo } = props
    const [step, setStep] = useState(0)
    const [isVisible, setIsVisible] = useState(false)
    useEffect(() => {
        setIsVisible(visible)
    }, [visible])
    console.log("tennant model ", tenant);
    const [product, setProduct] = useState(productCode)
    useEffect(() => {
        setProduct(productCode)
    }, [productCode])
    return (

        <Modal
            title={<AccumulationModalHeader step={step} partnerLogo={partnerLogo} />}
            visible={visible}
            // onOk={() => setIsVisible(true)}
            // onCancel={() => { setIsVisible(false) }}
            onOk={() => open()}
            onCancel={() => { setStep(0); close() }}
            width={1024}
            footer={null}
            keyboard={false}
            maskClosable={true}
            closable={true}
            destroyOnClose={true}
            className={"accumulationModal"}
        >
            {step === 0 && <AccumulationForm setStep={setStep} tenant={tenant} productCode={product} />}
            {step === 1 && <AccumulationSuccessfulNotify />}
        </Modal >
    )
}
