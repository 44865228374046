import { Chip } from '@material-ui/core'
import React from 'react'
import { toString } from 'lodash'

export const convertValue = (value, subfix) => {
  if (value) {
    return toString(value) + (subfix ? subfix : '')
  }
  return '---'
}

export const getBusinesTypeName = (value) => {
  switch (value) {
    case 'HKD':
      return 'Hộ kinh doanh'
    case 'CN':
      return 'Cá nhân'
    case 'DN':
      return 'Doanh nghiệp'
  }
}

export const ApplicationStatus = {
  UPDATING: 0, // Đang cập nhật trạng thái
  NEW: 1, // Khởi tạo
  WAITING_TRANSFERENCE: 2,
  REJECTED_TRANSFERENCE: 3, // Từ chối duyệt
  TRANSFERENCE: 4, // "Đã chuyển đối tác
  DISBURSED: 5, // Đã giải ngân
  REJECT: 6, // Đối tác từ chối
  REFUSAL: 7, // Khách hàng từ chối
  CANCEL: 8, // Đã hủy
  APPROVED: 9, // Đã phê duyệt
  REVIEW_APPLICATION: 10, // Yêu cầu bổ sung
  COMPLETE_PAYMENT: 11, // Đã tất toán
  OVERDUE_PAYMENT: 12, // Quá hạn thanh toán khoản vay
  getName: (status) => {
    switch (status) {
      case ApplicationStatus.NEW:
        return 'Khởi tạo'
      case ApplicationStatus.REJECTED_TRANSFERENCE:
        return 'Từ chối duyệt'
      case ApplicationStatus.REJECT:
        return 'Đối tác từ chối'
      case ApplicationStatus.WAITING_TRANSFERENCE:
        return 'Chờ chuyển đối tác'
      case ApplicationStatus.TRANSFERENCE:
        return 'Đã chuyển đối tác'
      case ApplicationStatus.CANCEL:
        return 'Đã hủy'
      case ApplicationStatus.DISBURSED:
        return 'Đã giải ngân'
      case ApplicationStatus.REFUSAL:
        return 'Khách hàng từ chối'
      case ApplicationStatus.APPROVED:
        return 'Đã phê duyệt'
      case ApplicationStatus.REVIEW_APPLICATION:
        return 'Yêu cầu bổ sung'
      case ApplicationStatus.COMPLETE_PAYMENT:
        return 'Đã tất toán'
      case ApplicationStatus.OVERDUE_PAYMENT:
        return 'Đã quá hạn'
      default:
        return ''
    }
  },
  getOptions: () => {
    const options = [
      { value: ApplicationStatus.NEW, label: ApplicationStatus.getName(ApplicationStatus.NEW) },
      {
        value: ApplicationStatus.REJECTED_TRANSFERENCE,
        label: ApplicationStatus.getName(ApplicationStatus.REJECTED_TRANSFERENCE),
      },
      {
        value: ApplicationStatus.REJECT,
        label: ApplicationStatus.getName(ApplicationStatus.REJECT),
      },
      {
        value: ApplicationStatus.WAITING_TRANSFERENCE,
        label: ApplicationStatus.getName(ApplicationStatus.WAITING_TRANSFERENCE),
      },
      {
        value: ApplicationStatus.TRANSFERENCE,
        label: ApplicationStatus.getName(ApplicationStatus.TRANSFERENCE),
      },
      {
        value: ApplicationStatus.CANCEL,
        label: ApplicationStatus.getName(ApplicationStatus.CANCEL),
      },
      {
        value: ApplicationStatus.DISBURSED,
        label: ApplicationStatus.getName(ApplicationStatus.DISBURSED),
      },
      {
        value: ApplicationStatus.REFUSAL,
        label: ApplicationStatus.getName(ApplicationStatus.REFUSAL),
      },
      {
        value: ApplicationStatus.APPROVED,
        label: ApplicationStatus.getName(ApplicationStatus.APPROVED),
      },
      {
        value: ApplicationStatus.REVIEW_APPLICATION,
        label: ApplicationStatus.getName(ApplicationStatus.REVIEW_APPLICATION),
      },
      {
        value: ApplicationStatus.COMPLETE_PAYMENT,
        label: ApplicationStatus.getName(ApplicationStatus.COMPLETE_PAYMENT),
      },
      {
        value: ApplicationStatus.OVERDUE_PAYMENT,
        label: ApplicationStatus.getName(ApplicationStatus.OVERDUE_PAYMENT),
      },
    ]
    return options
  },
  getChip: (status, size) => {
    switch (status) {
      case ApplicationStatus.NEW:
        return (
          <Chip
            style={{
              border: '1px solid #99CFFF',
              color: '#99CFFF',
              backgroundColor: '#F2F9FF',
              fontSize: '11px',
              fontWeight: 400,
            }}
            label={ApplicationStatus.getName(ApplicationStatus.NEW)}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.REJECTED_TRANSFERENCE:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.REJECTED_TRANSFERENCE)}
            style={{
              border: '1px solid #FFB8B8',
              color: '#EE4747',
              backgroundColor: '#FFF6F6',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.REJECT:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.REJECT)}
            style={{
              border: '1px solid #FFB8B8',
              color: '#EE4747',
              backgroundColor: '#FFF6F6',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.WAITING_TRANSFERENCE:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.WAITING_TRANSFERENCE)}
            style={{
              border: '1px solid #FFDF9B',
              color: '#E49C06',
              backgroundColor: '#FFF7E7',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.TRANSFERENCE:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.TRANSFERENCE)}
            style={{
              border: '1px solid #FFDF9B',
              color: '#E49C06',
              backgroundColor: '#FFF7E7',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.CANCEL:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.CANCEL)}
            style={{
              border: '1px solid #FFB8B8',
              color: '#EE4747',
              backgroundColor: '#FFF6F6',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.DISBURSED:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.DISBURSED)}
            style={{
              border: '1px solid #9FEDCF',
              color: '#0DB473',
              backgroundColor: '#F3FCF9',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.REFUSAL:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.REFUSAL)}
            style={{
              border: '1px solid #FFB8B8',
              color: '#EE4747',
              backgroundColor: '#FFF6F6',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.APPROVED:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.APPROVED)}
            style={{
              border: '1px solid #FFDF9B',
              color: '#E49C06',
              backgroundColor: '#FFF7E7',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.REVIEW_APPLICATION:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.REVIEW_APPLICATION)}
            style={{
              border: '1px solid #FFB8B8',
              color: '#EE4747',
              backgroundColor: '#FFF6F6',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.COMPLETE_PAYMENT:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.COMPLETE_PAYMENT)}
            style={{
              border: '1px solid #9FEDCF',
              color: '#0DB473',
              backgroundColor: '#F3FCF9',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      case ApplicationStatus.OVERDUE_PAYMENT:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.OVERDUE_PAYMENT)}
            style={{
              border: '1px solid #FFDF9B',
              color: '#E49C06',
              backgroundColor: '#FFF7E7',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
      default:
        return (
          <Chip
            label={ApplicationStatus.getName(ApplicationStatus.NEW)}
            style={{
              border: '1px solid #99CFFF',
              color: '#99CFFF',
              backgroundColor: '#F2F9FF',
              fontSize: '11px',
              fontWeight: 400,
            }}
            size={size ?? 'regular'}
          />
        )
    }
  },
}

export const fileExtensionNotAllowShow = [
  'application/pdf',
  'application/java-archive',
  'application/zip',
]
