import { Col, Row, Checkbox } from 'antd'
import React, { useEffect, useState, useCallback } from 'react'
import FormContainer from '../../component-utils/FormContainer'
import { InputComponents } from '../../component-utils/InputComponent'
import validateUtils from '../../common/validateUtils'
import { formatMoney } from '../../utils/FormatNumber'
import RegisterModal from '../register/RegisterModal'
import tenantService from '../../services/tenant/TenantService'
import NotificationModal from '../register/NotificationModal'
import Loading from '../../components/Loading'
import Products from './Product'
import calculatorService from '../../services/calculator/CalculatorService'
import { compareArrays } from '../../utils/Comparator'
import { Container } from 'react-bootstrap'
import { sortingProducts } from '../../utils/ProductSorting'
import { orderByOpts } from '../../common/Constant'
import {
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { formatCurrencyCal } from '../../common/functionUtils'

export default function CalculatorLoanPayMonth() {
  const [products, setProducts] = useState([])
  const [currentProduct, setCurrentProduct] = useState({})
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const [showNotiForm, setShowNotiForm] = useState(false)
  const [tenant, setTenant] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setErr] = useState({})
  const [loanPayMonth, setLoanPayMonth] = useState()
  const [isAccept, setIsAccept] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenLoanPer, setIsOpenLoanPer] = useState(false)
  const [interestMonths, setInterestMonths] = useState([])
  const [loanLimit, setLoanLimit] = useState(0)
  const [sorting, setSorting] = useState({ value: '', label: 'Sắp xếp' })
  const [data, setData] = useState({
    loanAmount: '', // số tiền vay
    interestRatePerMonth: '', // lãi suất vay
    term: '', // thời gian vay
    prepaymentTerm: '', // thời gian dự tính thanh toán
    prepaymentPenaltyFee: '', // phí phạt
    interestRateMethod: '1', // phương pháp tính lãi
    checkPrepayment: '1', // check tính phạt
  })
  const {
    loanAmount,
    interestRatePerMonth,
    term,
    prepaymentTerm,
    prepaymentPenaltyFee,
    interestRateMethod,
    checkPrepayment,
  } = data

  const setError = useCallback(
    (e) => {
      setTimeout(() => {
        setErr(e)
      }, 0)
    },
    [error]
  )
  const handleChangeCurrentProduct = (product) => {
    setCurrentProduct(product)
  }

  const handleRegisterSuccessful = () => {
    setShowRegisterForm(false)
    setShowNotiForm(true)
  }

  useEffect(() => {
    tenantService.getTenantInfo().then(
      (result) => {
        if (result) setTenant(result.tenant)
      },
      (error) => {
        // console.log(error.response);
      }
    )
  }, [])

  useEffect(() => {
    setTenant({
      ...tenant,
      loanProductCode: currentProduct.code,
      partnerLogo: currentProduct.logo,
    })
  }, [currentProduct])

  function handleFetchProduct(loanAmount, term, loanLimit) {
    const loanAmounts = parseFloat(
      loanAmount
        .toString()
        .replaceAll(',', '')
        .trim()
    )
    const terms = parseFloat(term.toString().trim())
    setIsLoading(true)
    calculatorService
      .getProductList(loanAmounts, terms, loanAmounts)
      .then((result) => {
        if (result && result.data && result.data.loan_products) {
          if (!compareArrays(products, result.data.loan_products)) {
            setProducts(result.data.loan_products)
            setSorting({ ...orderByOpts[3] })
          }
        }
      })
      .catch((e) => {
        console.log(e)
      })
      .finally(() => setIsLoading(false))
  }

  function getLoanPeriodMonths(
    loanAmount,
    term,
    interestRatePerMonth,
    prepaymentTerm,
    prepaymentPenaltyFee,
    interestRateMethod,
    checkPrepayment
  ) {
    const check = isOpen

    const loanAmounts = parseFloat(
      loanAmount
        .toString()
        .replaceAll(',', '')
        .trim()
    )
    const terms = parseFloat(term.toString().trim())
    const interests = parseFloat(interestRatePerMonth.toString().trim())
    const interest = interests / 100
    const interestFees = parseFloat(prepaymentPenaltyFee.toString().trim())
    const interestFee = interestFees / 100
    setLoanLimit(loanAmounts)
    if (isOpen) {
      const prepaymentTermss = parseFloat(
        prepaymentTerm
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      calculatorService
        .getLoanPeriodMonth(
          loanAmounts,
          terms,
          interest,
          prepaymentTermss,
          interestFee,
          interestRateMethod,
          check
        )
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.interest &&
            result.data.interest.interest_months
          ) {
            setLoanPayMonth(result.data)
            setInterestMonths(result.data.interest.interest_months)
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => setIsLoading(false))
    } else {
      calculatorService
        .getLoanPeriodMonth(
          loanAmounts,
          terms,
          interest,
          prepaymentTerm,
          interestFee,
          interestRateMethod,
          check
        )
        .then((result) => {
          if (
            result &&
            result.data &&
            result.data.interest &&
            result.data.interest.interest_months
          ) {
            setLoanPayMonth(result.data)
            setInterestMonths(result.data.interest.interest_months)
          }
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => setIsLoading(false))
    }
    handleFetchProduct(loanAmounts, terms, loanAmounts)
  }

  const r = products.map((product, i) => (
    <Products
      key={i}
      index={i}
      product={product}
      changeCurrentProduct={handleChangeCurrentProduct}
      showRegisterForm={(value) => setShowRegisterForm(value)}
    />
  ))

  function checkNull() {
    if (isOpen) {
      if (
        loanAmount === '' ||
        loanAmount === null ||
        loanAmount === undefined ||
        interestRatePerMonth === '' ||
        interestRatePerMonth === null ||
        interestRatePerMonth === undefined ||
        term === '' ||
        term === null ||
        term === undefined ||
        prepaymentTerm === '' ||
        prepaymentTerm === null ||
        prepaymentTerm === undefined ||
        prepaymentPenaltyFee === '' ||
        prepaymentPenaltyFee === null ||
        prepaymentPenaltyFee === undefined
      ) {
        setIsAccept(false)
      } else {
        setIsAccept(true)
      }
    } else {
      if (
        loanAmount === '' ||
        loanAmount === null ||
        loanAmount === undefined ||
        interestRatePerMonth === '' ||
        interestRatePerMonth === null ||
        interestRatePerMonth === undefined ||
        term === '' ||
        term === null ||
        term === undefined
      ) {
        setIsAccept(false)
      } else {
        setIsAccept(true)
      }
    }
  }

  useEffect(() => {
    checkNull()
  })

  function onSubmit() {
    const err = {}

    validateUtils(
      {
        key: 'loanAmount',
        value: loanAmount,
        lableErr: 'số tiền vay',
        isRequire: true,
      },
      err
    )
    validateUtils(
      {
        key: 'interestRatePerMonth',
        value: interestRatePerMonth,
        lableErr: 'lãi suất vay',
        isRequire: true,
      },
      err
    )
    validateUtils(
      {
        key: 'term',
        value: term,
        lableErr: 'thời gian vay',
        isRequire: true,
      },
      err
    )
    if (loanAmount) {
      const loanAmountValid = parseFloat(
        loanAmount
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (isNaN(loanAmountValid)) {
        err.interestRate = 'Nhập số tiền vay hợp lệ'
      }
      if (!/^[\d,]+$/.test(loanAmount.toString().trim())) {
        err.loanAmount = 'Nhập số tiền vay hợp lệ'
      }
      if (loanAmountValid > 100000000000) {
        err.loanAmount = 'Số tiền vay không được lớn hơn 100.000.000.000'
      }
      if (loanAmountValid < 0) {
        err.loanAmount = 'Số tiền vay không được nhỏ hơn 0'
      }
    }
    if (term) {
      const termValid = parseFloat(
        term
          .toString()
          .replaceAll(',', '')
          .trim()
      )
      if (isNaN(termValid)) {
        err.term = 'Nhập thời gian vay hợp lệ'
      }
      if (!/^[\d,]+$/.test(term.toString().trim())) {
        err.term = 'Nhập thời gian vay hợp lệ'
      }
      if (termValid > 60) {
        err.term = 'Thời gian vay không được lớn hơn 60'
      }
      if (termValid <= 0) {
        err.term = 'Thời gian vay phải lớn hơn 0'
      }
    }
    if (interestRatePerMonth) {
      const interestRates = parseFloat(interestRatePerMonth.toString().trim())
      const index = interestRates.toString().indexOf('.')
      const interestRateValids = interestRates.toString().slice(index + 1)
      if (isNaN(interestRates)) {
        err.interestRatePerMonth = 'Nhập lãi suất vay hợp lệ'
      }
      if (!/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(interestRatePerMonth.toString().trim())) {
        err.interestRatePerMonth = 'Nhập lãi suất vay hợp lệ'
      } else {
        if (interestRates < 0) {
          err.interestRatePerMonth = 'Lãi suất vay không được nhỏ hơn 0'
        }
        if (interestRateValids.length > 3) {
          err.interestRatePerMonth = 'Lãi suất vay lấy tối đa 3 số sau dấu thập phân'
        }
        if (interestRates > 100) {
          err.interestRatePerMonth = 'Lãi suất vay không được lớn hơn 100%'
        }
      }
    }

    if (isOpen) {
      validateUtils(
        {
          key: 'prepaymentTerm',
          value: prepaymentTerm,
          lableErr: 'thời gian dự tính thanh toán',
          isRequire: true,
        },
        err
      )
      validateUtils(
        {
          key: 'prepaymentPenaltyFee',
          value: prepaymentPenaltyFee,
          lableErr: 'lãi suất thanh toán trước hạn',
          isRequire: true,
        },
        err
      )
      if (prepaymentPenaltyFee) {
        const prepaymentPenaltyFees = parseFloat(prepaymentPenaltyFee.toString().trim())
        const index = prepaymentPenaltyFees.toString().indexOf('.')
        const interestRateValids = prepaymentPenaltyFees.toString().slice(index + 1)
        if (isNaN(prepaymentPenaltyFees)) {
          err.prepaymentPenaltyFee = 'Nhập phí phạt hợp lệ'
        }
        if (!/^([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(prepaymentPenaltyFee.toString().trim())) {
          err.prepaymentPenaltyFee = 'Nhập phí phạt hợp lệ'
        } else {
          if (prepaymentPenaltyFees < 0) {
            err.prepaymentPenaltyFee = 'Phí phạt không được nhỏ hơn 0'
          }
          if (interestRateValids.length > 3) {
            err.prepaymentPenaltyFee = 'Phí phạt lấy tối đa 3 số sau dấu thập phân'
          }
          if (prepaymentPenaltyFees > 100) {
            err.prepaymentPenaltyFee = 'Phí phạt không được lớn hơn 100%'
          }
        }
      }
      if (prepaymentTerm) {
        const prepaymentTermValid = parseFloat(
          prepaymentTerm
            .toString()
            .replaceAll(',', '')
            .trim()
        )
        if (isNaN(prepaymentTermValid)) {
          err.prepaymentTerm = 'Nhập thời gian dự tính thanh toán hợp lệ'
        }
        if (!/^[\d,]+$/.test(prepaymentTerm.toString().trim())) {
          err.prepaymentTerm = 'Nhập thời gian dự tính thanh toán hợp lệ'
        }
        if (prepaymentTermValid > 60) {
          err.prepaymentTerm = 'Nhập thời gian dự tính thanh toán không được lớn hơn 60'
        }
        if (prepaymentTermValid <= 0) {
          err.prepaymentTerm = 'Nhập thời gian dự tính thanh toán phải lớn hơn 0'
        }
        const termValid = parseFloat(
          term
            .toString()
            .replaceAll(',', '')
            .trim()
        )

        if (prepaymentTermValid > termValid) {
          err.prepaymentTerm = 'Thời gian dự tính thanh toán phải nhỏ hơn thời gian vay'
        }
      }
    }

    if (Object.keys(err).length !== 0) {
      setError(err)
      return
    }
    setIsOpenLoanPer(true)
    getLoanPeriodMonths(
      loanAmount,
      term,
      interestRatePerMonth,
      prepaymentTerm,
      prepaymentPenaltyFee,
      interestRateMethod,
      checkPrepayment
    )
    // handleFetchProduct(loanAmount, term, loanAmount)
  }
  useEffect(() => {
    const result = sortingProducts(products, sorting)
    if (!compareArrays(result, products)) setProducts(result)
  }, [sorting])
  return (
    <div>
      <div className="CalculatorContainer">
        <div>
          <Row gutter={24}>
            <Col span={16} style={{ paddingLeft: '0px' }}>
              <div className="font14">
                <br></br>
                <p>
                  Nhập thông tin tài chính của bạn, Sapo Money sẽ giúp bạn tính toán số tiền trả hàng
                  tháng nhanh chóng và chính xác nhất
                </p>
              </div>
              <Row gutter={24}>
                <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputComponents
                    label="Số tiền vay"
                    placeholder="Nhập số tiền vay"
                    isRequire={true}
                    data={data}
                    onChangeData={setData}
                    setError={setError}
                    error={error}
                    value={formatCurrencyCal(loanAmount.toString().trim())}
                    name="loanAmount"
                    labelCustomClass="LabelCustomPadding"
                    dataViewCustom="DataViewCustom"
                    suffix="VND"
                    customSuffix="CustomSuffix"
                    labelError="số tiền vay"
                  />
                </FormContainer>

                <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputComponents
                    label="Thời gian vay"
                    placeholder="Nhập thời gian vay"
                    isRequire={true}
                    data={data}
                    onChangeData={setData}
                    setError={setError}
                    error={error}
                    value={formatCurrencyCal(term.toString().trim())}
                    name="term"
                    labelCustomClass="LabelCustomPadding"
                    dataViewCustom="DataViewCustom"
                    suffix="tháng"
                    customSuffix="CustomSuffix"
                    labelError="thời gian vay"
                  />
                </FormContainer>

                <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputComponents
                    label="Lãi suất vay"
                    placeholder="Lãi suất hiện tại khoảng 1,5"
                    isRequire={true}
                    data={data}
                    onChangeData={setData}
                    setError={setError}
                    error={error}
                    value={interestRatePerMonth}
                    name="interestRatePerMonth"
                    labelCustomClass="LabelCustomPadding"
                    dataViewCustom="DataViewCustom"
                    suffix="%/tháng"
                    customSuffix="CustomSuffix"
                    labelError="lãi suất vay"
                  />
                </FormContainer>

                <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                  <InputComponents
                    isRequire={true}
                    data={data}
                    onChangeData={setData}
                    value={formatCurrencyCal(interestRateMethod.toString())}
                    name="interestRateMethod"
                    label="Phương pháp tính lãi"
                    setError={setError}
                    error={error}
                    labelCustomClass="LabelCustomPadding"
                    dataViewCustom="DataViewCustom"
                    suffix="%/năm"
                    customSuffix="CustomSuffix"
                    labelError="lãi suất vay"
                    type="select"
                    options={[
                      { label: 'Tính lãi trên dư nợ giảm dần', value: '1' },
                      { label: 'Tính lãi trên dư nợ gốc', value: '2' },
                    ]}
                  />
                </FormContainer>

                <Col span={24}>
                  <Checkbox checked={isOpen} onChange={(e) => setIsOpen(e.target.checked)}>
                    Thanh toán trước hạn
                  </Checkbox>
                </Col>

                <Col span={24}>
                  <Collapse in={isOpen}>
                    <Row gutter={24}>
                      <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputComponents
                          label="Thời gian dự tính thanh toán"
                          placeholder="Nhập thời gian dự tính thanh toán"
                          isRequire={true}
                          data={data}
                          onChangeData={setData}
                          setError={setError}
                          error={error}
                          value={formatCurrencyCal(prepaymentTerm.toString())}
                          name="prepaymentTerm"
                          labelCustomClass="LabelCustomPadding"
                          dataViewCustom="DataViewCustom"
                          suffix="tháng"
                          customSuffix="CustomSuffix"
                          labelError="thời gian dự tính thanh toán"
                        />
                      </FormContainer>

                      <FormContainer xs={12} sm={12} md={12} lg={12} xl={12}>
                        <InputComponents
                          label="Phí phạt thanh toán trước hạn"
                          placeholder="Thông thường từ 1-3"
                          isRequire={true}
                          data={data}
                          onChangeData={setData}
                          setError={setError}
                          error={error}
                          value={prepaymentPenaltyFee}
                          name="prepaymentPenaltyFee"
                          labelCustomClass="LabelCustomPadding"
                          dataViewCustom="DataViewCustom"
                          suffix="%"
                          customSuffix="CustomSuffix"
                          labelError="phí phạt thanh toán trước hạn"
                        />
                      </FormContainer>
                    </Row>
                  </Collapse>
                </Col>
                <Col span={24}>
                  <div style={{ justifyContent: 'flex-end' }}>
                    <Row justify="center" style={{ marginTop: '2%', marginBottom: '3%' }}>
                      <div
                        className={`ButtonContainer ${!isAccept && 'ButtonDisable'} `}
                        onClick={isAccept ? () => onSubmit() : () => {}}
                      >
                        Xem kết quả
                      </div>
                    </Row>
                  </div>
                </Col>
                <br></br>
              </Row>
            </Col>
            <Col span={8} className="boder-left">
              <br></br>
              <div className="font14">
                Số tiền trả hàng tháng <br></br>
                <div style={{ fontSize: '24px', color: '#0088FF' }}>
                  <b>
                    {formatMoney(loanPayMonth == undefined ? 0 : loanPayMonth.total_debt_month)} VND
                  </b>
                </div>
              </div>
              <div className="font14">
                Tổng tiền phải trả
                <br></br>
                <b className="font16">
                  {formatMoney(
                    loanPayMonth == undefined ? 0 : loanPayMonth.total_principal_interest
                  )}{' '}
                  VND
                </b>
              </div>
              <div className="font14">
                Tổng tiền lãi <br></br>
                <b className="font16">
                  {formatMoney(loanPayMonth == undefined ? 0 : loanPayMonth.total_interest)} VND
                </b>
              </div>
              <div className="font14">
                Số tiền trả nợ trước hạn<br></br>
                <b className="font16">
                  {formatMoney(
                    loanPayMonth == undefined ? 0 : loanPayMonth.total_prepayment_penalty
                  )}{' '}
                  VND
                </b>
              </div>
              <div className="font14">
                Phí phạt trả nợ trước hạn<br></br>
                <b className="font16">
                  {formatMoney(loanPayMonth == undefined ? 0 : loanPayMonth.prepayment_penalty_fee)}{' '}
                  VND
                </b>
              </div>
              <br></br>

              <div style={{ fontSize: '12px' }}>
                <i>
                  <b>Ghi chú:</b> Kết quả tính toán này chỉ mang tính chất tham khảo và có thể sai
                  lệch nhỏ với kết quả tính toán thực tế tại các thời điểm
                </i>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ minHeight: '24px', backgroundColor: '#F4F4F4' }}></div>
      <div>
        <Collapse
          in={isOpenLoanPer}
          timeout="auto"
          unmountOnExit
          className="paper Shadow8 product-detail-container"
          style={{ marginLeft: '0', marginRight: '0' }}
        >
          <div style={{ marginLeft: '3%', marginTop: '15px' }}>
            <b className="font16">Lịch trả khoản vay</b>
          </div>
          <TableContainer className="lst-repayment-tbl-container-calculator">
            <Table stickyheader aria-label="list repayment table" className="lst-repayment-tbl">
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '10%' }}>Kỳ trả nợ</TableCell>
                  <TableCell style={{ width: '16%' }}>Gốc hàng tháng</TableCell>
                  <TableCell style={{ width: '16%' }}>Lãi hàng tháng</TableCell>
                  <TableCell style={{ width: '16%' }}>Số tiền trả hàng tháng</TableCell>
                  <TableCell style={{ width: '16%' }}>Số tiền trả trước hạn</TableCell>
                  <TableCell style={{ width: '16%' }}>Số dư</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {interestMonths.map(function(row, i) {
                  return (
                    <TableRow key={i}>
                      <TableCell component="th" scope="row">
                        Kỳ thứ {row.numerical_order}
                      </TableCell>
                      <TableCell>{formatMoney(row.principal_per_month)}</TableCell>
                      <TableCell>{formatMoney(row.interest_per_month)}</TableCell>
                      <TableCell>
                        {formatMoney(loanPayMonth.interest.total_pay_per_month)}
                      </TableCell>
                      <TableCell>{formatMoney(row.prepayment_penalty_fee)}</TableCell>
                      <TableCell>{formatMoney(row.surplus)}</TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Collapse>
      </div>

      <Collapse in={isOpenLoanPer}>
        <div
          style={{
            minHeight: '25px',
            backgroundColor: '#F4F4F4',
          }}
        >
          <br></br>
          <b>Sản phẩm tài chính phù hợp với bạn</b>
        </div>
        <div style={{ marginLeft: '-15px', marginRight: '-15px' }}>
          <Container fluid>
            <Loading open={isLoading} />
            {r}
          </Container>
        </div>
        <RegisterModal isProductForm={1} visible={showRegisterForm} open={() => setShowRegisterForm(true)} close={() => setShowRegisterForm(false)} loanLimit={loanLimit} initTenant={tenant} amount={loanLimit}
          term={term} />
        {/* <NotificationModal show={showNotiForm} onHide={(e) => setShowNotiForm(false)} /> */}
        <br></br>
      </Collapse>
    </div>
  )
}
