import React from 'react'

export default function Loading(props) {
  const { open } = props
  return (
    <div hidden={!open}>
      <div className="fixed-loading"></div>
      <div className="loader"></div>
    </div>
  )
}
