import apiClient from '../ApiClient'
const tenantService = {
  async getLoanLimit() {
    try {
      const result = await apiClient.get('api/loan-limit.json')
      return result
    } catch (error) {
      // console.log(error);
      return null
    }
  },
  async getLoanLimitV1(params) {
    try {
      const result = await apiClient.get('api/v1/loan-limit.json', params)
      return result
    } catch (error) {
      // console.log(error);
      return null
    }
  },
  async getTenantInfo() {
    try {
      const result = await apiClient.get('api/tenants.json')
      return result
    } catch (error) {
      // console.log(error);
      return null
    }
  },
  async createTenant() {
    try {
      const result = await apiClient.post('api/tenants.json', {})
      return result
    } catch (error) {
      // console.log(error);
      return null
    }
  },
  async updateRevenues() {
    try {
      const result = await apiClient.put('api/revenues.json', {})
      return result
    } catch (error) {
      // console.log(error);
      // return null;
    }
  },
}
export default tenantService
