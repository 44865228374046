/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { faQuestionCircle as regularQuestionCircle } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import {
    Button,
    Col,
    Container, OverlayTrigger,
    Popover,
    Row
} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import mess from '../../components/home/mess.svg'
import phone from '../../components/home/phone.svg'
import HomeImage from '../../components/buy/buy-image.svg'
import fundin from '../../components/buy/fundin.svg'
import { PAYMENTS as payments } from './Payments'
import Header from '../header/Header'
export default function BuyFirst(props) {
    const history = useHistory()
    return (
        <>
            <Container fluid className="SapoFin">
               <Header title="Mua trước trả sau" />
                <div className='SapoMoneyContainer' style={{ background: "none", marginTop: 25 }}>
                    <div className='SapoMoneyHeaderContainer'>
                        <div className='row'>
                            <div className='col-9 text-center' style={{marginTop:50}}>
                                <div className='title'>
                                    <h5>Phương thức trả góp 0% ngay lập tức và không cần thẻ tín dụng</h5>
                                </div>
                                <div className='subDescription' style={{ marginTop: 30 }}>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Áp dụng cho đơn hàng từ 0đ</span>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Xử lý nhanh chóng trong 3 phút</span>
                                    <span><i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Phí giao dịch chỉ từ 2.9%</span>
                                </div>
                            </div>
                            <div className='col-3'>
                                <img src={HomeImage} alt="Vay vốn kinh doanh" className='image-los' />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="SapoMoneyContainer">
                    <div className="feature-container">
                        <Row className="feature">
                            {payments.map((item, index) => {
                                return (
                                    <Col className="paper  feature-item " style={{ width: 240 }} key={index} >
                                        <div className="icon">
                                            <img src={item.icon} alt={item.name} />
                                        </div>
                                        <div className="content">
                                            <div >
                                                <div className="title">{item.name}</div>
                                                <div className="detail">
                                                    {item.description}
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                );
                            })}
                        </Row>
                    </div>
                </div>
                <div className="paper Shadow8 limit-content-container">
                    <div className="limit-content">
                        <div className="title title-blod ">Đối tác cung cấp dịch vụ của Sapo Money</div>
                        <div className="limit-amount"></div>
                    </div>
                    <div className="main-control">
                            <img src={fundin} style={{cursor:'pointer'}} alt="fundiin" onClick={()=>window.open("https://www.sapo.vn/blog/fundiin-hop-tac-voi-sapo-cung-cap-giai-phap-mua-tra-sau-mien-phi")}/>
                    </div>
                </div>
                <div>
                    <div className="hotline-phone-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={phone} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div>
                    {/* <div className="hotline-message-ring-wrap">
                        <a href="tel:02473086880" >
                            <img src={mess} alt="Gọi điện thoại" width={50} />
                        </a>
                    </div> */}
                </div>
            </Container>
        </>
    )
}
