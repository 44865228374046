import apiClient from '../../ApiClient'
const AdminLeadService = {
  getLeadById: async (id) => {
    return apiClient.get(`api/admin/money/leads/${id}.json`)
  },

  create: async (lead) => {
    return apiClient.post('api/admin/money/leads.json', { lead })
  },

  getLeads: async (page, size) => {
    return apiClient.get(`api/admin/money/leads.json?page=${page}&size=${size}`)
  },
  countLeads: async () => {
    return apiClient.get('api/admin/money/leads/count.json')
  },
  createLeadFromBanner: async (lead) => {
    return apiClient.post('api/admin/money/leads/banner.json', { lead })
  }
}

export default AdminLeadService
