import * as Constant from './Constant.js'

export default function validateUtils(var1 = {}, err = {}) {
  const {
    key,
    value,
    isRequire = false,
    max = 255, // default for text, in case number => setValue for min max !!
    min = 0,
    isNumber = false,
    regex,
    labelErr,
    type = true,
  } = var1

  // if (isRequire) {
  // if (!value && isRequire) {
  //   err[key] = `${type ? Constant.PLEASE_TYPE : Constant.PLEASE_SELECT}${labelErr}`
  //   return err
  // }
  if (isRequire) {
    if (!value || !value.trim()) {
      err[key] = `${type ? Constant.PLEASE_TYPE : Constant.PLEASE_SELECT}${labelErr}`
      return err
    }
  }
  // if (regex && !regex.test(value)) {
  //   err[key] = `${Constant.PLEASE_TYPE}${labelErr}${Constant.VALID}`
  //   return err
  // }
  if (regex) {
    if (regex instanceof Array) {
      if (value && value.trim() && regex.length !== 0) {
        let testRegex = 0
        regex.forEach((item) => {
          if (!item.test(value.trim())) {
            testRegex++
          }
        })
        if (testRegex === regex.length) {
          err[key] = `${Constant.PLEASE_TYPE}${labelErr}${Constant.VALID}`
          return err
        }
      }
    } else {
      if (value && value.trim() && regex && !regex.test(value.trim())) {
        err[key] = `${Constant.PLEASE_TYPE}${labelErr}${Constant.VALID}`
        return err
      }
    }
  }

  if (isNumber) {
    if (parseFloat(value) > parseFloat(max)) {
      err[key] = `${labelErr}${Constant.VALID_VALUE_MAX}${max}`
      return err
    }
    if (parseFloat(value) <= parseFloat(min)) {
      err[key] = `${labelErr}${Constant.VALID_VALUE_MIN}${min}`
      return err
    }
  } else {
    if (value && value.length > max) {
      err[key] = `${Constant.VALID_LENGTH_MAX}`
      return err
    }
    // if (value.length < min) {
    //   err[key] = `${Constant.VALID_LENGTH_MIN}`
    //   return err
    // }
  }
  // }
  return err
}
