import { Col, Pagination, Row } from 'antd'
import React from 'react'

export function PaginationCustomComponent(props) {
  const { pageable = {}, setPageable = () => {} } = props
  const { page = 1, size = 20, total = 0 } = pageable

  return (
    <div className="PaginationContainer">
      <Row justify="end">
        <Col span={5} style={{ textAlign: 'end' }}>
          <Pagination
            size="small"
            pageSize={size}
            total={total === 0 ? 1 : total}
            current={page}
            onChange={(index) => setPageable({ ...pageable, page: index })}
          />
        </Col>
      </Row>
    </div>
  )
}
export const PaginationComponent = React.memo(PaginationCustomComponent)
