import axios from 'axios'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'
import queryString from 'query-string'
var base64 = require('base-64');
var cancel
class ApiAs3 {
    baseUrl
    accAs3
    passAs3
    constructor() {
        this.baseUrl = process.env.REACT_APP_AS3_URL || 'https://dev.finance.sapocorp.vn'
        this.accAs3 = process.env.REACT_APP_AS3_USER || 'finance'
        this.passAs3 = process.env.REACT_APP_AS3_PASSWORD || '123456a@'
    }
    initRequestAs3(method, path, params) {
        var RequestAs3 = {
            baseUrl: this.baseUrl,
            url: `${this.baseUrl}/${path}`,
            method: method,
            headers: {
                'Authorization': "Basic " + base64.encode(`${this.accAs3}:${this.passAs3}`),
                "Content-Type": "multipart/form-data; charset=utf-8",
                "X-Sapo-ServiceId": "appfinance",
                "X-Sapo-Client": "appfinance"
            },
            params: params,
            cancelToken: new axios.CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c
            }),
        }
        return RequestAs3
    }

    convertResponseData(data) {
        var camelData = camelcaseKeys(data)
        return camelData
    }
    convertRequestAs3Body(data) {
        var snakeData = data instanceof FormData ? convertObjectToSnakeKeys(data) : snakecaseKeys(data)
        return snakeData
    }
    processError(error) {
        let errorModel = {}
        if (error.response) {
            /*
             * The RequestAs3 was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            errorModel = camelcaseKeys(error.response.data)
            if (error.response.status === 401) {
                // browserHistory.push("/unauthorize");
                let callback = queryString.parse(window.location.href)
                let alias= callback?.tenant?.replace(".mysapo.vn", ".mysapogo.com").replace(".mysapogo.com", "")
                localStorage.removeItem(`${alias}_token`)
            }
        } else if (error.RequestAs3) {
            /*
             * The RequestAs3 was made but no response was received, `error.RequestAs3`
             * is an instance of XMLHttpRequestAs3 in the browser and an instance
             * of http.ClientRequestAs3 in Node.js
             */
            // console.log(error.RequestAs3);
            errorModel = {
                statusCode: null,
                errorCode: null,
                messages: ['No response was received'],
            }
        } else {
            // Something happened in setting up the RequestAs3 and triggered an Error
            // console.log("Error", error.message);
            errorModel = {
                statusCode: null,
                errorCode: null,
                messages: [error.message],
            }
        }
        return errorModel
    }


    async post(path, body, params = null) {

        try {
            var RequestAs3 = this.initRequestAs3('post', path, params)
            RequestAs3.data = this.convertRequestAs3Body(body)
            var response = await axios(RequestAs3)
            return this.convertResponseData(response.data)
        } catch (error) {
            throw this.processError(error)
        }
    }
    syncPost(path, body, params = null) {

        try {
            var RequestAs3 = this.initRequestAs3('post', path, params)
            RequestAs3.data = this.convertRequestAs3Body(body)
            var response = axios(RequestAs3)
            return this.convertResponseData(response.data)
        } catch (error) {
            throw this.processError(error)
        }
    }

}

function camelToUnderscore(key) {
    return key.replace(/([A-Z])/g, '_$1').toLowerCase()
}

function convertObjectToSnakeKeys(original) {
    const newForm = new FormData()
    for (var pair of original.entries()) {
        newForm.append(camelToUnderscore(pair[0]), pair[1])
    }
    return newForm
}

const apiAs3 = new ApiAs3()
export default apiAs3
