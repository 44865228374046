import { Box } from '@material-ui/core'
import React, { Fragment, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import historyUtil from '../../common/historyUtil'
import RegisterModal from '../register/RegisterModal'
import emptyLogo from '../../img/svg/emptyLogo.svg'
import loadingLogo from '../../img/svg/loadingLogo.svg'
export default function LoanHistoryEmpty(props) {
  const history = useHistory()
  const [showRegisterModal, setShowRegisterModal] = useState(false)

  return (
    <Fragment>
      <Container fluid className="wrapper text-center" style={{ paddingTop: '70px' }}>
        <Box>
          <img
            src={props.isLoading ? loadingLogo : emptyLogo}
            alt="Quý khách chưa có hồ sơ vay vốn nào"
          />
        </Box>
        {props.isLoading ? (
          <Fragment>
            <Box>
              <p
                style={{
                  color: '#747C87',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 'normal',
                  fontSize: 14,
                  marginTop: 10,
                }}
              >
                Bạn vui lòng chờ trong giây lát, các hồ sơ đã gửi của bạn sẽ được hiển thị tại đây!
              </p>
            </Box>
          </Fragment>
        ) : (
          <Fragment>
            <Box>
              <h5
                style={{
                  color: '#182537',
                  fontSize: 20,
                  marginTop: 16,
                  fontWeight: 500,
                }}
              >
                Quý khách chưa có hồ sơ vay vốn nào
              </h5>
              <p
                style={{
                  color: '#747C87',
                  fontSize: 14,
                }}
              >
                Xem gói vay phù hợp hoặc đăng ký để nhận tư vấn
              </p>
            </Box>
            <Box className="main-control">
              <Button
                variant="outline-primary home-button"
                onClick={() => historyUtil(history, `/los/products/${50000000}`)}
              >
                Xem gói vay phù hợp
              </Button>
              <Button variant="primary main-home-button" onClick={() => setShowRegisterModal(true)}>
                Đăng ký vay vốn kinh doanh
              </Button>
            </Box>
          </Fragment>
        )}
      </Container>
      <RegisterModal
        visible={showRegisterModal}
        open={() => setShowRegisterModal(true)}
        close={() => setShowRegisterModal(false)}
        loanLimit={50000000}
      />
    </Fragment>
  )
}
