import logo1 from '../../components/payment/logo1.svg'
import logo2 from '../../components/payment/logo2.svg'
import logo3 from '../../components/payment/logo3.svg'
export const PAYMENTS =[
    {
        name:"Thu hút thêm khách hàng",
        description:"Gia tăng tiện ích để phục vụ khách hàng hiện hữu tốt hơn và thu hút thêm nhiều khách hàng mới",
        icon:logo1
    },
    {
        name:"Tăng doanh thu",
        description:"Hưởng % hoa hồng trên từng hóa đơn/dịch vụ thanh toán thành công",
        icon:logo2
    },
    {
        name:"Đa dạng và Tiện lợi",
        description:"Một kết nối qua Sapo Money, có ngay tính năng thanh toán nhiều loại hóa đơn và dịch vụ",
        icon:logo3
    }
]