/* eslint-disable max-len */

import React from 'react'
import { Route, Switch } from 'react-router-dom'
import BhErr403Component from './bankHupComponent/errorPage/BhErr403Component'
import BhErr404Component from './bankHupComponent/errorPage/BhErr404Component'
import BhErr500Component from './bankHupComponent/errorPage/BhErr500Component'
import AccumulationProduct from './pages/accumulation/product/AccumulationProduct'
import { AuthenticationCallbackPage } from './pages/authenticationCallback/AuthenticationCallbackPage'
import BuyFirst from './pages/buyFirst/BuyFirst'
import Calculator from './pages/calculatorLoan/Index'
import Dashboard from './pages/dashboards/Dashboard'
import SapoFinLandingPage from './pages/landing/SapoFinLandingPage'
import NotFound from './pages/notFoundPage/NotFound'
import Unauthorize from './pages/notFoundPage/Unauthorize'
import OnlinePayment from './pages/onlinePayment/OnlinePayment'
import PaymentService from './pages/paymentService/PaymentService'
import Product from './pages/products/Product'
import PayLaterContainer from './pay-later-component/PayLaterContainer'
import SapoFinComponent from './sapoFinComponent/SapoFinComponent'
import LoanHistory from './pages/loanHistory/LoanHistory'
import LeadDetail from './pages/loanHistory/components/lead/LeadDetail'
import ApplicationDetail from './pages/loanHistory/components/application/ApplicationDetail'
import SapoFinLandingVPBankPage from './pages/landing/SapoFinLandingVPBankPage'

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/landing" component={SapoFinLandingPage} />
      <Route exact path="/landing/vpbank" component={SapoFinLandingVPBankPage} />
      <Route exact path="/api/install/callback-sapo-core">
        <AuthenticationCallbackPage />
      </Route>
      <Route exact path="/install/callback-sapo-core">
        <AuthenticationCallbackPage />
      </Route>
      <Route exact path="/unauthorize">
        <Unauthorize />
      </Route>
      <Route exact path="/bh403">
        <BhErr403Component />
      </Route>
      <Route exact path="/bh404">
        <BhErr404Component />
      </Route>
      <Route exact path="/bh500">
        <BhErr500Component />
      </Route>
      <Route exact path="/calculator" component={Calculator} />
      <Route exact path="/home" component={SapoFinComponent} />
      <Route exact path="/los" component={Dashboard} />
      <Route exact path="/online-payment" component={OnlinePayment} />
      <Route exact path="/buy-first-pay-later" component={BuyFirst} />
      <Route exact path="/payment-service" component={PaymentService} />
      <Route exact path="/los/products/:loanLimit" component={Product} />

      <Route exact path="/paylater" component={PayLaterContainer} />

      <Route exact path="/accumulation" component={AccumulationProduct} />
      <Route exact path="/los/loan-history" component={LoanHistory} />
      <Route exact path="/los/loan-history/lead/:id" component={LeadDetail} />
      <Route exact path="/los/loan-history/application/:id" component={ApplicationDetail} />
      <Route exact path="" component={SapoFinComponent} />

      <Route path="*" component={NotFound} />
    </Switch>
  )
}

export default Routes
