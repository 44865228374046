import { Collapse } from '@material-ui/core'
import { Col, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Loading from '../../components/Loading'
import exchangeRateService from '../../services/exchangeRate/ExchangeRateService'
import moment from 'moment';

export default function ExchangeRate(props) {
  const [data, setData] = useState({});
  useEffect(() => {
    const getData = async () => {
      try {
        const result = await exchangeRateService.getList();
        setData(result?.data);
        console.log(result?.data);
      } catch (error) {
        console.log(error)
      }
    }
    getData();
  }, [])
  console.log(data)
  return (

    <div className="">
      <div className="CalculatorContainer">
        <p style={{ fontWeight: 'bold', fontSize: '16px', marginTop: '20px' }}>Tỷ giá ngoại tệ ngân hàng Vietcombank</p>
        <p style={{ marginTop: '-5px', fontSize: '14px' }}>Tỷ giá được cập nhật lúc <b>{moment(data?.time).format("HH:mm DD-MM-YYYY")}</b>  và chỉ mang tính chất tham khảo</p>
        <p style={{ marginTop: '-10px', fontSize: '14px' }}>Nguồn: Ngân Hàng TMCP Ngoại Thương Việt Nam</p>
        <div style={{ background: '#FFF', borderRadius: '5px 5px', width: '100%', display: 'flex', fontSize:'14px'}}>
          <table className="tbl-01 rateTable" cellSpacing={1} id="ctl00_Content_ExrateView" width={'100%'} cellPadding={'8'} >
            <tbody>
            
              <tr className="classth fontS-11" style={{backgroundColor:'#F4F6F8'}}>
              <th style={{textAlign:'left',paddingLeft:'16px'}}>
                Mã ngoại tệ
                </th>
                <th style={{  textAlign:'left'}}>Tên ngoại tệ</th>
               
                <th style={{  textAlign:'right'}}>Giá mua tiền mặt</th>
                <th style={{  textAlign:'right'}}>Giá mua chuyển khoản</th>
                <th style={{  textAlign:'right', paddingRight:'16px'}}>Giá Bán</th>
              </tr>
              {data?.data?.map((item, index) => {
                return (
                  <tr className="odd" data-time="03/31/2022 17:45:06" key={index} style={{borderBottom:'1px solid #F3F4F5'}}>
                  <td style={{ textAlign: 'left',paddingLeft:'16px' }}>{item.currency_code}</td>
                    <td style={{ textAlign: 'left' }}> {item.currency_name}</td>
                    <td style={{ textAlign: 'right' }}>{item.buy} </td>
                    <td style={{ textAlign: 'right' }}>{item.transfer}</td>
                    <td style={{ textAlign: 'right',paddingRight:'16px' }}>{item.sell}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
        <Row gutter={24}>
          <Col span={24} style={{ paddingLeft: '0px' }}>
            <div className="font14">
             
            </div>

          </Col>
        </Row>
      </div>
    </div>
  )
}
