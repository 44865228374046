/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useEffect, useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AuthenticationContext } from '../../contexts/AuthenticationContext'
import authenticationService from '../../services/authentication/AuthenticationService'
import queryString from 'query-string'
import authenticationActionType from '../../contexts/AuthenticationActionType'
import historyUtil from '../../common/historyUtil'
import { encrypt } from '../../utils/encryptUtil'
import tokenStorage from '../../services/token/TokenStorage'

export const AuthenticationCallbackPage = () => {
  const history = useHistory()

  const { dispatch } = useContext(AuthenticationContext)
  const [authenticateResult, setAuthenticateResult] = useState({ token: null })

  useEffect(() => {
    // console.log('Create App')
    const callback = queryString.parse(history.location.search)
    console.log(callback);
    let domain = callback.tenant
    if (domain) {
      domain = domain.replace('.mysapo.vn', '.mysapogo.com')
      const alias = domain.replace('.mysapogo.com', '')
      localStorage.removeItem(`${alias}_token`)
    }
    localStorage.removeItem('token')
    const origin = `https://${callback.tenant}`
    localStorage.setItem('origin', origin)
    localStorage.setItem('state', callback.state)
    const apiKey = origin.includes('-sapostaging')
      ? process.env.REACT_APP_API_KEY_STAGING
      : process.env.REACT_APP_API_KEY
    localStorage.setItem('clientId', apiKey)
    //localStorage.setItem('clientId', '728226d5b6f0483e80ca3a855734d230')

    if (!callback.code) {
      let redirectUri = `${localStorage.getItem(
        'origin'
      )}/admin/oauth/authorize?client_id=${apiKey}&scope=${
        process.env.REACT_APP_SCOPES
      }&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`
      if (callback.source) {
        redirectUri = redirectUri + `&source=${callback.source}`
      }
      // const redirectUri = `${localStorage.getItem(
      //   'origin'
      // )}/admin/oauth/authorize?client_id=728226d5b6f0483e80ca3a855734d230&scope=read_accounts,read_locations,read_orders&redirect_uri=http://localhost:8086/install/callback-sapo-core`
      window.parent.window.location.href = redirectUri
    } else {
      const token = tokenStorage.getToken()
      if (!token) {
        authenticationService
          .authenticate({ callback })
          .then((result) => {
            setAuthenticateResult(result?.tokens)
          })
          .catch((error) => {
            console.error(error)
          })
      }
    }
  }, [])

  useEffect(() => {
    if (authenticateResult && authenticateResult.token === null) {
      return
    }
    dispatch({
      type: authenticationActionType.signIn,
      payload: authenticateResult,
    })
    var key = encrypt(authenticateResult)
    const state = localStorage.getItem('state')
    var url = '/home'
    if (state == 'landing-tpbank') {
      url = '/landing'
    } else if (state == 'landing-vpbank') {
      url = '/landing/vpbank'
    }
    historyUtil(history, url, `key=${key}`)
  }, [authenticateResult])

  return <div></div>
}
