import React from 'react'
import { STATUS_PAID, STATUS_UNPAID, STATUS_CANCEL } from '../../constants/constants'
import './styles/pay-later-status.scss'

export default function PayLaterStatus({ status }) {
  const getStatusComponent = () => {
    switch (status) {
      case STATUS_PAID:
        return <PayLaterStatus.Paid />
      case STATUS_UNPAID:
        return <PayLaterStatus.Unpaid />
      case STATUS_CANCEL:
        return <PayLaterStatus.Cancel />
      default:
        return <div />
    }
  }

  return getStatusComponent()
}

PayLaterStatus.Paid = function PayLaterStatusPaid() {
  return <span className="pay-later-status__paid"> {'Đã thanh toán'} </span>
}

PayLaterStatus.Unpaid = function PayLaterStatusUnpaid() {
  return <span className="pay-later-status__unpaid"> {'Chưa thanh toán'} </span>
}

PayLaterStatus.Cancel = function PayLaterStatusCancel() {
  return <span className="pay-later-status__cancel"> {'Đã hủy'} </span>
}
