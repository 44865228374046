import apiClient from '../../ApiClient'
const AdminProductService = {
  getProducts: async (total, time, limit, servicePackage) => {
    return apiClient.get(
      `api/admin/money/products.json?total=${total}&time=${time}&limit=${limit}&servicePackInSet=${servicePackage}`
    )
  },

  create: async (request) => {
    return apiClient.post('api/admin/money/leads.json', { request })
  },
}

export default AdminProductService
