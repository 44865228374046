import slide6 from './../components/home/slide6.svg'
import slide7 from './../components/home/slide7.svg'
import slide8 from './../components/home/slide8.svg'

export const LIST_IMAGE_SLIDE = [
  {
    img: slide6,
    url:" https://www.sapo.vn/blog/thanh-toan-khong-tien-mat-lam-dien-dao-thi-truong"
  },
  {
    img: slide7,
    url:"https://www.sapo.vn/blog/thieu-von-kinh-doanh-da-co-sapo-fin-lo"
  },
  {
    img: slide8,
    url:"https://www.sapo.vn/blog/sapo-ra-mat-tinh-nang-vay-von-so-hoa-100-danh-rieng-cho-nha-ban-hang"
  },
]
