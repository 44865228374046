import { ADD, SUB } from './../actionType'

const initValue = { count: 1 }

const countReducer = (state = initValue, action) => {
  switch (action.type) {
    case ADD: {
      const { count } = state
      return { ...state, count: count + 1 }
    }
    case SUB: {
      const { count } = state
      return { ...state, count: count - 1 }
    }

    default:
      return state
  }
}

export default countReducer
