import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useHistory, useParams } from 'react-router-dom'
import historyUtil from '../../../../common/historyUtil'
import Loading from '../../../../components/Loading'
import AdminLeadService from '../../../../services/admin/lead/AdminLeadService'
import { formatMoney } from '../../../../utils/FormatNumber'
import { convertValue, getBusinesTypeName } from '../../../../utils/Utils'
import RowContent from '../RowContent'

function LeadDetail() {
  const { id } = useParams()
  const history = useHistory()
  const [lead, setLead] = useState()
  const [tenant, setTenant] = useState()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    AdminLeadService.getLeadById(id)
      .then((res) => {
        if (res && res.lead) {
          setLead(res.lead)
          setTenant(res.lead?.tenant)
        }
      })
      .finally(() => setLoading(false))
  }, [id])

  return (
    <Container fluid className="wrapper">
      <Loading open={loading} />
      <Box className="header">
        <Box style={{ marginLeft: 24 }}>
          <span style={{ fontSize: '15px' }}>
            <span className="btn-back" onClick={() => historyUtil(history, '/los/loan-history')}>
              <FontAwesomeIcon icon={faAngleLeft} className="product-back-icon-calculator" />
              Lịch sử khoản vay
            </span>
          </span>
        </Box>
      </Box>
      <Container fluid style={{ marginTop: 90, padding: '0 30px' }}>
        <h5 style={{ fontWeight: 'bold' }}>{lead?.code}</h5>
        <Card className="mt-2" style={{ border: 'none' }}>
          <Card.Header
            style={{
              backgroundColor: '#ffff',
              color: '0F1824',
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            Thông tin người vay
          </Card.Header>
          <Card.Body>
            <RowContent
              leftTitle={'Số tiền muốn vay'}
              leftValue={lead?.amount ? convertValue(formatMoney(lead?.amount)) : '---'}
              rightTitle={'Mục đích vay'}
              rightValue={convertValue(lead?.reason)}
            />
            <RowContent
              leftTitle={'Thời gian vay (Tháng)'}
              leftValue={convertValue(lead?.time)}
              rightTitle={'Hình thức kinh doanh'}
              rightValue={lead?.business_type ? getBusinesTypeName(lead?.business_type) : '---'}
            />
            <RowContent
              leftTitle={'Lĩnh vực kinh doanh'}
              leftValue={convertValue(lead?.industry)}
              rightTitle={'Họ và tên'}
              rightValue={convertValue(lead?.name)}
            />
            <RowContent
              leftTitle={'CMND/Căn cước công dân'}
              leftValue={convertValue(lead?.identity_card)}
              rightTitle={'Số điện thoại'}
              rightValue={convertValue(lead?.phone)}
            />
            <RowContent leftTitle={'Địa bàn cư trú'} leftValue={convertValue(lead?.city)} />
          </Card.Body>
        </Card>
        <Card className="mt-4" style={{ border: 'none' }}>
          <Card.Header
            style={{
              backgroundColor: '#ffff',
              color: '0F1824',
              fontWeight: 'bold',
              fontSize: 16,
            }}
          >
            Thông tin cửa hàng {tenant?.name}
          </Card.Header>
          <Card.Body>
            <RowContent
              leftTitle={'Hình thức kinh doanh'}
              leftValue={tenant?.business_type ? getBusinesTypeName(tenant?.business_type) : '---'}
              rightTitle={'Lĩnh vực kinh doanh'}
              rightValue={convertValue(tenant?.industry)}
            />
            <RowContent
              leftTitle={'Số ĐKKD'}
              leftValue={convertValue(tenant?.business_number)}
              rightTitle={'Địa chỉ kinh doanh'}
              rightValue={convertValue(tenant?.business_address)}
            />
            <RowContent
              leftTitle={'Khu vực'}
              leftValue={
                tenant?.city
                  ? `${tenant?.city} ${tenant?.district ? `- ${tenant?.district}` : ''}`
                  : '---'
              }
              rightTitle={'Phường xã'}
              rightValue={convertValue(tenant?.ward)}
            />
          </Card.Body>
        </Card>
      </Container>
    </Container>
  )
}

export default LeadDetail
