import React from 'react'
import { Col } from 'antd'

const FormContainer = (props) => {
  const {
    style,
    classNameCustome,
    // justify,
    // span = 0,
    xs = 24,
    sm = 12,
    md = 12,
    lg = 8,
    xl = 6,
  } = props
  return (
    <Col
      className={`FormContainer DataFormContainer ${classNameCustome ? classNameCustome : ''}`}
      style={style}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      span={24}
      // gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
    >
      {props.children}
    </Col>
  )
}

export default FormContainer
