import { Modal } from 'antd'
import React from 'react'
function ModalPreview(props) {
  const { url, content, visible, setVisible } = props
  return (
    <Modal
      title={<b>{content}</b>}
      visible={visible}
      onOk={() =>
        setVisible({
          content: '',
          url: '',
          visible: false,
        })
      }
      onCancel={() =>
        setVisible({
          content: '',
          url: '',
          visible: false,
        })
      }
      width={580}
      footer={null}
      keyboard={false}
      maskClosable={true}
      closable={true}
      destroyOnClose={true}
      className={'ModalPreview'}
    >
      <div className="text-center" style={{ padding: 20 }}>
        <img src={url} alt={content} style={{ objectFit: 'cover', width: 400, height: 'auto' }} />
      </div>
    </Modal>
  )
}

export default ModalPreview
