import { Button, Result } from 'antd'
import React from 'react'
import { BANKHUB } from '../../common/Constant'

export default function BhErr403Component() {
  // const history = useHistory()
  // const onRedirect = () => window.location.href = ""
  return (
    <Result
      status="403"
      title="403"
      subTitle="Sorry, you are not authorized to access this page."
      extra={
        <Button type="primary" onClick={() => (window.location.href = BANKHUB)}>
          Trang chủ
        </Button>
      }
    />
  )
}
