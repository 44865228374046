import React from 'react'
import SuccessIcon from '../img/svg/circle_success.svg'
import InfoIcon from '../img/svg/circle_info.svg'
import WarningIcon from '../img/svg/outline_warning.png'

export default function Banner(props) {
  const { content, status } = props
  const getIcon = (status) => {
    switch (status) {
      case 'success':
        return SuccessIcon
      case 'info':
        return InfoIcon
      case 'warning':
        return WarningIcon
      default:
        return SuccessIcon
    }
  }
  const getClassName = (status) => {
    switch (status) {
      case 'success':
        return 'banner-container banner-success'
      case 'info':
        return 'banner-container banner-info'
      case 'warning':
        return 'banner-container banner-warning'
      default:
        return SuccessIcon
    }
  }
  return (
    <div className={getClassName(status)}>
      <img src={getIcon(status)} />
      <span>{content}</span>
    </div>
  )
}
