import { LoadingOutlined, RightOutlined } from '@ant-design/icons'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  notification,
} from 'antd'
import viLocale from 'antd/es/date-picker/locale/vi_VN'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import {
  leadTypes,
  REGEX_EMAIL,
  REGEX_IDENTIFY_CARD,
  REGEX_NAME_VN,
  REGEX_ONLY_TEXT_NUMBER,
  REGEX_PHONE_NUMBER_DETAIL,
} from '../../../common/Constant'
import addressService from '../../../services/address/AddressService'
import apiClient from '../../../services/ApiClient'
import accumulationActionType from '../context/AccumulationActionType'
import { AccumulationContext } from '../context/AccumulationContext'
import Loading from '../../../components/Loading'
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />
notification.config({ placement: 'topRight', duration: 3 })
const { Option } = Select
const { Text } = Typography
function AccumulationForm(props) {
  const { productCode = '', tenant = {}, setStep } = props

  const [isAccept, setIsAccept] = useState(false)
  const [region, setRegion] = useState([])
  const [spinning, setSpinning] = useState(false)
  const { registedLead, dispatch } = useContext(AccumulationContext)
  const [lead, setLead] = useState(registedLead)
  const [wards, setWards] = useState([])
  const [contactWards, setContactWards] = useState([])
  const [locations, setLocations] = useState([])
  const [businessLocation, setBusinessLocation] = useState({ value: '', label: 'Chọn khu vực' })
  const [contactLocation, setContactLocation] = useState({ value: '', label: 'Chọn khu vực' })
  const [icIssuePlaces, setIcIssuePlaces] = useState([])
  const [form] = Form.useForm()
  const formRule = {
    storeName: [
      {
        required: true,
        whitespace: true,
        message: 'Vui lòng nhập tên cửa hàng/ doanh nghiệp',
      },
      { max: 255, message: 'Vượt quá ký tự cho phép' },
      {
        pattern: new RegExp(REGEX_NAME_VN, 'iu'),
        message: 'Vui lòng nhập tên cửa hàng/ doanh nghiệp hợp lệ',
      },
    ],
    businessTime: [
      // {
      //   required: true,
      //   message: 'Vui lòng nhập thời gian kinh doanh',
      // },
      {
        type: 'number',
        min: 1,
        message: 'Vui lòng nhập thời gian kinh doanh hợp lệ',
      },
      {
        type: 'number',
        max: 2000,
        message: 'Vui lòng nhập thời gian kinh doanh hợp lệ',
      },
    ],
    businessRegistration: [
      {
        pattern: new RegExp(REGEX_ONLY_TEXT_NUMBER),
        message: 'Vui lòng nhập đăng ký kinh doanh hợp lệ',
      },
      // {
      //   required: true,
      //   message: 'Vui lòng nhập đăng ký kinh doanh',
      // },
      { max: 50, message: 'Vượt quá ký tự cho phép' },
    ],
    businessLocation: [{ required: true, message: 'Vui lòng chọn khu vực' }],
    ward: [{ required: true, message: 'Vui lòng chọn phường xã' }],
    businessAddress: [
      {
        required: true,
        whitespace: true,
        message: 'Vui lòng nhập địa chỉ kinh doanh',
      },
      { max: 255, message: 'Vượt quá ký tự cho phép' },
    ],
    ownerName: [
      {
        required: true,
        whitespace: true,
        message: 'Vui lòng nhập tên chủ cửa hàng/ doanh nghiệp',
      },
      {
        pattern: new RegExp(REGEX_NAME_VN, 'iu'),
        message: 'Vui lòng nhập tên chủ cửa hàng/ doanh nghiệp hợp lệ',
      },
      { max: 255, message: 'Vượt quá ký tự cho phép' },
    ],
    phone: [
      {
        required: true,
        message: 'Vui lòng nhập số điện thoại',
      },
      {
        pattern: new RegExp(REGEX_PHONE_NUMBER_DETAIL),
        message: 'Vui lòng nhập số điện thoại hợp lệ',
      },
    ],
    email: [
      {
        required: true,
        message: 'Vui lòng nhập email',
      },
      { pattern: new RegExp(REGEX_EMAIL), message: 'Vui lòng nhập email hợp lệ' },
      { max: 100, message: 'Vượt quá ký tự cho phép' },
    ],
    dob: [
      {
        required: true,
        message: 'Vui lòng nhập ngày sinh',
      },
      ({ getFieldValue, setFields }) => ({
        validator(_, value) {
          if (moment.duration(moment().diff(moment(value))).years() < 15) {
            return Promise.reject(new Error('Tuổi đăng ký tối thiểu là 15'))
          }
          if (getFieldValue('icIssuedOn')) {
            if (value) {
              if (getFieldValue('icIssuedOn').isBefore(value)) {
                setFields([
                  {
                    name: 'icIssuedOn',
                    value: getFieldValue('icIssuedOn'),
                    errors: ['Vui lòng nhập ngày cấp hợp lệ'],
                  },
                ])
              } else {
                setFields([
                  {
                    name: 'icIssuedOn',
                    value: getFieldValue('icIssuedOn'),
                    errors: [],
                  },
                ])
              }
            }
          }
          return Promise.resolve()
        },
      }),
    ],
    contactLocation: [{ required: true, message: 'Vui lòng chọn khu vực' }],
    contactWard: [{ required: true, message: 'Vui lòng chọn phường xã' }],
    address: [
      {
        required: true,
        whitespace: true,
        message: 'Vui lòng nhập địa chỉ',
      },
      { max: 255, message: 'Vượt quá ký tự cho phép' },
    ],
    identityCard: [
      {
        required: true,
        message: 'Vui lòng nhập số CMND/CCCD',
      },
      {
        pattern: new RegExp(REGEX_IDENTIFY_CARD),
        message: 'Vui lòng nhập số CMND/CCCD hợp lệ',
      },
    ],
    icIssuedOn: [
      {
        required: true,
        message: 'Vui lòng nhập ngày cấp',
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (getFieldValue('dob')) {
            if (!value || (getFieldValue('dob').isBefore(value) && moment().isAfter(value))) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Vui lòng nhập ngày cấp hợp lệ'))
          } else {
            if (!value || moment().isAfter(value)) {
              return Promise.resolve()
            }
            return Promise.reject(new Error('Vui lòng nhập ngày cấp hợp lệ'))
          }
        },
      }),
    ],
    icIssuedAt: [
      {
        required: true,
        message: 'Vui lòng chọn nơi cấp',
      },
    ],
  }
  useEffect(() => {
    getIcIssuedPlace()
    getLocations()
    getTenantInfo()
  }, [])
  async function getTenantInfo() {
    setSpinning(true)
    const res = await apiClient.get('api/tenants.json')
    const { tenant = {} } = res
    const initLead = {
      ...tenant,
      contactWardCode: tenant?.contact_ward?.code,
      contactCityCode: tenant?.contact_city?.code,
      contactDistrictCode: tenant?.contact_district?.code,
      wardCode: tenant?.ward?.code,
      cityCode: tenant?.city?.code,
      districtCode: tenant?.district?.code,
    }
    setLead(initLead)
    setBusinessLocation(tenant.business_location)
    setContactLocation(tenant.contact_location)
    setSpinning(false)
  }
  async function getLocations() {
    const res = await apiClient.get('api/city-districts.json')
    const { data } = res || {}
    const { city_districts: citiesRes = [] } = data || {}
    setLocations(citiesRes)
  }
  useEffect(() => {
    contactLocation &&
      contactLocation?.value &&
      getWards(contactLocation?.value, setContactWards).then((res) => {
        const { code, city_code, district_code } = res
        setLead({
          ...lead,
          contactWardCode: code,
          contactCityCode: city_code,
          contactDistrictCode: district_code,
        })
        form.setFields([{ name: 'contactLocation', value: district_code }])
        form.setFields([{ name: 'contactWardCode', value: code }])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactLocation])

  useEffect(() => {
    businessLocation &&
      businessLocation.value &&
      getWards(businessLocation?.value, setWards).then((res) => {
        const { code, city_code, district_code } = res
        setLead({
          ...lead,
          wardCode: code,
          cityCode: city_code,
          districtCode: district_code,
        })
        form.setFields([{ name: 'businessLocation', value: district_code }])
        form.setFields([{ name: 'wardCode', value: code }])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessLocation])
  async function getIcIssuedPlace() {
    const res = await apiClient.get('api/ic_issued_places.json')
    const { icIssuedPlaces = [] } = res
    setIcIssuePlaces(icIssuedPlaces.map((iip) => ({ value: iip.code, label: iip.name })))
  }

  async function getWards(districtCode = '', callback = () => {}) {
    try {
      const res = await addressService.getWardByDistrictCode(districtCode)
      if (res) {
        const { data } = res || {}
        const { wards: wardsRes = [] } = data || {}
        if (callback instanceof Function) {
          callback(wardsRes.map((w) => ({ value: w.code, label: w.name })))
          return wardsRes[0]
        }
      }
    } catch (err) {
      console.log('=======err===', err)
    }
  }
  const onFinish = (values) => {
    console.log(values)
    const leadRequest = {
      ownerName: values['ownerName'],
      email: values['email'],
      dob: values['dob'].format('YYYY-MM-DD'),
      identityCard: values['identityCard'],
      icIssuedAt: values['icIssuedAt'],
      icIssuedOn: values['icIssuedOn'].format('YYYY-MM-DD'),
      phone: values['phone'],
      status: 'NEW',
      source: 'CORE',
      address: values['address'],
      cityCode: lead.contactCityCode,
      districtCode: lead.contactDistrictCode,
      wardCode: values['contactWardCode'],
      productCode: productCode,
      tenant: {
        name: values['storeName'],
        domain: values['domain'],
        tenantId: lead?.tenant_id,
        businessRegistration: values['businessRegistration'],
        cityCode: lead.cityCode,
        districtCode: lead.districtCode,
        wardCode: values['wardCode'],
        businessAddress: values['businessAddress'],
        type: values['businessType'],
        businessTime: values['businessTime'],
      },
    }
    console.log(leadRequest)
    setSpinning(true)
    apiClient
      .post('api/accumulations/lead.json', { accumulation_lead_request: leadRequest })
      .then((res) => {
        const { accumulationLead = {} } = res
        if (accumulationLead && accumulationLead.phone && accumulationLead.identity_card) {
          dispatch({ state: leadRequest, action: accumulationActionType.updateLead })
          setStep(1)
        }
      })
      .catch((e) => {
        console.log(e)
        notification.error({
          message: 'Thất bại',
          description: 'Có lỗi xảy ra vui lòng thử lại sau',
        })
      })
      .finally(() => setSpinning(false))
  }
  const FormLabel = (label = '', required = false) => {
    return (
      <span style={{ fontSize: 12 }}>
        {label}
        {required ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : <></>}
      </span>
    )
  }
  return (
    <div className="AccumulationFormContainer">
      <Loading open={spinning} />
      {/* <Spin indicator={antIcon} spinning={spinning} className="spinning-container"/> */}
      {!spinning && (
        <>
          <Form
            form={form}
            name="accumulation-form"
            onFinish={onFinish}
            scrollToFirstError
            labelCol={{ span: 24, offset: 0 }}
            labelAlign={'left'}
            size="large"
          >
            <Row className="AccumulationFormTitle">Thông tin cửa hàng/ doanh nghiệp</Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="storeName"
                  label={FormLabel('Tên cửa hàng/ doanh nghiệp', true)}
                  rules={formRule.storeName}
                  className="accumulation-form-item"
                  initialValue={lead?.shop_name}
                >
                  <Input
                    className="accumulation-form-input"
                    placeholder="Nhập tên cửa hàng/ doanh nghiệp"
                    value={lead?.shop_name}
                  />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="businessType"
                  label={FormLabel('Loại hình doanh nghiệp', true)}
                  className="accumulation-form-item"
                  initialValue={leadTypes[0].value}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder={'Chọn loại hình doanh nghiệp'}
                  >
                    {leadTypes.map((type, index) => (
                      <Option key={index} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="businessTime"
                  label={FormLabel('Thời gian kinh doanh (tháng)')}
                  rules={formRule.businessTime}
                  className="accumulation-form-item"
                >
                  <InputNumber
                    className="accumulation-form-input"
                    min={0}
                    precision={0}
                    decimalSeparator={','}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/(,*)/g, '')}
                    placeholder="Nhập thời gian kinh doanh"
                  />
                </Form.Item>
              </Col>{' '}
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="businessRegistration"
                  label={FormLabel('Đăng ký kinh doanh')}
                  rules={formRule.businessRegistration}
                  className="accumulation-form-item"
                  initialValue={lead?.business_registration}
                >
                  <Input
                    className="accumulation-form-input"
                    placeholder="Nhập đăng ký kinh doanh"
                  />
                </Form.Item>
              </Col>{' '}
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="businessLocation"
                  label={FormLabel('Khu vực', true)}
                  className="accumulation-form-item"
                  rules={formRule.businessLocation}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder="Chọn khu vực"
                    onChange={(value, label) => setBusinessLocation({ value, label })}
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {locations.map((l, index) => (
                      <Option key={index} value={l.value}>
                        {l.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="wardCode"
                  label={FormLabel('Phường xã', true)}
                  className="accumulation-form-item"
                  rules={formRule.ward}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder="Chọn phường xã"
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {wards.map((w, index) => (
                      <Option key={index} value={w.value}>
                        {w.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="businessAddress"
                  label={FormLabel('Địa chỉ kinh doanh', true)}
                  rules={formRule.businessAddress}
                  className="accumulation-form-item"
                  initialValue={lead?.business_address}
                >
                  <Input
                    className="accumulation-form-input"
                    placeholder="Nhập địa chỉ kinh doanh"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row className="AccumulationFormTitle">Thông tin chủ cửa hàng/ doanh nghiệp</Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="ownerName"
                  label={FormLabel('Tên chủ cửa hàng/ doanh nghiệp', true)}
                  rules={formRule.ownerName}
                  className="accumulation-form-item"
                  initialValue={lead?.owner_name}
                >
                  <Input
                    className="accumulation-form-input"
                    placeholder="Nhập tên chủ cửa hàng/ doanh nghiệp"
                  />
                </Form.Item>
              </Col>{' '}
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="dob"
                  label={FormLabel('Ngày sinh', true)}
                  rules={formRule.dob}
                  className="accumulation-form-item"
                >
                  <DatePicker
                    locale={viLocale}
                    placeholder="Ngày - tháng - năm"
                    format={'DD-MM-YYYY'}
                    className="accumulation-form-input"
                    disabledDate={(date) => date.isAfter(moment())}
                  />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="phone"
                  label={FormLabel('Số ĐT', true)}
                  rules={[
                    {
                      required: true,
                      message: 'Vui lòng nhập số điện thoại',
                    },
                    {
                      pattern: new RegExp(REGEX_PHONE_NUMBER_DETAIL),
                      message: 'Vui lòng nhập số điện thoại hợp lệ',
                    },
                  ]}
                  className="accumulation-form-item"
                  initialValue={lead?.phone}
                >
                  <Input className="accumulation-form-input" placeholder="Nhập số đt" />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="email"
                  label={FormLabel('Email', true)}
                  rules={formRule.email}
                  className="accumulation-form-item"
                  initialValue={lead?.email}
                >
                  <Input className="accumulation-form-input" placeholder="Nhập địa chỉ email" />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="contactLocation"
                  label={FormLabel('Khu vực', true)}
                  className="accumulation-form-item"
                  rules={formRule.contactLocation}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder="Chọn khu vực"
                    onChange={(value, label) => setContactLocation({ value, label })}
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {locations.map((l, index) => (
                      <Option key={index} value={l.value}>
                        {l.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="contactWardCode"
                  label={FormLabel('Phường xã', true)}
                  className="accumulation-form-item"
                  rules={formRule.contactWard}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder="Chọn phường xã"
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {contactWards.map((w, index) => (
                      <Option key={index} value={w.value}>
                        {w.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="address"
                  label={FormLabel('Địa chỉ', true)}
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: 'Vui lòng nhập địa chỉ',
                    },
                    { max: 255, message: 'Vượt quá ký tự cho phép' },
                  ]}
                  className="accumulation-form-item"
                  initialValue={lead?.address}
                >
                  <Input className="accumulation-form-input" placeholder="Số nhà, tên đường ..." />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="identityCard"
                  label={FormLabel('CMND/CCCD', true)}
                  rules={formRule.identityCard}
                  className="accumulation-form-item"
                  initialValue={lead?.identity_card}
                >
                  <Input className="accumulation-form-input" placeholder="Nhập số CMND/CCCD" />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="icIssuedOn"
                  label={FormLabel('Ngày cấp', true)}
                  rules={formRule.icIssuedOn}
                  className="accumulation-form-item"
                >
                  <DatePicker
                    locale={viLocale}
                    placeholder="Ngày - tháng - năm"
                    format={'DD-MM-YYYY'}
                    className="accumulation-form-input"
                    disabledDate={(date) => date.isAfter(moment())}
                  />
                </Form.Item>
              </Col>
              <Col xl={6} lg={6} md={8} sm={24} xs={24} className="accumulation-form-col">
                <Form.Item
                  name="icIssuedAt"
                  label={FormLabel('Nơi cấp', true)}
                  className="accumulation-form-item"
                  rules={formRule.icIssuedAt}
                >
                  <Select
                    className="accumulation-form-input"
                    placeholder="Chọn nơi cấp"
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {icIssuePlaces.map((iip, index) => (
                      <Option key={index} value={iip.value}>
                        {iip.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }} style={{ marginTop: 24 }}>
              <Col span={1}>
                <Checkbox checked={isAccept} onChange={(e) => setIsAccept(e.target.checked)} />
              </Col>
              <Col span={23} className="AccumulationTermConditionContainer">
                Tôi đồng ý cung cấp thông tin cá nhân của tôi qua nền tảng quản lý bán hàng Sapo,
                với mục đích để được hỗ trợ giải pháp tài chính dựa trên{' '}
                <a href="https://support.sapo.vn/chinh-sach-bao-mat-sapo-fin" target="_blank">
                  chính sách bảo mật
                </a>{' '}
                và{' '}
                <a href="https://support.sapo.vn/dieu-khoan-dieu-kien-sapo-fin" target="_blank">
                  điều kiện điều khoản
                </a>{' '}
                của Sapo.
              </Col>
            </Row>
            <Row justify="end" style={{ marginTop: '24px' }}>
              <Form.Item>
                <Button
                  className={`AccumulationSubmitButton `}
                  type="primary"
                  htmlType="submit"
                  disabled={!isAccept}
                >
                  Tiếp tục <RightOutlined style={{ fontSize: '14px' }} />
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </>
      )}
    </div>
  )
}

export default AccumulationForm
