import apiClient from '../../ApiClient'
const AdminApplicationService = {
  getApplicationById: async (id) => {
    return apiClient.get(`api/admin/money/applications/${id}.json`)
  },

  getCurrentApplication: async (partnerId) => {
    return apiClient.get(`api/admin/money/applications/current.json?partnerId=${partnerId}`)
  },

  getApplications: async (page, size) => {
    return apiClient.get(`api/admin/money/applications.json?page=${page}&size=${size}`)
  },

  countApplications: async () => {
    return apiClient.get('api/admin/money/applications/count.json')
  },

  getInfoFastMoney: async (loanCode) => {
    return apiClient.get(
      `api/admin/money/applications/amber-fintech/check-info.json?loanCode=${loanCode}`
    )
  },
}

export default AdminApplicationService
