import CryptoJS from 'crypto-js'

export function encrypt(input) {
  const key = 'process.env.REACT_APP_BH_SECRET_KEY'
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(input), key)
  return encodeURI(encrypted.toString())
}

export function decrypt(input) {
  const key = 'process.env.REACT_APP_BH_SECRET_KEY'
  const decrypted = CryptoJS.AES.decrypt(decodeURIComponent(decodeURI(input)), key)
  return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypted))
}

export function getKey() {
  const url = new URL(document.URL)
  const search = url.href.split('&')
  const paramKeys = search.filter((param) => param && param.startsWith('key='))
  if (paramKeys && paramKeys.length > 0) {
    const key = paramKeys[0].replaceAll('key=', '').replaceAll('"', '')
    return key
  }
  return null
}
