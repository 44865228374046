import { combineReducers } from 'redux'
import countReducer from './countReducer'
import registerBankHubReducer from './registerBankHubReducer'

const rootReducer = combineReducers({
  countReducer,
  registerBankHubReducer,
})

export default rootReducer
