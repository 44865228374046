export const payLaterStatus = [
  {
    value: 'PAID',
    label: 'Đã thanh toán',
  },
  {
    value: 'CANCELED',
    label: 'Đã hủy',
  },
]
