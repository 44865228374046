import queryString from 'query-string'
import { toString } from 'lodash'
export default (history, var1, var2) => {
  const callback = queryString.parse(window.location.href)
  const windowApp = window
  const sapoApp = windowApp.SapoApp
  if (history && typeof sapoApp !== 'undefined') {
    if (typeof var1 === 'object') {
      const { pathname = '', state = {} } = var1 || {}
      history.push({ pathname, state })
      sapoApp.pushState(pathname)
    }
    if (typeof var1 === 'string') {
      if (var2) {
        history.push(`${var1}?hmac=${callback.hmac}&tenant=${toString(callback.tenant)}&${var2}`)
      } else {
        history.push(`${var1}?hmac=${callback.hmac}&tenant=${toString(callback.tenant)}`)
      }
      sapoApp.pushState(var1)
    }
  }
}
