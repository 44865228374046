/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import React from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import phone from '../../components/home/phone.svg'
import HomeImage from '../../components/onlinePayment/payment-image.svg'
import Header from '../header/Header'
import { LIST_PARTNER_LOGO as logos } from './Partner.js'
import { PAYMENTS as payments } from './Payments'
import { Box } from '@material-ui/core'
export default function OnlinePayment(props) {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 8,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  }
  const origin = localStorage.getItem('origin')
  const redirectBlog = (url) => {
    window.open(url)
  }
  return (
    <>
      <Container fluid className="SapoFin">
        <Header title="Thanh toán điện tử" />
        <div className="SapoMoneyContainer" style={{ background: 'none', marginTop: 25 }}>
          <div className="SapoMoneyHeaderContainer">
            <div className="row">
              <div className="col-9 text-center">
                <div className="title">
                  <h5>Chuyển đổi số để bắt kịp xu hướng thanh toán không tiền mặt ngay hôm nay</h5>
                </div>
                <div className="subDescription" style={{ marginTop: 30 }}>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>An toàn
                    tuyệt đối
                  </span>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Tiết kiệm
                    chi phí
                  </span>
                  <span>
                    <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Ưu đãi hấp
                    dẫn
                  </span>
                </div>
                <div style={{ marginTop: 30 }}>
                  <a
                    href={`${origin}/admin/settings/payment_methods`}
                    target="_blank"
                    style={{ color: '#fff' }}
                  >
                    <Button variant="primary main-home-button">Đăng ký ngay</Button>
                  </a>
                </div>
              </div>
              <div className="col-3">
                <img src={HomeImage} alt="Vay vốn kinh doanh" className="image-los" />
              </div>
            </div>
          </div>
        </div>
        <div className="SapoOnlinePaymentContainer">
          <div className="feature-container">
            <Row className="feature">
              {payments.map((item, index) => {
                return (
                  <Col className="paper  feature-item " style={{ width: 240 }} key={index}>
                    <div className="icon">
                      <img src={item.icon} alt={item.name} />
                    </div>
                    <div className="content">
                      <div>
                        <div className="title">{item.name}</div>
                        <div className="detail">{item.detail}</div>
                        <div className="detail">{item.detail2}</div>
                        <hr style={{ width: 60 }} />
                        <div className="detail">{item.description}</div>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </div>
        </div>
        <div className="paper Shadow8 limit-content-container">
          <div className="limit-content">
            <div className="title title-blod ">Đối tác cung cấp dịch vụ của Sapo Money</div>
            <div className="limit-amount"></div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {logos.map((item, index) => {
              return (
                <Box
                  key={index}
                  style={{ flexBasis: '25%', marginTop: '20px', marginBottom: '30px' }}
                >
                  <img
                    src={item.logo}
                    alt={item.name}
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => redirectBlog(item.url)}
                  />
                </Box>
              )
            })}
          </div>
        </div>
        <div className="hotline-phone-ring-wrap">
          <a href="tel:02473086880">
            <img src={phone} alt="Gọi điện thoại" width={50} />
          </a>
        </div>
      </Container>
    </>
  )
}
