import apiClient from "../ApiClient";
const addressService = {
  async getCities() {
    try {
      let result = await apiClient.get("api/cities.json");
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
  async getDistricts() {
    try {
      let result = await apiClient.get("api/districts.json");
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
  async getWards() {
    try {
      let result = await apiClient.get("api/wards.json");
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
  async getWardByDistrictCode(districtCode) {
    try {
      let result = await apiClient.get(
        `api/wards/district/${districtCode}.json`
      );
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
  async getCityDistricts() {
    try {
      let result = await apiClient.get("api/city-districts.json");
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
  async te() {
    try {
      let result = await apiClient.post("api/t", {});
      return result;
    } catch (error) {
      // console.log(error);
      return null;
    }
  },
};
export default addressService;
