import { Button, Checkbox, Col, Form, Input, InputNumber, Row, Select, notification } from 'antd'
import React, { Fragment, useEffect, useState } from 'react'
import {
  IndustryOptions,
  LoanReasonTPD,
  Partner,
  leadTypes,
  loanReasons,
  months,
} from '../../common/Constant'
import Loading from '../../components/Loading'
import AdminLeadService from '../../services/admin/lead/AdminLeadService'
import AdminLocationService from '../../services/admin/location/AdminLocationService'
import styles from '../../styles/registerLead.scss'
import { ValidateForm } from './validate'
notification.config({ placement: 'topRight', duration: 3 })
const { Option } = Select

function RegisterDefault(props) {
  const { setLeadId, initialValue, tenant, isLanding, partnerId } = props
  const [isAccept, setIsAccept] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [cities, setCities] = useState([])
  const [city, setCity] = useState()

  const [form] = Form.useForm()

  useEffect(() => {
    getLocations()
  }, [])

  async function getLocations() {
    const res = await AdminLocationService.getCities()
    if (res && res.data && res.data.cities) {
      setCities(res.data.cities)
    }
  }

  const onFinish = (values) => {
    const lead = {
      ownerName: values['name'],
      identityCard: values['identityCard'],
      phone: values['phone'],
      source: "CORE",
      amount: values['loanAmount'] ? values['loanAmount'] : 0,
      time: values['time'] ? values['time'] : 0,
      loanReason: values['loanReason'],
      businessType: values['businessType'],
      businessIndustryName: values['industry'],
      cityId: city.value,
      city: city.label,
      productId: initialValue.productId,
      partnerId: initialValue.partnerId,
      merchantConsentDate: new Date(),
    }
    setIsLoading(true)
    AdminLeadService.create(lead)
      .then((result) => {
        if (result) {
          const registerLead = result.lead
          if (registerLead && registerLead.id) {
            setLeadId(registerLead.id)
          }
        }
      })
      .catch((e) => {
        console.log(e)
        notification.error({
          message: 'Thất bại',
          description: 'Có lỗi xảy ra vui lòng thử lại sau',
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const FormLabel = (label = '', required = false) => {
    return (
      <span style={{ fontSize: 12 }}>
        {label}
        {required ? <span style={{ color: 'red', marginLeft: '4px' }}>*</span> : <></>}
      </span>
    )
  }

  return (
    <div className="RegisterLeadFormContainer" style={styles}>
      <Loading open={isLoading} />
      {!isLoading && (
        <Fragment>
          <Form
            form={form}
            name="register-lead-form"
            onFinish={onFinish}
            scrollToFirstError
            labelCol={{ span: 24, offset: 0 }}
            labelAlign={'left'}
            size="large"
          >
            <Row className="RegisterLeadFormTitle">Thông tin nhu cầu vốn</Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col
                xl={24}
                xs={24}
                className="register-lead-form-col"
                style={{ paddingLeft: 12, paddingRight: 6 }}
              >
                <Form.Item
                  name="loanAmount"
                  label={FormLabel('Số tiền vay (VND)', true)}
                  rules={ValidateForm.loanAmount}
                  className="register-lead-form-item"
                >
                  <InputNumber
                    className="register-lead-form-input"
                    min={0}
                    max={1000000000}
                    formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    placeholder="Nhập số tiền vay"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="time"
                  label={FormLabel('Thời hạn vay (tháng)', true)}
                  className="register-lead-form-item"
                  initialValue={initialValue.time || months[11].value}
                  disabled={!!initialValue.time}
                  rules={ValidateForm.time}
                >
                  <Select
                    className="register-lead-form-input"
                    placeholder={'Chọn thời hạn vay'}
                    disabled={!!initialValue.time}
                  >
                    {months.map((type, index) => (
                      <Option key={index} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="loanReason"
                  label={FormLabel('Mục đích vay', true)}
                  className="register-lead-form-item"
                  rules={ValidateForm.loanReason}
                  initialValue={isLanding ? LoanReasonTPD[0].value : loanReasons[0].value}
                >
                  <Select
                    className="register-lead-form-input"
                    placeholder={'Chọn mục đích vay'}
                  >
                    {isLanding ? (
                      <Fragment>
                        {LoanReasonTPD.map((type, index) => (
                          <Option key={index} value={type.value}>
                            {type.label}
                          </Option>
                        ))}
                      </Fragment>
                    ) : (
                      <Fragment>
                        {loanReasons.map((type, index) => (
                          <Option key={index} value={type.value}>
                            {type.label}
                          </Option>
                        ))}
                      </Fragment>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="RegisterLeadFormTitle">Thông tin cửa hàng</Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="businessType"
                  label={FormLabel('Hình thức kinh doanh', true)}
                  rules={ValidateForm.businessType}
                  initialValue={leadTypes[1].value}
                  className="register-lead-form-item"
                >
                  <Select
                    className="register-lead-form-input"
                    placeholder={'Chọn loại hình thức kinh doanh'}
                  >
                    {leadTypes.map((type, index) => (
                      <Option key={index} value={type.value}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="industry"
                  label={FormLabel('Lĩnh vực kinh doanh', true)}
                  className="register-lead-form-item"
                  rules={ValidateForm.industry}
                  initialValue={tenant?.industry}
                >
                  <Select
                    className="register-lead-form-input"
                    placeholder={'Chọn lĩnh vực kinh doanh'}
                  >
                    {IndustryOptions.map((type, index) => (
                      <Option key={index} value={type.label}>
                        {type.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row className="RegisterLeadFormTitle">Thông tin người vay</Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="name"
                  label={FormLabel('Họ và tên', true)}
                  rules={ValidateForm.name}
                  className="register-lead-form-item"
                  initialValue={tenant?.owner_name}
                >
                  <Input className="register-lead-form-input" placeholder="Nhập họ và tên" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="identityCard"
                  label={FormLabel('CMND/CCCD', true)}
                  rules={ValidateForm.identityCard}
                  className="register-lead-form-item"
                >
                  <Input className="register-lead-form-input" placeholder="Nhập số CMND/CCCD" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="phone"
                  label={FormLabel('Số điện thoại', true)}
                  rules={ValidateForm.phone}
                  className="register-lead-form-item"
                  initialValue={tenant?.phone}
                >
                  <Input className="register-lead-form-input" placeholder="Nhập số điện thoại" />
                </Form.Item>
              </Col>
              <Col xl={12} xs={12} className="register-lead-form-col">
                <Form.Item
                  name="city"
                  label={FormLabel('Địa bàn cư trú', true)}
                  className="register-lead-form-item"
                  rules={ValidateForm.city}
                >
                  <Select
                    className="register-lead-form-input"
                    placeholder="Chọn địa bàn cư trú"
                    onChange={(value, label) =>
                      setCity({
                        value: value,
                        label: label.children,
                      })
                    }
                    notFoundContent={'Không có dữ liệu'}
                    showSearch={true}
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase().trim()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {cities.map((city, index) => (
                      <Option key={index} value={city.value}>
                        {city.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col span={1}>
                <Checkbox checked={isAccept} onChange={(e) => setIsAccept(e.target.checked)} />
              </Col>
              {Partner.FASTMONEY === partnerId ? (
                <Col span={23} className="RegisterLeadTermConditionContainer">
                  Tôi đã đọc, hiểu và đồng ý cho Sapo thu thập, xử lý dữ liệu cá nhân của tôi với
                  mục đích để được hỗ trợ, sử dụng các giải pháp tài chính, dịch vụ của EVNFC và
                  AMFT dựa trên{' '}
                  <a
                    href="https://support.sapo.vn/chinh-sach-bao-ve-du-lieu-ca-nhan?utm_campaign=cpn%3Achinh_sach_bao_mat-plm%3Afooter&utm_source=sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=footer_sapo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    chính sách bảo vệ dữ liệu cá nhân của Sapo.
                  </a>{' '}
                </Col>
              ) : Partner.TPD === partnerId ? (
                <Col span={23} className="RegisterLeadTermConditionContainer">
                  Tôi biết, đồng ý cho Sapo Xử lý dữ liệu, cung cấp thông tin của tôi trên phần mềm Sapo cho TPBank với mục đích sử dụng Sản phẩm của TPBank dựa trên{' '}
                  <a
                    href="https://support.sapo.vn/chinh-sach-bao-ve-du-lieu-ca-nhan?utm_campaign=cpn%3Achinh_sach_bao_mat-plm%3Afooter&utm_source=sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=footer_sapo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    chính sách bảo vệ dữ liệu cá nhân của Sapo.
                  </a>{' '}
                </Col>
              ) : Partner.VPB === partnerId ? (
                <Col span={23} className="RegisterLeadTermConditionContainer">
                  Tôi biết, đồng ý cho Sapo Xử lý dữ liệu, cung cấp thông tin của tôi trên phần mềm Sapo cho VPBank với mục đích sử dụng Sản phẩm của VPBank dựa trên{' '}
                  <a
                    href="https://support.sapo.vn/chinh-sach-bao-ve-du-lieu-ca-nhan?utm_campaign=cpn%3Achinh_sach_bao_mat-plm%3Afooter&utm_source=sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=footer_sapo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    chính sách bảo vệ dữ liệu cá nhân của Sapo.
                  </a>{' '}
                </Col>
              ) : Partner.KBANK === partnerId ? (
                <Col span={23} className="RegisterLeadTermConditionContainer">
                  Tôi biết, đồng ý cho Sapo Xử lý dữ liệu, cung cấp thông tin của tôi trên phần mềm Sapo cho KBank với mục đích sử dụng Sản phẩm của KBank dựa trên{' '}
                  <a
                    href="https://support.sapo.vn/chinh-sach-bao-ve-du-lieu-ca-nhan?utm_campaign=cpn%3Achinh_sach_bao_mat-plm%3Afooter&utm_source=sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=footer_sapo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    chính sách bảo vệ dữ liệu cá nhân của Sapo.
                  </a>{' '}
                </Col>
              ) : (
                <Col span={23} className="RegisterLeadTermConditionContainer">
                  Tôi biết, đồng ý cho Sapo Xử lý dữ liệu của tôi phát sinh trên phần mềm Sapo cho 
                  mục đích được hỗ trợ các giải pháp tài chính dựa trên dựa trên{' '}
                  <a
                    href="https://support.sapo.vn/chinh-sach-bao-ve-du-lieu-ca-nhan?utm_campaign=cpn%3Achinh_sach_bao_mat-plm%3Afooter&utm_source=sapo.vn&utm_medium=referral&utm_content=fm%3Atext_link-km%3A-sz%3A&utm_term=&campaign=footer_sapo"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Chính sách bảo vệ dữ liệu cá nhân
                  </a>{' '}
                </Col>
              )}
            </Row>
            <Row justify="center" style={{ marginTop: '24px' }}>
              <Form.Item style={{ paddingRight: 6 }}>
                <Button
                  className={'RegisterLeadSubmitButton '}
                  type="primary"
                  htmlType="submit"
                  disabled={!isAccept}
                >
                  Đăng ký
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </Fragment>
      )}
    </div>
  )
}

export default RegisterDefault
