/* eslint-disable no-sparse-arrays */
import { Col, Row } from 'antd'
import React, { useEffect, useState, useContext } from 'react'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useHistory } from 'react-router'
import Loading from '../components/Loading'
import Header from '../pages/header/Header'
import tenantService from '../services/tenant/TenantService'
import image1 from './../components/home/image1.svg'
import phone from './../components/home/phone.svg'
import { LIST_BLOG as blogs } from './blog'
import { LIST_OF_MENU_FIN as dataFin } from './menus'
import { LIST_IMAGE_SLIDE as slides } from './slide'
import queryString from 'query-string'
import { toString } from 'lodash'
import { AuthenticationContext } from '../contexts/AuthenticationContext'
import authenticationActionType from '../contexts/AuthenticationActionType'
import { getKey, decrypt } from '../utils/encryptUtil'

export default function SapoFinComponent() {
  const history = useHistory()
  // const authenticationStore = useContext(AuthenticationContext)
  // const { state = {} } = authenticationStore || {}
  // const { currentUser = {} } = state || {}
  // const { id = 0, accountOwner = false } = currentUser
  const [isLoading, setIsLoading] = useState(true)
  const [tenant, setTenant] = useState({})
  const { dispatch } = useContext(AuthenticationContext)

  function onFinOption(_blank, url) {
    if (_blank) {
      window.open(url)
    } else {
      const callback = queryString.parse(window.location.href)
      history.push(`${url}?hmac=${callback.hmac}&tenant=${toString(callback.tenant)}`)
    }
  }
  const changeColor = (e) => {
    e.target.style.color = '#0088FF'
  }
  const changeColorOut = (e) => {
    e.target.style.color = '#0F1824'
  }
  useEffect(() => {
    const key = getKey()
    if (key) {
      const keyDecrypt = decrypt(key)
      if (keyDecrypt && keyDecrypt.token) {
        dispatch({
          type: authenticationActionType.signIn,
          payload: keyDecrypt,
        })
      }
    }
    tenantService
      .getTenantInfo()
      .then(
        (result) => {
          if (result) {
            setTenant(result.tenant)
          }
          setIsLoading(false)
        },
        (error) => {
          setIsLoading(false)
          console.error(error.response)
        }
      )
      .catch((e) => {
        setIsLoading(false)
        console.error(e)
      })
  }, [])
  return (
    <div className="SapoFin">
      <Loading open={isLoading} />
      {!isLoading && (
        <>
          <Header title="Sapo Money" />
          <div className="SapoMoneyContainer" style={{ marginTop: 100 }}>
            <div className="SapoMoneyHeaderContainer">
              <div className="row">
                <div className="col-9">
                  <div className="title">
                    <h5>Xin chào {tenant?.owner_name}</h5>
                  </div>
                  <div className="description">
                    <span>Sapo Money - Giải pháp tài chính đồng hành cùng nhà bán hàng</span>
                  </div>
                  <div className="subDescription">
                    <span>
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Mở rộng
                      quy mô kinh doanh
                    </span>
                    <span>
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Gia tăng
                      doanh số bán hàng
                    </span>
                    <span>
                      <i className="far fa-check-circle" style={{ color: '#0088FF' }}></i>Tối ưu
                      hiệu quả tài chính
                    </span>
                  </div>
                </div>
                <div className="col-3">
                  <img src={image1} />
                </div>
              </div>
            </div>
            <div className="SapoFinContainer">
              <div className="SapoFinOptionsContainer">
                <Row gutter={24}>
                  {dataFin.map((item) => (
                    <Col
                      span={8}
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={8}
                      key={item.id}
                      xxl={8}
                      style={{ textAlign: 'center' }}
                    >
                      <div
                        className={`FinOptionsContainer ${!item.active && 'FinOptionNotActive'}`}
                        onClick={() => {
                          onFinOption(item._blank, item.url)
                        }}
                      >
                        {!item.active && (
                          <div className="FinOptionIconNotActive">
                            <span>Sắp ra mắt</span>
                          </div>
                        )}
                        <div className="ImgFinOption">
                          <img src={item.img} />
                        </div>
                        <div className="FinOptionTitle">{item.title}</div>
                        <div className="FinOptionDescription">{item.description}</div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
          <div className="SapoFinContainer mt-4">
            <Carousel
              autoPlay={true}
              showThumbs={false}
              useKeyboardArrows={true}
              showStatus={false}
              infiniteLoop={true}
            >
              {slides.map((item) => {
                return (
                  <div
                    key={item.img}
                    style={{ cursor: 'pointer' }}
                    onClick={() => window.open(item.url)}
                  >
                    <img src={item.img} className="slide-image" />
                  </div>
                )
              })}
            </Carousel>
          </div>
          <div className="SapoMoneyBlogContainer mt-4" style={{ background: '#fff' }}>
            <div className="SapoMoneyBlogTitle">
              <div className="row" style={{ marginLeft: 0, marginRight: 16, padding: '10px 0px' }}>
                <div className="col-6 text-left" style={{ padding: '8px 16px' }}>
                  <span className="title font-weight-bold">Có thể bạn quan tâm</span>
                </div>
                <div className="col-6 text-right" style={{ padding: '8px 16px' }}>
                  <span className="show-more">
                    <a target="_blank" href="https://www.sapo.vn/sapo-money.html">
                      Xem thêm
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div className="SapoMoneyListBlogContainer" style={{ margin: '8px 16px' }}>
              <div className="list-blog">
                <div className="row" style={{ paddingBottom: 16 }}>
                  {blogs.map((item, index) => {
                    return (
                      <div
                        className="col-4 d-flex flex-row blog"
                        key={index}
                        style={{ paddingLeft: 16, paddingRight: 0 }}
                      >
                        <div style={{ paddingTop: 8 }}>
                          <img src={item.img} />
                        </div>
                        <div style={{ padding: 8, paddingRight: 0 }}>
                          <div className="title" style={{ maxWidth: 430 }}>
                            <span>
                              <a
                                target="_blank"
                                href={item.url}
                                style={{ color: '#0F1824', fontSize: 14 }}
                                onMouseOver={changeColor}
                                onMouseOut={changeColorOut}
                              >
                                {item.title}
                              </a>
                            </span>
                          </div>
                          <span className="created d-block">{item.date}</span>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div>
              <div className="hotline-phone-ring-wrap">
                <a href="tel:02473086880">
                  <img src={phone} alt="Gọi điện thoại" width={50} />
                </a>
              </div>
              {/* <div className="hotline-message-ring-wrap">
                <a href="tel:02473086880" >
                  <img src={mess} alt="Gọi điện thoại" width={50} />
                </a>
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
