import { Box } from '@material-ui/core'
import React, { Fragment } from 'react'
import sapoLogo from '../../components/SapoLogo.svg'
import ConvertLogoPartner from '../../utils/ConvertLogoPartner'
import { Partner } from '../../common/Constant'

export default function RegisterModalHeader(props) {
  const { partnerId } = props

  const getStyleLogo = (partnerId) => {
    if (Partner.TPD === partnerId || Partner.TPB === partnerId) {
      return { height: 52, width: 95 }
    } else if (Partner.KAG === partnerId) {
      return { height: 65, width: 75 }
    } else if (Partner.ASP === partnerId) {
      return { height: 60, width: 120 }
    } else if (Partner.EVN === partnerId) {
      return { height: 45, width: 115 }
    } else if (Partner.VPB === partnerId) {
      return { height: 40, width: 125 }
    } else if (Partner.FASTMONEY === partnerId) {
      return { height: 40, width: 170 }
    } else if (Partner.KBANK === partnerId) {
      return { height: 40, width: 170 }
    } else if (Partner.TCB === partnerId) {
      return { height: 40, width: 293 }
    }
    return { height: 60, width: 103 }
  }

  return (
    <Fragment>
      <Box
        className={'RegisterLeadHeaderRegisterModal'}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box className="logo-group">
          {partnerId ? (
            <Fragment>
              <Box className="register-lead-sapo-fin-logo" style={{ marginRight: 15 }}>
                <img src={sapoLogo} alt="Sapo Money Logo" style={{ width: 111, height: 40 }} />
              </Box>
              <Box className="register-lead-sapo-fin-logo">
                <ConvertLogoPartner partnerId={partnerId} style={getStyleLogo(partnerId)} />
              </Box>
            </Fragment>
          ) : (
            <Box className="register-lead-sapo-fin-logo">
              <img src={sapoLogo} alt="Sapo Money Logo" style={{ width: 150, height: 50 }} />
            </Box>
          )}
        </Box>
        <Box className="RegisterLeadTitleModalContainer">ĐĂNG KÝ TƯ VẤN VAY VỐN</Box>
      </Box>
    </Fragment>
  )
}
