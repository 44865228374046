import React from 'react'
import icon_1 from './svg/icon-1.svg'
import icon_2 from './svg/icon-2.svg'
import icon_3 from './svg/icon-3.svg'
import icon_4 from './svg/icon-4.svg'
import icon_5 from './svg/icon-5.svg'
import icon_6 from './svg/icon-6.svg'

export const INFORMATION_TAG = [
  {
    icon: icon_1,
    title: 'Siêu nhanh',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Chỉ mất 2 phút đăng ký</p>
        <p style={{ marginBottom: 0 }}>5 phút chuẩn bị hồ sơ</p>
      </>
    ),
  },
  {
    icon: icon_2,
    title: 'Siêu đơn giản',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Tư vấn nhiệt tình, miễn phí.</p>
        <p style={{ marginBottom: 0 }}>Hồ sơ, thủ tục nhanh chóng</p>
      </>
    ),
  },
  {
    icon: icon_3,
    title: 'An toàn tuyệt đối',
    content: (
      <p style={{ marginBottom: 0 }}>Dịch vụ cho vay chính thống được cung cấp bởi đối tác Sapo</p>
    ),
  },
  // {
  //   icon: icon_4,
  //   title: 'Lãi suất cạnh tranh',
  //   content: (
  //     <>
  //       <p style={{ marginBottom: 0 }}>Có đăng ký kinh doanh: 2%/tháng</p>
  //       <p style={{ marginBottom: 0 }}>Không đăng ký kinh doanh: 2,25%/tháng</p>
  //       <p style={{ marginBottom: 0, fontSize: 13, opacity: '0.9' }}>
  //         (Lãi tính trên dư nợ giảm dần)
  //       </p>
  //     </>
  //   ),
  // },
  {
    icon: icon_5,
    title: 'Kỳ hạn linh hoạt',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Kỳ hạn: 3 đến 36 tháng</p>
        <p style={{ marginBottom: 0 }}>Thanh toán gốc, lãi định kỳ hàng tháng</p>
      </>
    ),
  },
  // {
  //   icon: icon_6,
  //   title: 'Hạn mức phù hợp',
  //   content: '100% doanh thu bình quân 6 tháng gần nhất, tối đa lên tới 150 triệu đồng',
  // },
]

export const INFORMATION_TAG_VPBANK = [
  {
    icon: icon_1,
    title: 'Siêu nhanh',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Chỉ mất 2 phút đăng ký</p>
        <p style={{ marginBottom: 0 }}>5 phút chuẩn bị hồ sơ</p>
      </>
    ),
  },
  {
    icon: icon_2,
    title: 'Siêu đơn giản',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Tư vấn nhiệt tình, miễn phí.</p>
        <p style={{ marginBottom: 0 }}>Hồ sơ, thủ tục nhanh chóng</p>
      </>
    ),
  },
  {
    icon: icon_3,
    title: 'An toàn tuyệt đối',
    content: (
      <p style={{ marginBottom: 0 }}>Dịch vụ cho vay chính thống được cung cấp bởi đối tác Sapo</p>
    ),
  },
  {
    icon: icon_5,
    title: 'Kỳ hạn linh hoạt',
    content: (
      <>
        <p style={{ marginBottom: 0 }}>Kỳ hạn: 12 đến 60 tháng</p>
        <p style={{ marginBottom: 0 }}>Thanh toán gốc, lãi định kỳ hàng tháng</p>
      </>
    ),
  },
]
