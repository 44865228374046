import { Button, Result } from 'antd'
import React from 'react'
import { BANKHUB } from '../../common/Constant'

export default function BhErr404Component() {
  return (
    <Result
      status="404"
      title="404"
      subTitle="Sorry, the page you visited does not exist."
      extra={
        <Button type="primary" onClick={() => (window.location.href = BANKHUB)}>
          Trang chủ
        </Button>
      }
    />
  )
}
