import React from 'react'

function FieldDetail(props) {
  const { label, value, style, className, styleLabel } = props
  return (
    <div style={style} className={className}>
      <div className="Display-root  Display-container">
        <div
          className="Display-root Display-container Display-item Display-direction-column label-detail "
          style={styleLabel}
        >
          <p style={{ fontSize: 14 }}>{label}</p>
        </div>
        <div className="Display-root Display-container Display-item Display-direction-column value-detail ">
          {label && <p style={{ fontSize: 14 }}>: {value}</p>}
        </div>
      </div>
    </div>
  )
}

export default FieldDetail
