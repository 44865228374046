/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-unused-vars */
import { Collapse } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import BreadcrumbComponent from '../../../bankHupComponent/router/BreadcrumbComponent'
import ChevronDuoDown from '../../../components/ChevronDuoDownIcon.svg'
import ChevronDuoUp from '../../../components/ChevronDuoUpIcon.svg'
import DoneIcon from '../../../components/DoneIcon.svg'
import Loading from '../../../components/Loading'
import tenantService from '../../../services/tenant/TenantService'
import { formatMoney } from '../../../utils/FormatNumber'
import AccumulationModal from '../register/AccumulationModal'
import logo from './VND2015.png'
const productList = [
  {
    name: 'DFUND|Quỹ trái phiếu',
    minimalCapital: 100000,
    expectedProfit: '5-7%',
    investmentTime: '1 năm ++',
    risk: 'Thấp',
    logo: '',
    note:
      'Phù hợp với mọi đối tượng-Danh mục trái phiếu niêm yết và các tài sản có thu nhập cố định khác',
    code: 'DFUND',
  },
  {
    name: 'DBOND|Trái phiếu DN, cam kết mua lại',
    minimalCapital: 10000000,
    expectedProfit: 'Lên đến 8.7%',
    investmentTime: '3-12 tháng',
    risk: 'Thấp',
    logo: '',
    note:
      'Phù hợp với mọi đối tượng-Trái phiếu của DN có nền tảng vững mạnh, hoạt động trong các lĩnh vực hàng đầu của nền kinh tế',
    code: 'DBOND',
  },
  {
    name: 'VBOND|Trái phiếu DN, hỗ trợ thanh khoản',
    minimalCapital: 50000000,
    expectedProfit: 'Lên đến hơn 10%',
    investmentTime: '3-12 tháng',
    risk: 'Thấp',
    logo: '',
    note:
      'Phù hợp với mọi đối tượng-Trái phiếu của DN có nền tảng vững mạnh, hoạt động trong các lĩnh vực hàng đầu của nền kinh tế',
    code: 'VBOND',
  },
]
function ProductRow(props) {
  const { product, changeCurrentProduct = (f) => f, showRegisterForm = (f) => f } = props
  const [open, setOpen] = useState(false)
  const handleRegisterProduct = (e) => {
    changeCurrentProduct(product)
    showRegisterForm(true)
  }
  const productNote =
    product && product.note ? (
      product.note.split('-').map((note, i) => {
        return (
          <li key={i} className="requirement-item">
            <img className="icon" src={DoneIcon} />
            <span className="content">{note}</span>
          </li>
        )
      })
    ) : (
      <></>
    )

  return (
    <React.Fragment>
      <div className="paper Shadow8 product-container">
        <Row className="product-row">
          <Col className="partner-logo">
            <img src={logo} className="logo" alt="partner logo" />
          </Col>
          <Col className="accumulation-product">
            <div className="product-name">
              {String(product.name)
                .split('|')
                .map((n, i) => (
                  <div key={i}>{n}</div>
                ))}
            </div>
          </Col>
          <Col className="minimal-capital">
            <div className="minimal-capital-detail">
              {formatMoney(product.minimalCapital, 0, '.', ',', ' VND')}
            </div>
            <div className="label-info">Số vốn tối thiểu</div>
          </Col>
          <Col className="expected-profit">
            <div className="expected-profit-detail">{`${product.expectedProfit}`}</div>
            <div className="label-info">Lợi nhuận kỳ vọng</div>
          </Col>
          <Col className="investment-time">
            <div className="investment-time-detail">{`${product.investmentTime} `}</div>
            <div className="label-info">Thời gian đầu tư</div>
          </Col>
          <Col className="risk">
            <div className="risk-detail">{product.risk}</div>
            <div className="label-info">Rủi ro</div>
          </Col>
          <Col className="register-control">
            <Button variant="primary" onClick={handleRegisterProduct} className="register-btn">
              Yêu cầu tư vấn
            </Button>
          </Col>
        </Row>
        <div
          className="accumulation-product-row-control"
          aria-label="product expand row"
          onClick={() => setOpen(!open)}
        >
          <div style={{ display: 'inline-block', margin: '0 4px' }}>
            {open ? (
              <>
                Thu gọn <img src={ChevronDuoUp} />
              </>
            ) : (
              <>
                Chi tiết <img src={ChevronDuoDown} />
              </>
            )}
          </div>
        </div>
      </div>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className="paper Shadow8 product-detail-container"
      >
        <ul className="list-requirement">{productNote}</ul>
      </Collapse>
    </React.Fragment>
  )
}
function createData(name, minimalCapital, expectedInterest, investmentTime, risk) {
  return { name, minimalCapital, expectedInterest, investmentTime, risk }
}
export default function AccumulationProduct() {
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(false)
  const [tenant, setTenant] = useState({})
  const [currentProduct, setCurrentProduct] = useState({})
  const [products, setProducts] = useState([])
  const [showRegisterForm, setShowRegisterForm] = useState(false)
  const handleChangeCurrentProduct = (product) => {
    setCurrentProduct(product)
  }
  const handleRegisterSuccessful = () => {
    setShowRegisterForm(false)
  }
  useEffect(() => {
    tenantService.getTenantInfo().then(
      (result) => {
        if (result) setTenant(result.tenant)
      },
      (error) => {
        // console.log(error.response);
      }
    )
  }, [])
  useEffect(() => {
    setTenant({
      ...tenant,
      productCode: currentProduct.code,
      partnerLogo: currentProduct.logo,
    })
  }, [currentProduct])
  const r = productList.map((product, i) => (
    <ProductRow
      key={i}
      index={i}
      product={product}
      changeCurrentProduct={handleChangeCurrentProduct}
      showRegisterForm={(value) => setShowRegisterForm(value)}
    />
  ))
  // const onOpen = () => { setIsVisibleAccumulationModal(true) }
  // const onClose = () => { setIsVisibleAccumulationModal(false) }
  return (
    <Container fluid className="wrapper accumulationContainer">
      <div className="breadcrumHeader">
        <BreadcrumbComponent />
      </div>
      <Container fluid>
        <Loading open={isLoading} />
        <div className="accumulationTitle">Danh sách sản phẩm tích lũy</div>
        {r}
      </Container>
      <AccumulationModal
        tenant={tenant}
        productCode={currentProduct.code}
        visible={showRegisterForm}
        open={() => setShowRegisterForm(true)}
        close={() => setShowRegisterForm(false)}
        partnerLogo={logo}
      />
    </Container>
  )
}
