import blog4 from '../components/home/blog4.svg';
import blog5 from '../components/home/blog5.svg';
import blog6 from '../components/home/blog6.svg';

export const LIST_BLOG =[
    {
        img:blog4,
        title:"SAPO và OCB hợp tác ra mắt giải pháp thanh toán OCB VietQR trên nền tảng Sapo",
        date:"07-11-2022",
        url:"https://www.sapo.vn/blog/sapo-va-ocb-hop-tac-ra-mat-giai-phap-thanh-toan-ocb-vietqr-tren-nen-tang-sapo"
    },
    {
        img:blog5,
        title:"VPBank - Vay tín chấp 300 triệu, chủ shop không thể bỏ qua",
        date:"04-11-2022",
        url:"https://www.sapo.vn/blog/vay-tin-chap-300-trieu-tai-vpbank"
    },
    {
        img:blog6,
        title:"Ưu đãi quét mã QR tại Sapo: Hỗ trợ nhà bán hàng tăng trưởng doanh thu",
        date:"26-09-2022",
        url:"https://www.sapo.vn/blog/uu-dai-quet-ma-qr-tai-sapo"
    }
]