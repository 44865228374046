import apiClient from '../ApiClient'
import apiAs3 from './ApiAs3'

const amazonWebService = {
  getOneImage: async (files) => {
    try {
      const result = await apiClient.post(`api/files-show.json`, {
        files: [`${files}`],
      })
      console.log('result: ', result)
      return result
    } catch (error) {
      console.log(error)
      return null
    }
  },
  uploadImgae: async (request) => {
    try {
      const formData = new FormData()
      formData.append('file', request)
      const result = await apiClient.post(`api/file-update.json`, {
        formData,
      })
      console.log('result: ', result)
      return result
    } catch (error) {
      console.log(error)
      return null
    }
  },

  uploadOneImage: async (
    file
  ) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await apiClient.post(`api/file-update.json`,
        formData
      )

      return result
    } catch (error) {
      console.log(error)
      return null
    }
  },

  uploadListImage: async (
    files
  ) => {
    try {
      const formData = new FormData();
      files.map((item) => {
        formData.append("files", item);
      })

      // const result = await apiAs3.post(`api/v1/amazon-s3/list-file.json`,
      //   formData
      // )
      const result = await apiClient.post(`api/files.json`,
        formData
      )

      return result
    } catch (error) {
      console.log(error)
      return null
    }
  },
    uploadOneImage: async (
      file
    ) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = await apiClient.post(`api/file.json`,
        formData
      )

      return result
    } catch (error) {
      console.log(error)
      return null
    }
  }
}
export default amazonWebService
