import React from 'react'
import { Col, Pagination, Row, Select } from 'antd'
import { Option } from 'antd/lib/mentions'
import { CaretDownOutlined } from '@ant-design/icons';
const pagaItems = [10, 20, 50, 100]

export function PaginationCustomComponent(props) {
  const { pageable = {}, setPageable = () => {} } = props
  const { pageIndex = 1, pageSize = 10, total = 0 } = pageable

  return (
    <div className="PaginationContainer">
      <Row justify="end">
        <Col span={12} style={{ textAlign: 'end' }}>
          <span style={{ paddingRight: '2%', fontSize: '14px' }}>Hiển thị</span>
          <Select
            value={pageSize}
            onChange={(value) => setPageable({ ...pageable, pageSize: value,pageIndex:1 })}
            suffixIcon={<CaretDownOutlined />}
            dropdownClassName="DropdownPageContainer"
          >
            {pagaItems.map((pageItem) => (
              <Select.Option key={pageItem} value={pageItem}>
                {pageItem}
              </Select.Option>
            ))}
          </Select>
          <span style={{ paddingLeft: '2%' }}>kết quả</span>
        </Col>
        <Col span={6} style={{ textAlign: 'end', paddingTop: '5px' }}>
          <div style={{ height: '100%', paddingTop: '3px' }}>
            Hiển thị kết quả từ {total === 0 ? 0 : pageSize * (pageIndex - 1) + 1} -{' '}
            {pageIndex * pageSize >= total ? total : pageSize * pageIndex} trên tổng {total}
          </div>
        </Col>

        <Col span={5} style={{ textAlign: 'end' }}>
          <Pagination
            size="small"
            pageSize={pageSize}
            total={total === 0 ? 1 : total}
            current={pageIndex}
            onChange={(index) => setPageable({ ...pageable, pageIndex: index })}
          />
        </Col>
      </Row>
    </div>
  )
}
export const PaginationComponent = React.memo(PaginationCustomComponent)
