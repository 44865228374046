import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Grid } from '@material-ui/core'
import React, { Fragment, useEffect, useState } from 'react'
import { Card, Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import historyUtil from '../../common/historyUtil'
import emptyImg from '../../img/svg/emptyImg.svg'
import AdminLeadService from '../../services/admin/lead/AdminLeadService'
import AdminTenantService from '../../services/admin/tenant/AdminTenantService'
import LoanHistoryEmpty from './LoanHistoryEmpty'
import RowContent from './components/RowContent'
import ApplicationList from './components/application/ApplicationList'
import LeadList from './components/lead/LeadList'
import './style.scss'
import Loading from '../../components/Loading'
import { formatMoney } from '../../utils/FormatNumber'
import { convertValue, fileExtensionNotAllowShow, getBusinesTypeName } from '../../utils/Utils'
import moment from 'moment'
import ModalPreview from './components/ModalPreview'

export default function LoanHistory() {
  const history = useHistory()
  const tabs = [{ id: 1, title: 'Yêu cầu tư vấn' }, { id: 2, title: 'Hồ sơ' }]
  const [tabActive, setTabActive] = useState(1)
  const [emptyLoan, setEmptyLoan] = useState(true)
  const [loading, setLoanding] = useState(false)
  const [tenant, setTenant] = useState({})
  const [customer, setCustomer] = useState()
  const [files, setFiles] = useState([])
  const [modalPreview, setModalPreview] = useState({
    content: '',
    url: '',
    visible: false,
  })

  useEffect(() => {
    setLoanding(true)
    AdminLeadService.countLeads()
      .then((result) => {
        if (result && result.metadata && result.metadata.count > 0) {
          setEmptyLoan(false)
        }
      })
      .finally(() => {
        setLoanding(false)
      })
    return () => {
      console.log('end')
    }
  }, [])

  useEffect(() => {
    if (!emptyLoan) {
      setLoanding(true)
      AdminTenantService.getTenant()
        .then((res) => {
          if (res && res.tenant) {
            setTenant(res.tenant)
            setCustomer(res.tenant?.customer)
            setFiles(res.tenant?.customer?.files)
          }
        })
        .finally(() => setLoanding(false))
    }
  }, [emptyLoan])

  return (
    <Fragment>
      {emptyLoan ? (
        <LoanHistoryEmpty loading={loading} />
      ) : (
        <Fragment>
          {loading ? (
            <Loading open={loading} />
          ) : (
            <Container fluid className="wrapper">
              <Box className="header">
                <Box style={{ marginLeft: 24 }}>
                  <span style={{ fontSize: '15px' }}>
                    <span className="btn-back" onClick={() => historyUtil(history, '/los')}>
                      <FontAwesomeIcon
                        icon={faAngleLeft}
                        className="product-back-icon-calculator"
                      />
                      Vay vốn kinh doanh
                    </span>
                  </span>
                </Box>
              </Box>
              <Container fluid style={{ marginTop: 90, padding: '0 30px' }}>
                <h5 style={{ fontWeight: 'bold' }}>Lịch sử khoản vay</h5>
                <Grid container spacing={3}>
                  <Grid item xl={8} xs={8}>
                    <Card className="mt-2" style={{ border: 'none' }}>
                      <Card.Header
                        style={{
                          backgroundColor: '#ffff',
                          color: '0F1824',
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Thông tin người vay
                      </Card.Header>
                      <Card.Body>
                        <RowContent
                          leftTitle={'Họ và tên'}
                          leftValue={convertValue(customer?.name)}
                          rightTitle={'Căn cước công dân'}
                          rightValue={convertValue(customer?.identity_card)}
                        />
                        <RowContent
                          leftTitle={'Ngày cấp'}
                          leftValue={
                            customer?.ic_date
                              ? moment(customer?.ic_date).format('DD/MM/YYYY')
                              : '---'
                          }
                          rightTitle={'Nơi cấp'}
                          rightValue={convertValue(customer?.ic_place_name)}
                        />
                        <RowContent
                          leftTitle={'Giới tính'}
                          leftValue={convertValue(customer?.gender)}
                          rightTitle={'Ngày sinh'}
                          rightValue={
                            customer?.birthday
                              ? moment(customer?.birthday).format('DD/MM/YYYY')
                              : '---'
                          }
                        />
                        <RowContent
                          leftTitle={'CMND cũ'}
                          leftValue={convertValue(customer?.identity_card_old)}
                          rightTitle={'Email'}
                          rightValue={convertValue(customer?.email)}
                        />
                        <RowContent
                          leftTitle={'Số điện thoại'}
                          leftValue={convertValue(customer?.phone)}
                          rightTitle={'Địa chỉ cư trú'}
                          rightValue={convertValue(customer?.address)}
                        />
                        <RowContent
                          leftTitle={'Khu vực'}
                          leftValue={
                            customer?.city
                              ? `${customer?.city} ${
                                  customer?.district ? `- ${customer?.district}` : ''
                                }`
                              : '---'
                          }
                          rightTitle={'Phường xã'}
                          rightValue={convertValue(customer?.ward)}
                        />
                        <RowContent
                          leftTitle={'Tình trạng hôn nhân'}
                          leftValue={convertValue(customer?.married_status)}
                          rightTitle={'Số người phụ thuộc'}
                          rightValue={convertValue(customer?.dependants_number)}
                        />
                        <RowContent
                          leftTitle={'Trình độ học vấn'}
                          leftValue={convertValue(customer?.education_status)}
                          rightTitle={'Thu nhập hàng tháng'}
                          rightValue={
                            customer?.monthly_income ? formatMoney(customer?.monthly_income) : '---'
                          }
                        />
                      </Card.Body>
                    </Card>
                    <Card className="mt-4" style={{ border: 'none' }}>
                      <Card.Header
                        style={{
                          backgroundColor: '#ffff',
                          color: '0F1824',
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Thông tin cửa hàng {tenant?.name}
                      </Card.Header>
                      <Card.Body>
                        <RowContent
                          leftTitle={'Hình thức kinh doanh'}
                          leftValue={
                            tenant?.business_type
                              ? getBusinesTypeName(tenant?.business_type)
                              : '---'
                          }
                          rightTitle={'Lĩnh vực kinh doanh'}
                          rightValue={convertValue(tenant?.industry)}
                        />
                        <RowContent
                          leftTitle={'Số ĐKKD'}
                          leftValue={convertValue(tenant?.business_number)}
                          rightTitle={'Địa chỉ kinh doanh'}
                          rightValue={convertValue(tenant?.business_address)}
                        />
                        <RowContent
                          leftTitle={'Khu vực'}
                          leftValue={
                            tenant?.city
                              ? `${tenant?.city} ${tenant?.district ? `- ${tenant?.district}` : ''}`
                              : '---'
                          }
                          rightTitle={'Phường xã'}
                          rightValue={convertValue(tenant?.ward)}
                        />
                      </Card.Body>
                    </Card>
                    <Card className="mt-4" style={{ border: 'none' }}>
                      <Box className="loan_menu">
                        <Box style={{ borderBottom: '1px #e8eaeb solid', height: '48px' }}>
                          <Box className="title-tab">
                            {tabs.map((item) => (
                              <Box
                                key={item.id}
                                className={`${tabActive === item.id && 'active-container'}`}
                                test={item.id}
                                onClick={() => setTabActive(item.id)}
                              >
                                {item.title}
                              </Box>
                            ))}
                          </Box>
                        </Box>
                        <Box>{tabActive === 1 ? <LeadList /> : <ApplicationList />}</Box>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item xl={4} xs={4}>
                    <Card style={{ border: 'none', marginTop: 8 }}>
                      <Card.Header
                        style={{
                          backgroundColor: '#ffff',
                          color: '0F1824',
                          fontWeight: 'bold',
                          fontSize: 16,
                        }}
                      >
                        Ảnh chứng từ
                      </Card.Header>
                      <Card.Body>
                        <Box>
                          <Box>
                            <span
                              className="label-detail"
                              style={{ marginBottom: 10, marginTop: 10 }}
                            >
                              CMND/CCCD mặt trước
                            </span>
                          </Box>
                          <Box>
                            {files?.filter(
                              (item) =>
                                item.type === 1 &&
                                !fileExtensionNotAllowShow.includes(item.extension)
                            ).length > 0 ? (
                              <Fragment>
                                {files
                                  ?.filter(
                                    (item) =>
                                      item.type === 1 &&
                                      !fileExtensionNotAllowShow.includes(item.extension)
                                  )
                                  ?.map((item, index) => (
                                    <img
                                      key={index}
                                      src={item?.url ?? emptyImg}
                                      alt="CMND/CCCD mặt trước"
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        cursor: 'pointer',
                                        marginRight: 10,
                                      }}
                                      onClick={() =>
                                        setModalPreview({
                                          content: 'Chi tiết ảnh CMND/CCCD mặt trước',
                                          url: item?.url ? item.url : emptyImg,
                                          visible: true,
                                        })
                                      }
                                    />
                                  ))}
                              </Fragment>
                            ) : (
                              <img
                                src={emptyImg}
                                alt="CMND/CCCD mặt trước"
                                style={{ width: '70px', height: '70px' }}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <span
                              className="label-detail"
                              style={{ marginBottom: 10, marginTop: 10 }}
                            >
                              CMND/CCCD mặt sau
                            </span>
                          </Box>
                          <Box>
                            {files?.filter(
                              (item) =>
                                item.type === 2 &&
                                !fileExtensionNotAllowShow.includes(item.extension)
                            ).length > 0 ? (
                              <Fragment>
                                {files
                                  ?.filter(
                                    (item) =>
                                      item.type === 2 &&
                                      !fileExtensionNotAllowShow.includes(item.extension)
                                  )
                                  ?.map((item, index) => (
                                    <img
                                      key={index}
                                      src={item?.url ?? emptyImg}
                                      alt="CMND/CCCD mặt sau"
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        cursor: 'pointer',
                                        marginRight: 10,
                                      }}
                                      onClick={() =>
                                        setModalPreview({
                                          content: 'Chi tiết ảnh CMND/CCCD mặt sau',
                                          url: item?.url ? item.url : emptyImg,
                                          visible: true,
                                        })
                                      }
                                    />
                                  ))}
                              </Fragment>
                            ) : (
                              <img
                                src={emptyImg}
                                alt="CMND/CCCD mặt sau"
                                style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                                onClick={() => console.log('click')}
                              />
                            )}
                          </Box>
                        </Box>
                        <Box>
                          <Box>
                            <span
                              className="label-detail"
                              style={{ marginBottom: 10, marginTop: 10 }}
                            >
                              Ảnh chân dung
                            </span>
                          </Box>
                          <Box>
                            {files?.filter(
                              (item) =>
                                item.type === 9 &&
                                !fileExtensionNotAllowShow.includes(item.extension)
                            ).length > 0 ? (
                              <Fragment>
                                {files
                                  ?.filter(
                                    (item) =>
                                      item.type === 9 &&
                                      !fileExtensionNotAllowShow.includes(item.extension)
                                  )
                                  ?.map((item, index) => (
                                    <img
                                      key={index}
                                      src={item?.url ?? emptyImg}
                                      alt="Ảnh chân dung"
                                      style={{
                                        width: '70px',
                                        height: '70px',
                                        cursor: 'pointer',
                                        marginRight: 10,
                                      }}
                                      onClick={() =>
                                        setModalPreview({
                                          content: 'Chi tiết ảnh chân dung',
                                          url: item?.url ? item.url : emptyImg,
                                          visible: true,
                                        })
                                      }
                                    />
                                  ))}
                              </Fragment>
                            ) : (
                              <img
                                src={emptyImg}
                                alt="Ảnh chân dung"
                                style={{ width: '70px', height: '70px', cursor: 'pointer' }}
                              />
                            )}
                          </Box>
                        </Box>
                      </Card.Body>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
              <ModalPreview
                url={modalPreview.url}
                content={modalPreview.content}
                visible={modalPreview.visible}
                setVisible={setModalPreview}
              />
            </Container>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
