import _ from 'lodash'
export const compareObjects = (o1, o2) =>
  (_.isNull(o1) || _.isUndefined(o1)) && (_.isNull(o2) || _.isUndefined(o2))
    ? typeof o1 === 'object' && Object.keys(o1).length > 0
      ? Object.keys(o1).length === Object.keys(o2).length &&
      Object.keys(o1).every((p) => compareObjects(o1[p], o2[p]))
      : o1 === o2
    : o1 === o2
export const compareArrays = (a1, a2) =>
  a1.length === a2.length && a1.every((o, idx) => compareObjects(o, a2[idx]))
// Warn if overriding existing method
export default function ArrEQ(a1, a2) {
  return (
    //:Are both elements arrays?
    Array.isArray(a1) && Array.isArray(a2)
      ? //:Yes: Test each entry for equality:
      a1.every((v, i) => ArrEQ(v, a2[i]))
      : //:No: Simple Comparison:
      a1 === a2
  )
}
