import 'bootstrap/dist/css/bootstrap.min.css'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import './App.css'
import AuthenticationProvider from './contexts/AuthenticationProvider'
import Routes from './Routes'

function App() {
  return (
    <>
      <BrowserRouter>
        <AuthenticationProvider>
          {/* <SocketContext.Provider value={socket}> */}
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            autoHideDuration={4000}
          >
            <Routes />
          </SnackbarProvider>
          {/* </SocketContext.Provider> */}
        </AuthenticationProvider>
      </BrowserRouter>
    </>
  )
}

export default App
